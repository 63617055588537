import React, {useState, useMemo, useCallback} from "react";
import styles from "../dash.module.css";
import {Popover} from 'react-tiny-popover'

const DayCell = (
    {
        day,
        is_workday,
        hours,
        edit,
        handleDayClick,
        comment,
        rangeMode,
        inRange,
        isBoundary,
        isChosen
    }) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    return (
        <div
            className={`relative`}
            onMouseEnter={() => {
                if (comment || hours > 0) setIsPopoverOpen(true)
            }}
            onMouseLeave={() => setIsPopoverOpen(false)}
        >
            <Popover
                isOpen={isPopoverOpen}
                reposition={true}
                content={<React.Fragment>
                    {hours > 0 && <div>{`Количество рабочих часов: ${hours}`}</div>}
                    {comment && <div>{`Комментарий: ${comment}`}</div>}
                </React.Fragment>}
                containerClassName={`mb-2 p-2 border border-pult/40 bg-white/90 rounded-md`}
            >
                <div
                    className={`
                        w-[30px] h-[30px] rounded-md flex justify-center
                        ${(is_workday === false) ? 'text-red-600' : ''}
                        ${comment ? 'border border-pult' : ''}
                        ${inRange ? 'bg-blue-200' : ''}
                        ${isBoundary ? 'bg-blue-400' : ''}
                        ${isChosen ? 'bg-amber-300' : ''}
                        cursor-default  
                      `}
                    onClick={() => (rangeMode || edit) && handleDayClick(day)}
                >
                    <p className={'w-fit self-center'}>
                        {day}
                    </p>
                </div>
            </Popover>
        </div>
    );
};

const MonthCalendar = ({monthData, edit, selectedDays, setSelectedDays, rangeMode, setRange, range}) => {
    const weekdays = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];
    const months = {
        1: `Январь`,
        2: `Февраль`,
        3: `Март`,
        4: `Апрель`,
        5: `Май`,
        6: `Июнь`,
        7: `Июль`,
        8: `Август`,
        9: `Сентябрь`,
        10: `Октябрь`,
        11: `Ноябрь`,
        12: `Декабрь`
    };

    const firstDayOfMonth = new Date(monthData.days[0].date).getDay();
    // console.log(monthData.days, firstDayOfMonth)
    const max = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;

    const emptyCells = useMemo(() => {
        return Array(max).fill(null).map((_, i) => <div key={`empty${i}`}/>);
    }, [max]);
    // console.log(selectedDays);
    const handleDayClick = useCallback((dayData) => {
        const day = dayData.date
        if (rangeMode) {
            if (!range.first) {
                setRange({...range, first: day});
            } else if (range.first && !range.second) {
                setRange({...range, second: day});
            } else {
                setRange({first: day, second: ""});
            }
            return;
        }
        if (!selectedDays.includes(day)) {
            setSelectedDays([...selectedDays, day]);
        } else {
            const filtered = selectedDays.filter(date => date !== day);
            setSelectedDays(filtered)
        }
    }, [selectedDays, setSelectedDays, rangeMode, range, setRange]);

    const inRange = (day) => {
        if (!range.first || !range.second) return false;
        return (range.first <= day && day <= range.second) || (range.second <= day && day <= range.first)
    }

    const daysInMonth = useMemo(() => {

        return monthData.days.map(dayData => (
            <DayCell
                key={dayData.date}
                day={new Date(dayData.date).getDate()}
                hours={dayData.hours}
                is_workday={dayData.is_workday}
                comment={dayData.comment}
                edit={edit}
                rangeMode={rangeMode}
                inRange={inRange(dayData.date)}
                isChosen={selectedDays.includes(dayData.date)}
                isBoundary={dayData.date === range.first || dayData.date === range.second}
                handleDayClick={() => handleDayClick(dayData)}
            />
        ));
    }, [monthData.days, edit, handleDayClick, rangeMode]);

    return (
        <div className={`${styles.card} m-1.5`}>
            <h2 className={styles.label}>
                {months[monthData.month]}
            </h2>
            <div className={`grid grid-cols-7 gap-2 text-center px-1`}>
                {weekdays.map((weekday) => (
                    <DayCell
                        key={`weekday-${weekday}`}
                        day={weekday}
                    />
                ))}
            </div>
            <div className={`grid grid-cols-7 gap-2 text-center px-1`}>
                {emptyCells}
                {daysInMonth}
            </div>
        </div>
    );
};

const PrintCalendar = ({data, editCalendar, selectedDays, setSelectedDays, rangeMode, range, setRange}) => {

    return (
        <div className={`flex flex-wrap`}>
            {data.map((monthData) => (
                <MonthCalendar
                    key={monthData.month}
                    monthData={monthData}
                    edit={editCalendar}
                    selectedDays={selectedDays}
                    setSelectedDays={setSelectedDays}
                    rangeMode={rangeMode}
                    range={range}
                    setRange={setRange}
                />
            ))}
        </div>
    );
};

export default PrintCalendar;
