import Section from "../../UI/Sections/Section";
import Alink from "../../UI/Buttons/Alink";
import PageSection from "../../UI/Sections/PageSection";


const RegistractionSuccess = (props) => {

    return (
        <PageSection>
            <Section>
                <p>Регистрация прошла успешно, на Ваш email отправлено письмо</p>
                <br/>
                <div className="w-max mx-auto"><Alink href="/">Перейти на главную</Alink></div>
            </Section>
        </PageSection>
    );
}

export default RegistractionSuccess;