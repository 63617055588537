import PageSection from "../../UI/Sections/PageSection";
import Section from "../../UI/Sections/Section";
import Grids from "../../UI/Grids/Grids";
import Alink from "../../UI/Buttons/Alink";

const payCards = (props) => {

    return (
        <PageSection>
            <Section>
                <Grids cols={2}>
                    <Alink href='/pay-success'>Оплата прошла успешно</Alink>
                    <Alink href='/pay-not-success'>Оплата не прошла</Alink>
                </Grids>
            </Section>
        </PageSection>
    );
}

export default payCards;