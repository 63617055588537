import {useState} from "react";
import usePerson from "./usePerson";

export const useSelectPerson = (personList) => {
    const [customerId, setCustomerId] = useState({
        customer_id: "",
    });

    const handleCustomerOnChange = (event) => {
        const {name, value} = event.target;
        setCustomerId(prev =>
            ({...prev, [name]: value})
        );
    }

    const {
        person: customer
    } = usePerson(customerId.customer_id, personList);


    const isSelectedPersons = customer

    return {
        customerState: {
            customer,
        },
        handleCustomerOnChange,
        customerId,
        isSelectedPersons
    };
}