import React, {useContext, useEffect, useState} from "react";
import UserSection from "../../UI/Sections/UserSection";
import {Link, useLocation} from "react-router-dom";
import {ErrorOccurred} from "../../UI/Sections/ErrorOccurred";
import axios from "axios";
import UserContext from "../../../store/user/user-context";
import Btn from "../../UI/Buttons/Btn";
import inputStyles from "../../UI/Inputs/inputs.module.css";
import Warnings from "../../UI/Sections/Warnings";
import UploadDocsForm from "./UploadDocsForm";
import RequiredDocuments from "./register-docoments.json";

export default function UploadRegistrationDocs() {

    const userSettings = useContext(UserContext);
    const contractData = new URLSearchParams(useLocation().search);
    const subcompany_id = +contractData.get('subcompany');
    const tender_id = +contractData.get('tender');

    const [answers, setAnswers] = useState(null);
    const [currentContract, setCurrentContract] = useState(null);
    const [currentDocs, setCurrentDocs] = useState(null);
    const company_id = currentContract?.tender.project.company.id;
    const project_id = currentContract?.tender.project.id;

    const requiredDocuments = RequiredDocuments;
    const [docsNames, setDocsNames] = useState([]);

    const addNameHandler = (e) => {

        e.preventDefault();

        const value = e.target.name.value;
        const name = value.trim(' ');

        const currentDocsNames = currentDocs ? currentDocs.map(doc => doc.name) : [];
        const validDocsNames = docsNames || [];
        const checkedNames = [...currentDocsNames, ...validDocsNames];
        const validName = (!checkedNames.includes(name) && name.length > 2);


        // console.log(
        //     `\n validName`, validName,
        //     `\n name`, name,
        // );

        if (validName) {

            setAnswers(false);

            const updateDocsNames = [...docsNames, name];
            setDocsNames(updateDocsNames);

            // console.log(
            //     `\n addNameHandler`,
            //     `\n name`, name, name.length,
            //     `\n validName`, validName,
            //     `\n updateDocsNames`, updateDocsNames,
            // );
        }
        else setAnswers(<Warnings>Название совпадает с документом или слишком короткое</Warnings>);
    }
    const removeNameHandler = (name) => {

        const updateDocsNames = [...docsNames].filter(doc => doc !== name);
        setDocsNames(updateDocsNames);
    }
    console.log(currentContract)
    const uploadDocsHandler = async (data) => {

       try {

           const formData = new FormData();

           Object.keys(data).forEach(name => {
               formData.append(name,name === 'file' ? data[name][0] : data[name]);
           });


           const postDoc = await axios.post(
               `${userSettings.api}/subcompanies/${subcompany_id}/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/register-docs/`,
               formData,
               {headers: userSettings.headers.postForm}
           );

           if (postDoc.data) {

               const updateDocuments = !currentDocs ? [...[], postDoc.data] : [...currentDocs, postDoc.data];
               // console.log(`\n updateDocuments`, updateDocuments);
               setCurrentDocs(updateDocuments);

               const updateNames = [...docsNames].filter(name => name !== postDoc.data.name);
               setDocsNames(updateNames);
               setAnswers(<Warnings correct>Документ добавлен</Warnings>);
           }
       }
       catch (error) {

           const responseError = ErrorOccurred(
               error.response,
               "Ошибка загрузки документов",
               "uploadDocsHandler"
           );
           setAnswers(responseError.jsx);
       }
    }
    const editDocumentHandler = async (data) => {

        try {
            console.log('\n editDocumentHandler', data);
        }
        catch (error) {

            const responseError = ErrorOccurred(
                error.response,
                "Ошибка обновления документа",
                "editDocumentHandler"
            );
            setAnswers(responseError.jsx);
        }
    }
    // const putDocsHandler = async (data) => {
    //
    //     if (editableDocument) {
    //
    //         try {
    //
    //             const formData = new FormData();
    //             Object.keys(data).forEach(name => {
    //                 formData.append(name,name === 'file' ? data[name][0] : data[name]);
    //             });
    //
    //             const register_docs_id = editableDocument.id;
    //
    //             const response = await axios.put(
    //                 `/subcompanies/${subcompany_id}/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/register-docs/${register_docs_id}/`,
    //                 formData,
    //                 userSettings.headers.postForm
    //             )
    //
    //             console.log(
    //                 `\n putDocsHandler`, data,
    //                 `\n formData`, formData,
    //                 `\n response`, response,
    //             );
    //         }
    //         catch (error) {
    //
    //             const responseError = ErrorOccurred(
    //                 error.response,
    //                 "Ошибка обновления документа",
    //                 "putDocsHandler"
    //             );
    //             setAnswers(responseError.jsx);
    //         }
    //     }
    // }

    useEffect(() => {

        const getInfo = async () => {

            setAnswers(null);

            try {

                const wonTenderLots = await axios.get(
                    `${userSettings.api}/subcompanies/${subcompany_id}/won-tender-lots`,
                    userSettings.headers.get
                )
                const updateCurrentContract = wonTenderLots.data.find(l => l.tender.id === tender_id);
                // console.log(`\n updateCurrentContract`, updateCurrentContract);
                setCurrentContract(updateCurrentContract);
            }
            catch (error) {

                const responseError = ErrorOccurred(
                    error.response,
                    "Ошибка получения информации",
                    "getInfo"
                );
                setAnswers(responseError.jsx);
            }
        }

        getInfo();

    },[subcompany_id,userSettings]);
    useEffect(() => {

        const getRegisterDocs = async () => {

            try {

                const response = await axios.get(
                    `${userSettings.api}/subcompanies/${subcompany_id}/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/register-docs/`,
                    userSettings.headers.get
                );
                // console.log(`\n response`, response.data);
                if (response.data.length > 0) {
                    setCurrentDocs(response.data);
                    const currentDocsNames = response.data.map(doc => doc.name);
                    const findRequiredDocuments = requiredDocuments.filter(name => !currentDocsNames.includes(name));
                    // console.log(
                    //     `\n currentDocsNames`, currentDocsNames,
                    //     `\n findRequiredDocuments`, findRequiredDocuments
                    // );
                    if (findRequiredDocuments.length > 0) setDocsNames(findRequiredDocuments);
                    else setDocsNames([]);
                }
                else {
                    setCurrentDocs(null);
                    setDocsNames(requiredDocuments);
                }
            }
            catch (error) {

                const responseError = ErrorOccurred(
                    error.response,
                    "Ошибка получения документов при регистрации",
                    "responseError"
                );
                setAnswers(responseError.jsx);
            }
        }

        if (company_id && project_id) getRegisterDocs();
    },[company_id, project_id, userSettings, subcompany_id, tender_id, requiredDocuments]);

    // if (currentContract) console.log(`\n currentContract`, currentContract);
    if (currentDocs) console.log(`\n currentDocs`, currentDocs);
    // if (docsNames) console.log(`\n docsNames`, docsNames);

    return (
        <UserSection>
            <h1>Загрузить документы</h1>
            <Btn method={`back`} color={`button`}>
                <Link to={'/work/'}>Назад</Link>
            </Btn>
            {answers && answers}
            <form onSubmit={addNameHandler} className={`my-1 w-full md:w-max bg-pult/10 p-2 rounded-md flex gap-2 flex-wrap`}>
                <div className={`my-auto mx-auto`}>
                    <input
                        type={`text`}
                        name={`name`}
                        className={inputStyles.input}
                        placeholder={`Укажите имя документа`}
                    />
                </div>
                <Btn method={`add`} color={`button`} center>
                    <button type={`submit`}>Добавить документ</button>
                </Btn>
            </form>
            <div className={`flex flex-wrap gap-2`}>
                {(docsNames && docsNames.length > 0) && docsNames.map(name => <UploadDocsForm
                    key={name}
                    name={name}
                    onSubmit={uploadDocsHandler}
                    removeNameHandler={removeNameHandler}
                    required={requiredDocuments.includes(name)}
                    editDocumentHandler={editDocumentHandler}
                />)}
                {(currentDocs && currentDocs.length > 0) && currentDocs.map(document => <UploadDocsForm
                    key={document.name}
                    document={document}
                    onSubmit={uploadDocsHandler}
                    removeNameHandler={removeNameHandler}
                />)}
            </div>
        </UserSection>
    );
}