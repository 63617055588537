import Section from "../../UI/Sections/Section";
import PageSection from "../../UI/Sections/PageSection";
import Alink from "../../UI/Buttons/Alink";

const PayNotSuccess = (props) => {

    return (
        <PageSection>
            <Section>
                <p>Оплата не прошла</p>
                <br/>
                <Alink href='/payment'>Перейти к оплате</Alink>
            </Section>
        </PageSection>
    );
}

export default PayNotSuccess;