import React, {useContext, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import dashStyles from "../dash.module.css";
import RegistrationInput from "../../UI/Inputs/RegistrationInput";
import Btn from "../../UI/Buttons/Btn";
import {Link} from "react-router-dom";
import UserContext from "../../../store/user/user-context";

export default function UploadDocsForm(
    {
        name,
        onSubmit,
        removeNameHandler,
        required,
        document,
        // editDocumentHandler
    }
) {

    const userSettings = useContext(UserContext);
    const methods = useForm();

    const docFields = [
        {
            "type": "file",
            "name": "file",
            "label": "Загрузить документ"
        },
        {
            "type": "text",
            "name": "number",
            "label": "Номер документа"
        },
        {
            "type": "date",
            "name": "date",
            "label": "Дата документа",
            "max": new Date().toISOString().split('T')[0]
        },
        {
            "type": "text",
            "name": "organization",
            "label": "Наименование организации"
        },
    ];

    const [editDocument, ] = useState(false); //setEditDocument

    // console.log(`\n view`, view);
    const sendData = (data) => {
        data['name'] = name;
        onSubmit(data);
    }

    console.log('\n name', name);
    console.log('\n document', document);

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(sendData)} className={dashStyles.card}>
                <h2 className={dashStyles.label}>{document?.name || name}</h2>
                <div className={dashStyles.area}>
                    {docFields && docFields.map(field => {

                        const defaultValue = document && field.name !== 'file' ? document[field.name] : null;
                        const fileLink = document && field.name === 'file' ? <Btn method={`download`} color={`button`} center>
                                <Link to={`${userSettings.fileServer}${document[field.name]}`} target={`_blank`}>Посмотреть документ</Link>
                            </Btn> : null;

                        if (!document || editDocument) return (
                            <RegistrationInput
                                key={field.name}
                                type={field.type}
                                name={field.name}
                                label={field.label}
                                options={field.options}
                                defaultValue={defaultValue}
                                min={field.min}
                                max={field.max}
                                step={field.step}
                            />
                        );
                        else return (
                            <div key={field.name}>
                                {fileLink ?
                                    fileLink :
                                    <React.Fragment>
                                        <div className={`text-center`}>
                                            {field.label}
                                        </div>
                                        <div className={`px-1 py-0.5 border border-pult/30 rounded-md bg-pult-hover/10 my-1`}>
                                            {document[field.name]}
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                        )
                    })}
                    {(name || editDocument) && <Btn method={`save`} color={`button`} center>
                        <button type="submit">
                            {editDocument ? `` : `Отправить`} документ
                        </button>
                    </Btn>}
                    {(name && !required) && <Btn method={`remove`} center onClick={() => removeNameHandler(name)} >
                        Удалить из списка
                    </Btn>}
                    {document?.reason && <div className={`px-1 py-0.5 border border-warning rounded-md bg-warning/20 my-1`}>
                        <div>Документ отклонен координатором</div>
                        <div>{document.reason}</div>
                        {/*{!editDocument && <Btn method={`edit`} color={`button`} center onClick={() => setEditDocument(true)}>Редактировать</Btn>}*/}
                    </div>}
                </div>
            </form>
        </FormProvider>
    );
}