import React, {useContext, useEffect, useState} from "react";
import Btn from "../Buttons/Btn";
import {ReactComponent as Create} from "../Icons/Plan/created.svg";
import {ReactComponent as Send} from "../Icons/Plan/send.svg";
import {ReactComponent as Sign} from "../Icons/Plan/sing.svg";
import {ReactComponent as Call} from "../Icons/Plan/call.svg";
import {ReactComponent as Reject} from "../Icons/Plan/reject.svg";
import {ReactComponent as Apply} from "../Icons/Plan/accepted.svg";
import RegistrationInput from "../Inputs/RegistrationInput";
import {FormProvider, useForm} from "react-hook-form";
import {Link} from "react-router-dom";
import {PDFDownloadLink} from "@react-pdf/renderer";
import {getObjectName} from "../GetInfo/getCompany";
import UserContext from "../../../store/user/user-context";
import DocumentOrder from "../PdfDocuments/DocumentOrder";
import {getTender} from "../GetInfo/getSubcompanies";
import {Button} from "@mui/material";

export default function OrderInfo(
    {
        order,
        fileServer,
        subcompanies,
        sendOrder,
        signOrder,
        callToOrder,
        rejectOrder,
        acceptOrder,
        moveOrderHandler,
        editOrder,
        handleClosePopup,
    }
) {

    const [state, setState] = useState(null);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isEditHistory, setIsEditHistory] = useState(true);

    const role = +localStorage.role;
    console.log(role)
    const methods = useForm();
    const statuses = {
        1: (<React.Fragment>
            <Btn color={`button`} full icon={<Send/>} onClick={() => setState('send')}>Отправить на подпись
                исполнителю</Btn>
            <div className={`flex flex-wrap gap-2 w-max mx-auto`}>
                <Btn color={`button`} method={`move`} onClick={() => moveOrderHandler(order.id)}>Передвинуть</Btn>
                <Btn color={`button`} method={`edit`} onClick={() => {
                    editOrder(order.id)
                    handleClosePopup();
                }}>Редактировать</Btn>
            </div>
        </React.Fragment>),
        2: <Btn color={`button`} center icon={<Sign/>} onClick={() => setState('sign')}>Подписать предписание</Btn>,
        3: role === 6 && <Btn color={`button`} center icon={<Call/>} onClick={() => callToOrder()}>Вызвать</Btn>,
        4: role === 6 && <Btn color={`button`} center icon={<Call/>} onClick={() => callToOrder()}>Вызвать</Btn>,
        5: role === 3 && <div className={`flex gap-2`}>
            <Button variant="contained" onClick={() => setState('reject')}>Отклонить</Button>
            <Button variant="contained" onClick={() => setState('accept')}>Принять</Button>
        </div>,
    };
    const statusesHistory = {
        1: {
            img: <Create/>,
            label: "Предписание создано"
        },
        2: {
            img: <Send/>,
            label: "Предписание отправлено"
        },
        3: {
            img: <Sign/>,
            label: "Предписание подписано"
        },
        5: {
            img: <Call/>,
            label: "Вызов на объект"
        },
        4: {
            img: <Reject/>,
            label: "Отказ по предписанию"
        },
        6: {
            img: <Apply/>,
            label: "Предписанию завершено"
        },
    };
    const fields = [
        {
            tag: "contract",
            label: "Договор с подрядчиком",
        },
        {
            tag: "date_completion",
            label: "Устранить до",
        },
        {
            tag: "category",
            label: "Категория",
        },
        {
            tag: "description",
            label: "Описание",
        },
        {
            tag: "standard",
            label: "Норматив",
        },
        {
            tag: "measures",
            label: "Мероприятия по устранению",
        },
        {
            tag: "photos",
            label: "Фото",
        },
    ];
    const currentStatus = order.history[order.history.length - 1];
    const statusControl = statuses[currentStatus.status];
    const BackState = () => (
        <Btn
            method={`back`}
            color={`button`}
            center
            onClick={() => setState(null)}
        >
            Назад
        </Btn>
    );

    const order_id = order.id;
    const section_id = order.section;

    // console.log(`\n subcompanies`, subcompanies);
    // console.log(`\n role`, role);
    // console.log(`\n order`, order);
    const tender_id = subcompanies?.find(t => t.id === order.contract).tender.id;

    const sendOrderHandler = (data) => {

        if (role === 3) {
            // console.log(`\n `, order, subcompanies);

            data['order_id'] = order_id;
            data['section_id'] = section_id;
            data['tender_id'] = tender_id;

            // console.log(`\n sendOrder`, data);
            sendOrder(data);
        }
    }
    const signOrderHandler = (data) => {

        if (role === 6) signOrder(data);
    }
    const rejectOrderHandler = (data) => {

        if (role === 3) {
            // console.log(`\n data`, data);

            data['order_id'] = order_id;
            data['section_id'] = section_id;
            data['tender_id'] = tender_id;
            rejectOrder(data);
        }
    }
    const acceptOrderHandler = (data) => {

        if (role === 3) {

            data['order_id'] = order_id;
            data['section_id'] = section_id;
            data['tender_id'] = tender_id;
            acceptOrder(data);
        }
    }

    useEffect(() => {
        if (role === 3 && currentStatus.status === 2) setIsEditHistory(null);
    }, [role, currentStatus.status]);

    const [pdfData, setPdfData] = useState(null);
    const userSettings = useContext(UserContext);
    useEffect(() => {

        const pdfDataHandler = async () => {

            if (role === 3) {
                const contract_info = subcompanies?.find(contract => contract.id === order.contract);
                const company = contract_info?.tender.project.company;
                const project_id = contract_info?.tender.project.id;
                const subcompany = contract_info?.info;
                const object = await getObjectName(userSettings, company.id, project_id);

                if (contract_info && object) {

                    setPdfData({
                        ...order,
                        contract_info,
                        company,
                        subcompany,
                        object,
                        fileServer,
                    });
                }
            } else if (role === 6) {

                const subcompany_id = +window.localStorage.getItem('company');
                const tender_id = +window.localStorage.getItem('tender');
                const allTenders = await getTender(userSettings, subcompany_id);
                const currentTender = allTenders.find(tender => tender.tender.id === tender_id);
                const company = currentTender?.tender?.project?.company;
                const project_id = currentTender?.tender?.project?.id;
                const subcompany = userSettings.companies.find(s => s.id === subcompany_id);
                const object = await getObjectName(userSettings, company?.id, project_id);
                console.log("subcompany_id", subcompany_id)
                console.log("tender_id", tender_id)
                console.log("allTenders", allTenders)
                console.log("currentTender", currentTender)
                console.log("company", company)
                console.log("project_id", project_id)
                console.log("subcompany", subcompany)
                console.log("object", object)

                setPdfData({
                    ...order,
                    company,
                    subcompany,
                    object,
                    fileServer,
                });
            }
        }

        if (order && fileServer) pdfDataHandler();
    }, [order, fileServer, subcompanies, userSettings, role]);
    // if (pdfData) console.log('\n pdfData', pdfData);

    if (!state) return (<React.Fragment>
        {fields.map((f, id) => {

            const subcompany = f.tag === "contract" && subcompanies?.find(subcompany => subcompany.id === order.contract);
            // console.log('\n subcompany', subcompany);

            return (
                <React.Fragment key={id}>
                    <div className={`text-sm`}>{f.label}</div>
                    <div className={`border-b border-pult/20`}>{
                        f.tag === "date_completion" ?
                            new Date(order[f.tag]).toLocaleDateString() :
                            subcompany ?
                                <React.Fragment>
                                    <div>{subcompany.tender.name}</div>
                                    <div>{subcompany.info.name}</div>
                                </React.Fragment> :
                                f.tag === "photos" ?
                                    <div className={`flex flex-wrap gap-2 items-center`}>
                                        {order[f.tag].map((photo, index) =>
                                            <a
                                                className={`rounded-md overflow-hidden shadow-md shadow-black/50`}
                                                href={`${fileServer}${photo.file}`}
                                                target={`_blank`}
                                                key={`${f.tag}-${index}`}
                                            >
                                                <img
                                                    className={`w-12`}
                                                    src={`${fileServer}${photo.file}`}
                                                    alt={photo.order}
                                                />
                                            </a>
                                        )}
                                    </div> :
                                    order[f.tag]
                    }</div>
                </React.Fragment>
            )
        })}
        {isEditHistory && <div className={`mt-4`}>{statusControl}</div>}
        {(isCollapsed) ?
            order.history.length > 1 &&
            <Btn
                method={`view`}
                color={`button`}
                center
                onClick={() => setIsCollapsed(false)}
            >
                Посмотреть историю {order.history.length}
            </Btn> :
            <React.Fragment>
                <ul>
                    {order.history.map((h, id) =>
                        <li key={id} className={`grid grid-cols-3 gap-1`}>
                            <span
                                className={`text-pult/80 text-xs my-auto`}>{new Date(h.date_time).toLocaleString('ru-RU')}</span>
                            <div className={`col-span-2 text-sm`}>
                                {(h.status === 1 || h.status === 4 || h.status === 6) &&
                                    <Btn className={`flex`} icon={statusesHistory[h.status].img} transparent full>
                                        {statusesHistory[h.status].label}
                                    </Btn>
                                }
                                {h.order_file &&
                                    <React.Fragment>
                                        <Btn color={`link`} icon={statusesHistory[h.status].img} full>
                                            <Link to={`${fileServer}${h.order_file}`} target={"_blank"}>
                                                {statusesHistory[h.status].label}
                                            </Link>
                                        </Btn>
                                    </React.Fragment>
                                }
                                {h.status === 5 &&
                                    <Btn className={`flex`} icon={statusesHistory[h.status].img} transparent full>
                                        {statusesHistory[h.status].label}
                                    </Btn>
                                }
                            </div>
                        </li>
                    )}
                </ul>
                <Btn method={`cancel`} color={`button`} center onClick={() => {
                    setIsCollapsed(!isCollapsed)
                }}>Закрыть историю</Btn>
            </React.Fragment>
        }
    </React.Fragment>);
    else if (state === 'send' && role === 3) return (<FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(sendOrderHandler)}>
            {pdfData &&
                <Btn method={`download`} center color={`button`}>
                    <PDFDownloadLink document={<DocumentOrder data={pdfData}/>}
                                     fileName={`Акт-предписание №${order.id}.pdf`}>
                        {({loading}) => (loading ? 'Загрузка документа...' : 'Скачать предписание для подписи')}
                    </PDFDownloadLink>
                </Btn>
            }
            <RegistrationInput
                name={`order_file`}
                type={`file`}
                label={`Загрузить подписанное предписание *.sig`}
                sig
            />
            <Btn method={`save`} color={`button`} center>
                <button type="submit">Отправить на подпись исполнителю</button>
            </Btn>
        </form>
        <BackState/>
    </FormProvider>);
    else if (state === 'reject' && role === 3) return (<FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(rejectOrderHandler)}>
            <RegistrationInput
                name={`photo_reject`}
                type={`file`}
                label={`Загрузить фото отклонения предписания`}
                multiple
                accept={'.jpg,.jpeg,.png,.gif'}
                allowedFileTypes={'.jpg, .jpeg, .png, .gif'}
            />
            <Btn method={`save`} color={`button`} center>
                <button className={`w-full`} type="submit">Отклонить вызов</button>
            </Btn>
        </form>
        <BackState/>
    </FormProvider>);
    else if (state === 'accept' && role === 3) return (<FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(acceptOrderHandler)}>
            <RegistrationInput
                name={`photo_reject`}
                type={`file`}
                label={`Загрузить фото приемки предписания`}
                multiple
                accept={'.jpg,.jpeg,.png,.gif'}
                allowedFileTypes={'.jpg, .jpeg, .png, .gif'}
            />
            <Btn method={`save`} color={`button`} center>
                <button className={`w-full`} type="submit">Принять предписание</button>
            </Btn>
        </form>
        <BackState/>
    </FormProvider>);
    else if (state === 'sign' && role === 6) return (<FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(signOrderHandler)}>
            {pdfData &&
                <Btn method={`download`} center color={`button`}>
                    <PDFDownloadLink document={<DocumentOrder data={pdfData}/>}
                                     fileName={`Акт-предписание №${order.id}.pdf`}>
                        {({loading}) => (loading ? 'Загрузка документа...' : 'Скачать предписание для подписи')}
                    </PDFDownloadLink>
                </Btn>
            }
            <RegistrationInput
                name={`order_file`}
                type={`file`}
                label={`Загрузить подписанное предписание *.sig`}
                sig
            />
            <Btn method={`save`} color={`button`} center>
                <button type="submit">Подписать предписание</button>
            </Btn>
        </form>
        <BackState/>
    </FormProvider>);
}