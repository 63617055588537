import React, {useContext, useEffect, useState} from 'react';
import UserContext from "../../../store/user/user-context";
import {useNavigate} from "react-router-dom";
import axios from 'axios';
import Warnings from "../../UI/Sections/Warnings";
import tables from "../../UI/Tables/table.module.css";
import TheadSort from "../../UI/Tables/TheadSort";
import Btn from "../../UI/Buttons/Btn";
import Popup from "../../UI/Popup/Popup";
import {PrintPhone} from "../../UI/Convertors/PrintPhone";
import {ReactComponent as ResetPassword} from "../../UI/Icons/Administrator/resetPassword.svg";
import {ReactComponent as AddUser} from "../../UI/Icons/Administrator/addUser.svg";
import {ReactComponent as DeletedIcon} from "../../UI/Icons/Administrator/deletedUser.svg";
import {GetUserInfo} from "./GetUserInfo";

const UsersManagement = () => {

    const navigate = useNavigate();
    if (window.localStorage.getItem('role') !== '1') navigate('/work/');

    const userSettings = useContext(UserContext);
    const company_id = window.localStorage.getItem('company');
    const [popupShow, setPopupShow] = useState(false);
    const [errors, setErrors] = useState(false);
    const [message, setMessage] = useState(false);
    const sortNames = [
        {
            "tag": "",
            "name": "Инициалы",
            "component": `name`,
        },
        {
            "tag": "",
            "name": "Статус",
            "component": `status`,
        },
        {
            "tag": "",
            "name": "Назначенные блоки",
            "component": `role`,
        },
        {
            "tag": "",
            "name": "Проекты",
            "component": `projects`,
        },
        {
            "tag": "",
            "name": "Управление",
            "component": `manage`,
        },
    ];
    const roleNames = [
        {
            id: 0,
            label: "Инвестор"
        },
        {
            id: 1,
            label: "Администратор"
        },
        {
            id: 2,
            label: "Координатор"
        },
        {
            id: 3,
            label: "Стройконтроль"
        },
        {
            id: 4,
            label: "Начальник участка"
        },
        {
            id: 5,
            label: "ГИП"
        },
    ];

    const [companyUsers, setCompanyUsers] = useState(null);
    const [companyProjects, setCompanyProjects] = useState(null);

    const inviteUserHandler = () => {

        const inviteUser = async (data) => {

            console.log(`\n data`, data);

            try {

                const answerInviteUser = await axios.post(
                    `${userSettings.api}/invites/companies/${company_id}/invite`,
                    {
                        "role": data.role,
                        "email": data.email,
                    },
                    {
                        headers: userSettings.headers.post
                    }
                );

                if (answerInviteUser.status === 201) {

                    // console.log(`\n answerInviteUser`, answerInviteUser);
                    setErrors(false);
                    setMessage(`Приглашение успешно отправлено`);
                }
            } catch (error) {
                // console.log(`\n updateСompanyUsersHandler user`, error.response);
                setErrors(<React.Fragment>
                    <div>Приглашение не отправлено <ul>{error.response.data.errors.map((e,id) => <li key={id}>{e.detail}</li>)}</ul></div>
                </React.Fragment>);
                setMessage(false);
            }

            setPopupShow(false);
        }

        setPopupShow(<Popup
            title="Добавить сотрудника"
            form={{
                fields: [
                    {
                        type: "email",
                        name: "email",
                        label: "Укажите e-mail сотрудника",
                    },
                    {
                        type: "select",
                        name: "role",
                        label: "Выберите блок",
                        options: roleNames.filter(n => n.id !== 0).map(r => ({id: r.id, name: r.label}))
                    }
                ],
                submit: "Отправить"
            }}
            onSubmit={inviteUser}
            grid={2}
            onCloseModal={() => setPopupShow(false)}
        />);
    }

    const updateСompanyUsersHandler = async (requests) => {

        if (companyUsers && Object.keys(requests).length > 0) {

            // console.log(`\n updateCompanyUsersHandler`, requests, companyUsers);

            const changedUsers = companyUsers.sort((a, b) => a.id - b.id).map(user => {
                if (requests[user.id]) {
                    const userUpdate = {};
                    Object.keys(user).forEach(key => {
                        if (key === 'is_blocked' || key === 'is_deleted') {
                            userUpdate[key] = requests[user.id].hasOwnProperty(key) ? requests[user.id][key] : user[key];
                        } else {
                            userUpdate[key] = requests[user.id][key] || user[key];
                        }
                    });
                    return userUpdate;
                } else {
                    return false;
                }
            }).filter(user => user);

            if (changedUsers.length > 0) try {

                console.log(`\n changedUsers`, changedUsers);

                const updateCompanyUsersHandler = await axios.patch(
                    `${userSettings.api}/company/${company_id}/users`,
                    changedUsers,
                    {
                        headers: userSettings.headers.post
                    }
                );

                // console.log(`\n updateCompanyUsersHandler`, updateCompanyUsersHandler.data.users);
                if (updateCompanyUsersHandler.data.users.length > 0) window.location.reload();
                // const updateCompanyUsers = companyUsers.map(user => {
                //
                //     // console.log(`\n user`, user);
                //     // console.log(`\n user`, user.id);
                //     // console.log(`\n users`, updateCompanyUsersHandler.data.users.find(updatedUser => updatedUser.id === user.id));
                //
                //     return updateCompanyUsersHandler.data.users.find(updatedUser => updatedUser.id === user.id) || user;
                // });
                // console.log(`\n updateCompanyUsers`, updateCompanyUsers);

                // setErrors(false);
                // setMessage(updateCompanyUsersHandler.data.message);
                // setCompanyUsers(updateCompanyUsers);

            } catch (error) {
                // console.log(`\n updateСompanyUsersHandler user`, error);
                setErrors(`Обновление данных пользователя Error-${error.response.status}`);
                setMessage(false);
            }
        }

        setPopupShow(false);
    }

    const editNameHandler = (id, full_name) => {

        // console.log(`\n full_name`, id, full_name);

        const getFull_name = Array.isArray(full_name.split(' ')) ? full_name.replace(/[/"]/g,'').split(' ') : [];

        // console.log(`\n getFull_name`, getFull_name);

        const editName = (data) => {

            // console.log(`\n editName`, data);

            const full_name = `${data.surname} ${data.name} ${data.secondname}`;
            const short_name = `${data.surname} ${data.name[0]}. ${data.secondname[0]}.`;
            const update = {};
            update[id] = {
                full_name,
                short_name
            };

            updateСompanyUsersHandler(update);
        }

        setPopupShow(<Popup
            title="Добавить инициалы"
            form={{
                fields: [
                    {
                        type: "text",
                        name: "surname",
                        label: "Введите фамилию",
                        defaultValue: getFull_name[0] || ''
                    },
                    {
                        type: "text",
                        name: "name",
                        label: "Введите имя",
                        defaultValue: getFull_name[1] || ''
                    },
                    {
                        type: "text",
                        name: "secondname",
                        label: "Введите отчество",
                        defaultValue: getFull_name[2] || ''
                    }
                ],
                submit: "Сохранить"
            }}
            onSubmit={editName}
            grid={3}
            onCloseModal={() => setPopupShow(false)}
        />);
    }
    const addRoleUserHandler = (user_id, user_role) => {

        // console.log(`\n addRoleUserHandler`, user_id, user_role);

        const companyRole = roleNames.filter(n => n.id !== 0);
        const existCompanyRole = companyRole.filter(role => !user_role.includes(role.id));

        // console.log(`\n companyRole`, companyRole);
        // console.log(`\n existCompanyRole`, existCompanyRole);

        const addRoleUser = (data) => {

            // console.log(`\n addRoleUser`, data);

            const chosenRoles = Object.keys(data).map(role => data[role] && role).filter(role => role);
            const mergedRoles = user_role.concat(chosenRoles.map(Number));
            const update = {};
            update[user_id] = {role: mergedRoles};

            updateСompanyUsersHandler(update);
        }

        setPopupShow(<Popup
            title="Добавить блоки"
            form={{
                fields: existCompanyRole.map(role => ({
                    type: "checkbox",
                    name: role.id,
                    id: role.id,
                    label: role.label
                })),
                submit: "Сохранить",
                type: "checkbox"
            }}
            onSubmit={addRoleUser}
            grid={2}
            onCloseModal={() => setPopupShow(false)}
        />);
    }
    const deleteRoleUserHandler = (user_id, role) => {

        // console.log(`\n deleteRoleUserHandler`, user_id,role);

        const update = {};
        update[user_id] = {role};

        updateСompanyUsersHandler(update);
    }
    const addProjectUserHandler = (user_id, projects) => {

        // console.log(`\n addProjectUserHandler`, user_id, projects, companyProjects);

        const existCompanyProjects = companyProjects.filter(project => !projects.includes(project.id));

        const addProjectUser = data => {

            // console.log(`\n addProjectUser`, data);

            const chosenProjects = Object.keys(data).map(id => data[id] && id).filter(id => id);
            const mergedProjects = projects.concat(chosenProjects.map(Number));
            const update = {};
            update[user_id] = {projects: mergedProjects};

            updateСompanyUsersHandler(update);
        }

        setPopupShow(<Popup
            title="Добавить блоки"
            form={{
                fields: existCompanyProjects.map(project => ({
                    type: "checkbox",
                    name: project.id,
                    id: project.id,
                    label: project.name
                })),
                submit: "Сохранить",
                type: "checkbox"
            }}
            onSubmit={addProjectUser}
            grid={2}
            onCloseModal={() => setPopupShow(false)}
        />);
    }
    const deleteProjectUserHandler = (user_id, projects) => {

        console.log(`\n deleteProjectUserHandler`, user_id,projects);

        const update = {};
        update[user_id] = {projects};

        updateСompanyUsersHandler(update);
    }
    const resetPasswordHandler = (user_id) => {

        console.log(`\n resetPasswordHandler`, user_id);
    }
    const accessUserHandler = (user_id, state) => {

        // console.log(`\n blockUserHandler`, user_id,state);

        const update = {};
        update[user_id] = {is_blocked: state};

        updateСompanyUsersHandler(update);
    }
    const deleteUserHandler = (user_id) => {

        console.log(`\n deleteUserHandler`, user_id);

        const update = {};
        update[user_id] = {is_deleted: true};

        updateСompanyUsersHandler(update);
    }
    const printDeletedEmployeesHandler = () => {

        const deletedUsers = companyUsers.filter(user => user.is_deleted);

        const updateUser = (user_id) => {

            const update = {};
            update[user_id] = {is_deleted: false};

            updateСompanyUsersHandler(update);
        }

        setPopupShow(<Popup
            title="Восстановить удаленного сотрудника"
            content={deletedUsers.map(user =>
                <Btn 
                    key={user.id}
                    color={`button`}
                    onClick={() => updateUser(user.id)}
                >
                    {user.short_name && <div>Инициалы: {user.short_name}</div>}
                    {user.email && <div>E-mail: {user.email}</div>}
                </Btn>
            )}
            onCloseModal={() => setPopupShow(false)}
        />);
    }

    useEffect(() => {
        GetUserInfo(userSettings, company_id, setCompanyUsers, setCompanyProjects);
    }, [userSettings, company_id]);

    // if (userSettings) console.log(`\n userSettings`, userSettings);
    // if (company_id) console.log(`\n company_id`, company_id);
    if (companyUsers) console.log(`\n companyUser`, companyUsers);
    // if (message) console.log(`\n message`, message);
    // if (companyProjects) console.log(`\n companyProjects`, companyProjects);
    // if (errors) console.log(`\n errors`, errors);

    return (
        <React.Fragment>
            {popupShow}
            <h1>Управление пользователями</h1>
            <div className="flex">
                <div className="w-max mr-2"><Btn color="button" icon={<AddUser />} onClick={inviteUserHandler}>Добавить сотрудника</Btn></div>
                {(companyUsers && companyUsers.filter(user => user.is_deleted).length > 0) && <Btn color="button" icon={<DeletedIcon />} onClick={printDeletedEmployeesHandler}>Удаленные сотрудники</Btn>}
                {/*{deletedEmployees.length > 0 && <div className="w-max mr-2"><Btn color="button" icon={<DeletedIcon />} onClick={printDeletedEmployeesHandler}>Удаленные сотрудники</Btn></div>}*/}
            </div>
            <div>
                {errors && <Warnings>{errors}</Warnings>}
                {message && <Warnings correct>{message}</Warnings>}
            </div>
            {companyUsers ?
                <div className={`${tables.container} max-h-[80vh]`}>
                    <table className={tables.table}>
                        <TheadSort
                            names={sortNames}
                            array={companyUsers}
                            setArray={setCompanyUsers}
                        />
                        <tbody>
                        {companyUsers.filter(user => !user.is_deleted).map(user => {

                            const short_name = (user.short_name && user.short_name.length > 0) ? user.short_name : null;

                            // console.log(`\n user`, user);

                            return (<tr key={user.id}>
                                {sortNames.map((cell, num) => <td key={num}>
                                    {cell.component === 'name' && <React.Fragment>
                                        {short_name && <h3 className={`md: text-lg`}>{user.short_name}</h3>}
                                        <Btn color="button" method="add" onClick={() => {editNameHandler(user.id, user.full_name)}}>
                                            {!short_name ? "Добавить" : "Редактировать"} инициалы
                                        </Btn>
                                        {user.email && <div className={`text-sm whitespace-nowrap`}>E-mail: {user.email}</div>}
                                        {user.phone && <div className={`text-sm whitespace-nowrap`}>Телефон: {PrintPhone(user.phone)}</div>}
                                    </React.Fragment>}
                                    {cell.component === 'status' && <React.Fragment>
                                        {user.is_blocked ?
                                            <Warnings reject>Доступ пользователя приостановлен</Warnings>
                                            :
                                            <React.Fragment>
                                                {!user.short_name && <Warnings>Ошибка доступа, отсутствуют инициалы</Warnings>}
                                                {user.projects.length === 0 && <Warnings>Ошибка доступа, не назначен проект</Warnings>}
                                                {(user.short_name && user.projects.length > 0) && <Warnings correct>Доступ пользователю предоставлен</Warnings>}
                                            </React.Fragment>
                                        }
                                    </React.Fragment>}
                                    {cell.component === 'role' && <React.Fragment>
                                        {user.role.map(role => <Btn
                                            key={role}
                                            user={role === 0}
                                            method={(role !== 0) ? "remove" : "disable"}
                                            onClick={() => {
                                                if (role !== 0) deleteRoleUserHandler(user.id, user.role.filter(r => r!== role));
                                            }}
                                        >{roleNames.find(n => n.id === role).label}</Btn>)}
                                        {user.role.length < roleNames.filter(n => n.id !== 0).length && <Btn
                                            method={`add`}
                                            color={`button`}
                                            onClick={() => addRoleUserHandler(user.id,user.role)}
                                        >Добавить роль</Btn>}
                                    </React.Fragment>}
                                    {(cell.component === 'projects' && companyProjects) && <React.Fragment>
                                        {user.projects.map(project => <Btn
                                            key={project}
                                            method={"remove"}
                                            onClick={() => deleteProjectUserHandler(user.id, user.projects.filter(p => p !== project))}
                                        >{companyProjects.find(p => p.id === project).name}</Btn>)}
                                        {(user.projects.length < companyProjects.length) && <Btn
                                            method={`add`}
                                            color={`button`}
                                            onClick={() => addProjectUserHandler(user.id,user.projects)}
                                        >Добавить проект</Btn>}
                                    </React.Fragment>}
                                    {(cell.component === 'manage' && companyProjects) && <React.Fragment>
                                        <Btn
                                            color="red"
                                            icon={<ResetPassword />}
                                            onClick={() => resetPasswordHandler(user.id)}
                                        >Сбросить пароль</Btn>
                                        <Btn
                                            color={user.is_blocked ? "button" : "red"}
                                            method={user.is_blocked ? "edit" : "cancel"}
                                            onClick={() => accessUserHandler(user.id,!user.is_blocked)}
                                        >
                                            {user.is_blocked ? 'Восстановить' : 'Приостановить'} доступ
                                        </Btn>
                                        {!user.role.includes(0) && <Btn
                                            color="red"
                                            method="cancel"
                                            onClick={() => deleteUserHandler(user.id)}
                                        >Удалить сотрудника</Btn>}
                                    </React.Fragment>}
                                </td>)}
                            </tr>)
                        })}
                        </tbody>
                    </table>
                </div>
                :
                <Warnings>Отсутствуют пользователи</Warnings>
            }
        </React.Fragment>
    );
}

export default UsersManagement;