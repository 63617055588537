import React, {useContext, useEffect, useState} from "react";
import UserContext from "../../store/user/user-context";
import Btn from "../UI/Buttons/Btn";
import Grids from "../UI/Grids/Grids";
import Popup from "../UI/Popup/Popup";
import {ReactComponent as User} from "../UI/Icons/employee.svg";
import {ReactComponent as Project} from "../UI/Icons/project.svg";
import axios from "axios";
import UserSection from "../UI/Sections/UserSection";
import SubCompanyAccess from "./Subcompany/SubCompanyAccess";
import RouteScheme from "./ControlPanel/RouteScheme.json";
import {
    generateGUID,
    getPaymentAmount,
    getTariffsCompanies,
    paymentDetails,
    saveBill,
    setTariffsCompany,
    systemTariffs
} from "../SendRequests/TariffsData";
import {Alert, Box, Button, CardContent, Typography} from "@mui/material";
import {Price} from "../UI/Convertors/Price";
import PaymentRoundedIcon from '@mui/icons-material/PaymentRounded';
import Bill from "../UI/Documents/Bill";
import {pdf} from "@react-pdf/renderer";
import {saveAs} from "file-saver";
import {Link} from "react-router-dom";
import {useCookies} from "react-cookie";

const Hello = () => {
    const [, setCookie] = useCookies(['access']);
    const userSettings = useContext(UserContext);
    const [popupShow, setPopupShow] = useState(false);
    const choseRoleHandler = (company_id, role) => {

        const choseRole = (project) => {

            window.localStorage.setItem('company', company_id);
            window.localStorage.setItem('role', role);
            window.localStorage.setItem('project', project);

            // console.log(`\n RouteScheme`, RouteScheme.find(scheme => scheme.role === role), RouteScheme.find(scheme => scheme.role === role).routes[0].route);
            window.location.replace(RouteScheme.find(scheme => scheme.role === role).routes[0].route);
        }


        axios.get(
            `${userSettings.api}/company/${company_id}/projects/projects/`,
            {headers: {Authorization: userSettings.token}}
        ).then(response => {
            const projects = response.data.data.sort((a, b) => a.id - b.id);
            const firstProject = projects[0].id

            if (role !== 1) {
                setPopupShow(<Popup
                    title={`Выберите проект`}
                    content={
                        projects.map(project => <Btn
                            key={project.id}
                            color="button"
                            icon={<Project/>}
                            onClick={() => choseRole(project.id)}
                        >{project.name}</Btn>)
                    }
                    onCloseModal={() => setPopupShow(false)}
                />)
            } else choseRole(firstProject);
        }).catch(error => {
            console.log('\n ', error);
        });

    }
    const userCompanies = userSettings.companies.sort((a, b) => a.id - b.id);

    const [companyTariffs, setCompanyTariffs] = useState(null);
    useEffect(() => {

        const getData = async () => {

            try {

                const companyIdList = userCompanies.filter(company => !company.subcompany).map(company => company.id);
                const getTariffs = await getTariffsCompanies(companyIdList, userSettings);

                // console.log('\n getTariffs', getTariffs);
                setCompanyTariffs(getTariffs);

            } catch (err) {
                console.log('\n getData', err);
            }
        }

        if (userCompanies?.length > 0) getData();
    }, [userCompanies, userSettings]);
    const choseTariffsHandler = async (company_id) => {

        const getSystemTariffs = await systemTariffs(userSettings);

        const setTariffHandler = async (tariff_id) => {

            try {

                setPopupShow(false);

                const setTariff = await setTariffsCompany(company_id, tariff_id, userSettings)

                // console.log('\n setTariffHandler', setTariff);

                if (setTariff) window.location.reload();

            } catch (errors) {

                console.log('\n setTariffHandler', errors);
            }
        }

        if (getSystemTariffs?.length > 0) {

            // console.log('\n getSystemTariffs', getSystemTariffs);

            const roles = [
                {
                    key: 'administrator',
                    name: 'Администратор',
                },
                {
                    key: 'coordinator',
                    name: 'Координатор',
                },
                {
                    key: 'manager',
                    name: 'Начальник участка',
                },
                {
                    key: 'build_control',
                    name: 'Стройконтроль',
                },
                {
                    key: 'gip',
                    name: 'ГИП',
                },
                {
                    key: 'contract',
                    name: 'Договор с подрядчиком',
                },
            ];

            const list = <Box className={`flex flex-row flex-wrap gap-2`}>
                {getSystemTariffs.map(t =>
                    <CardContent>
                        <Typography variant="h4">{t.name}</Typography>
                        {roles.map(role =>
                            <Box
                                className={`grid grid-cols-3 justify-items-stretch gap-1 border-b border-pult/20 border-dashed items-end`}>
                                <Typography className={`col-span-2`}>{role.name}</Typography>
                                <Typography className={`justify-self-end`}>{t[role.key]}</Typography>
                            </Box>
                        )}
                        <Box className={`grid items-center mt-2`}>
                            <Button variant="outlined" color="info" onClick={() => setTariffHandler(t.id)}>
                                Выбрать {Price(t.payment_per_month)} руб. в мес.
                            </Button>
                        </Box>
                    </CardContent>
                )}
            </Box>

            setPopupShow(<Popup
                title={`Выберите тариф`}
                content={list}
                onCloseModal={() => setPopupShow(false)}
            />)
        }
    }

    const [billIsSend, setBillIsSend] = useState(26);
    const selectPaymentOption = async (company_id) => {

        const currentCompany = userCompanies.find(c => c.id === company_id);
        const paymentAmount = await getPaymentAmount(company_id, userSettings);
        const amount = Math.abs(paymentAmount?.payment_difference);
        // console.log('\n amount', amount);

        const billPayment = async () => {

            try {

                setPopupShow(false);
                const getPaymentOption = await paymentDetails(userSettings);
                const billDate = new Date().toLocaleDateString('ru-RU', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    timezone: 'UTC'
                });
                const guid = await generateGUID();

                // console.log('\n currentCompany', currentCompany);
                // console.log('\n getPaymentOption', getPaymentOption);
                // console.log('\n billDate', billDate);
                // console.log('\n guid', guid);

                const generateBill = <Bill
                    bank_name={getPaymentOption.bank}
                    company_name={getPaymentOption.name}
                    inn={getPaymentOption.inn}
                    kpp={getPaymentOption.kpp}
                    bank_account={getPaymentOption.settlement_account}
                    ks={getPaymentOption.correspondent_account}
                    bik={getPaymentOption.bik}
                    buyer={`${currentCompany.name} ИНН: ${currentCompany.inn}, ${currentCompany.legal_address}`}
                    tariffName={currentCompany.name}
                    tariffPriceCost={amount}
                    tariffCost={amount}
                    billNumber={guid}
                    billDate={billDate}
                />

                const bill = await pdf(generateBill).toBlob();
                saveAs(bill, `Счет_№_${guid}_от_${billDate}.pdf`);
                const billName = `Счет_№_${guid}_от_${billDate}.pdf`;

                const saveBillHandler = await saveBill(company_id, bill, billName, amount, userSettings);
                // console.log('\n saveBillHandler', saveBillHandler);
                if (saveBillHandler) setBillIsSend(company_id);
            } catch (errors) {
                console.log('\n selectPaymentOption', errors);
            }
        }

        setPopupShow(<Popup
            title={`Выберите способ оплаты`}
            content={(<Box className={`flex items-center gap-2`}>
                <Button
                    variant="outlined"
                    component={Link}
                    to={`/work/payment-card/?company=${company_id}&amount=${amount}`}
                >Оплата картой</Button>
                {currentCompany.bank && <Button
                    variant="outlined"
                    onClick={billPayment}
                >Оплата по счету</Button>}
            </Box>)}
            onCloseModal={() => setPopupShow(false)}
        />);

        // console.log('\n selectPaymentOption', company_id);
    }

    // if (userCompanies) console.log('\n userCompanies', userCompanies);
    // if (companyTariffs) console.log('\n companyTariffs', companyTariffs);
    const leaveAccount = () => {
        console.log('Выполняется функция leaveAccount', document.cookie);
        document.cookie = "access =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        setCookie("access", "", {
            sameSite: "Lax",
            secure: true,
            maxAge: -1,
        });

        console.log('После удаления всех куков:', document.cookie);
        window.location.replace("/authorization");
    }


    return (
        <UserSection>
            <Button
                className="w-fit"
                variant="contained"
                href="/"
                onClick={leaveAccount}
            >
                Вернуться на главную
            </Button>
            {popupShow}
            <ul>
                <Grids cols={4}>
                    {userCompanies.map(company => {

                        const accessCompany = userSettings.user_companies.find(uc => uc.company === company.id);
                        // console.log(`\n accessCompany`, accessCompany);
                        const checkTariff = !company.subcompany && companyTariffs?.find(t => t.id === company.id)?.tariff;
                        // console.log('\n checkTariff', checkTariff);
                        const isPaid = company.is_paid;
                        // console.log('\n isPaid', isPaid);

                        const state_account = +company.state_account;
                        // if (checkTariff) console.log('\n state_account', state_account);

                        const billSent = billIsSend === company.id;
                        // console.log('\n billSent', billSent);

                        return (
                            <li key={company.id} className="border p-3 rounded-md bg-pult-hover/10">
                                <Box className="text-center mb-2 border-b border-pult/10">
                                    <Typography>{company.subcompany ? `Подрядчик` : `Компания`}</Typography>
                                    <Typography variant="h2">{company.name}</Typography>
                                    {checkTariff && <Box>
                                        <Alert
                                            className={`w-max mx-auto`}
                                            severity={state_account < 0 ? "error" : "success"}
                                        >
                                            Лицевой счет: {Price(state_account)} р.
                                        </Alert>
                                        {!isPaid && <Box>
                                            <Alert
                                                className={`w-full whitespace-pre-wrap`}
                                                severity={billSent ? "info" : "warning"}
                                            >
                                                {
                                                    billSent ?
                                                        "Выставлен счет, работа компании будет возобновлена после оплаты" :
                                                        "Есть задолженность, работа компании приостановлена"
                                                }
                                            </Alert>
                                            <Button
                                                variant="outlined"
                                                startIcon={<PaymentRoundedIcon/>}
                                                onClick={() => selectPaymentOption(company.id)}
                                            >Пополнить счет</Button>
                                        </Box>}
                                    </Box>}
                                </Box>

                                {!company.subcompany ?
                                    checkTariff ?
                                        <Box>
                                            <div>Выберите роль:</div>
                                            <ul>
                                                {accessCompany && accessCompany.roles.map(role =>
                                                    <li key={role}>
                                                        <Btn
                                                            color='button'
                                                            icon={<User/>}
                                                            onClick={() => choseRoleHandler(company.id, role)}
                                                        >
                                                            {userSettings.template_roles[role]}
                                                        </Btn>
                                                    </li>
                                                )}
                                            </ul>
                                        </Box> :
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <Alert severity="warning">Отсутствует тариф компании</Alert>
                                            <Btn
                                                color={`button`}
                                                method={`tariff`}
                                                center
                                                onClick={() => choseTariffsHandler(company.id)}
                                            >Выбрать тариф</Btn>
                                        </Box>
                                    :
                                    <React.Fragment>
                                        <SubCompanyAccess
                                            company={company}
                                            userSettings={userSettings}
                                        />
                                    </React.Fragment>
                                }
                            </li>
                        );
                    })}
                </Grids>
            </ul>
        </UserSection>
    );
}

export default Hello;
