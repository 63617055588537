import styles from "./inputs.module.css";
import {useFormContext} from "react-hook-form";

const InputFile = ({name, label, required}) => {

    const {register, formState: {errors}} = useFormContext();

    return (
        <div className={`${styles['field-file']} ${errors[name] ? styles.invalid : ''}`}>
            <label htmlFor={name}>{label}</label>
            <input
                type="file"
                name={name}
                {...register(name, {
                    validate: (files) => {
                        if (required) return files.length > 0;
                        return true;
                    }
                })}
            />
        </div>
    );
}

export default InputFile;
