import React, {useContext, useEffect, useState} from "react";
import UserSection from "../../UI/Sections/UserSection";
import UserContext from "../../../store/user/user-context";
import axios from "axios";
import {ErrorOccurred} from "../../UI/Sections/ErrorOccurred";
import tables from "../../UI/Tables/table.module.css";
import styles from "../../UI/Tables/table.module.css";
import {Price} from "../../UI/Convertors/Price";
import DiagramFinancialSchedule from "../../UI/Diagrams/DiagramFinancialSchedule";
import {useNavigate} from "react-router-dom";

export default function FinancialControlPlanFact() {

    const navigate = useNavigate();
    const role = +window.localStorage.getItem('role');
    if (role !== 0 && role !== 4) navigate('/work/');

    const userSettings = useContext(UserContext);
    const company_id = +window.localStorage.getItem('company');
    const project_id = +window.localStorage.getItem('project');

    const [answers, setAnswers] = useState(null);
    const [financialSchedule, setFinancialSchedule] = useState(null);
    const [summaryAllRowsMonths, setSummaryAllRowsMonths] = useState(null);
    const [summaryAllRowsPeriods, setSummaryAllRowsPeriods] = useState(null);

    const types = {
        plan: "План",
        fact: "Факт",
    };

    const tableNames = [
        {
            name: "ССР",
            type: {span: Object.keys(types).length},
            key: "ssr",
        },
        {
            name: "Наименование работ и затрат",
            type: {span: Object.keys(types).length},
            key: "name",
        },
        {
            name: "Общая сметная стоимость",
            type: types,
            key: "summary_periods",
        }
    ];
    // const secondTableNames = tableNames?.map(n => n.type?.span ? null : n.type).filter(n => n);
    financialSchedule && [...new Set(financialSchedule.flatMap(f => Object.keys(f.periods)))]
        .sort()
        .forEach(date => {
            const months = [
                "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
                "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
            ];
            const month = months[new Date(date * 1000).getMonth()];
            const year = new Date(date * 1000).getFullYear();
            tableNames.push({
                name: (<React.Fragment>
                    <div>{month}</div>
                    <div>{year}</div>
                </React.Fragment>),
                type: types,
                date
            });
        })
    ;

    function recalculateValues(data) {
        // Преобразуем объект в массив пар [date, value] и сортируем по датам
        const sortedEntries = Object.entries(data).sort((a, b) => Number(a[0]) - Number(b[0]));

        // Результирующий объект
        const result = {};

        // Первая дата остается без изменений
        result[sortedEntries[0][0]] = sortedEntries[0][1];

        // Перерасчет значений для остальных дат
        for (let i = 1; i < sortedEntries.length; i++) {
            const currentDate = sortedEntries[i][0];
            const currentValue = sortedEntries[i][1];
            const previousValue = result[sortedEntries[i - 1][0]];

            result[currentDate] = currentValue + previousValue;
        }

        return result;
    }

    useEffect(() => {

        const getInfo = async () => {
            try {
                const getFinancialSchedule = await axios.get(
                    `${userSettings.api}/finplan/companies/${company_id}/projects/${project_id}/`,
                    userSettings.headers.get
                );
                const financialSchedule = getFinancialSchedule.data['financial_plan']['json'];

                const updateSummaryAllRowsMonths = {
                    plan: {},
                    fact: {},
                };
                const updateFinancialSchedule = Object.keys(financialSchedule).map(id => {
                    const row = financialSchedule[id];
                    const updatePeriods = {};
                    const summary_periods = {
                        plan: [],
                        fact: []
                    };

                    Object.keys(row.periods).forEach(date => {

                        if (!updatePeriods[date]) updatePeriods[date] = {};
                        updatePeriods[date].plan = row.periods[date];

                        summary_periods.plan.push(row.periods[date]);

                        if (row.type === "row") {
                            if (!updateSummaryAllRowsMonths.plan[date]) updateSummaryAllRowsMonths.plan[date] = [row.periods[date]];
                            updateSummaryAllRowsMonths.plan[date].push(row.periods[date]);
                        }
                    })
                    row.periods = updatePeriods;

                    summary_periods.plan = summary_periods.plan.length > 0 && summary_periods.plan?.reduce((acc, w) => (acc + w), 0);
                    summary_periods.fact = summary_periods.fact.length > 0 && summary_periods.fact?.reduce((acc, w) => (acc + w), 0);
                    return ({
                        ...row,
                        id,
                        summary_periods,
                    });
                });
                setFinancialSchedule(updateFinancialSchedule);

                let previousDate;
                Object.keys(updateSummaryAllRowsMonths.plan).forEach(date => {
                    updateSummaryAllRowsMonths.plan[date] = updateSummaryAllRowsMonths.plan[date].reduce((acc, w) => (acc + w), 0);
                    updateSummaryAllRowsMonths.plan[date] = previousDate
                        ? updateSummaryAllRowsMonths.plan[previousDate] + updateSummaryAllRowsMonths.plan[date]
                        : updateSummaryAllRowsMonths.plan[date];
                    previousDate = date;
                });
                Object.keys(updateSummaryAllRowsMonths.fact).forEach(date => {
                    updateSummaryAllRowsMonths.fact[date] = updateSummaryAllRowsMonths.fact[date].reduce((acc, w) => (acc + w), 0);
                });

                console.log(`\n updateSummaryAllRowsMonths`, updateSummaryAllRowsMonths);
                setSummaryAllRowsMonths(updateSummaryAllRowsMonths);

                const plan = Object.keys(updateSummaryAllRowsMonths.plan).length > 0 && recalculateValues(updateSummaryAllRowsMonths.plan);
                const fact = Object.keys(updateSummaryAllRowsMonths.fact).length > 0 && recalculateValues(updateSummaryAllRowsMonths.fact);

                const updateSummaryAllRowsPeriods = {
                    plan,
                    fact,
                };
                // console.log(`\n updateSummaryAllRowsPeriods`, updateSummaryAllRowsPeriods);
                setSummaryAllRowsPeriods(updateSummaryAllRowsPeriods);
            } catch (error) {
                const responseError = ErrorOccurred(
                    error.response,
                    "Ошибка получения данных",
                    "getData",
                    "",
                    true
                );
                setAnswers(responseError.jsx);
            }
        }

        getInfo();
    }, [company_id, project_id, userSettings]);

    // console.log(`\n tableNames`, tableNames);
    // if (financialSchedule) console.log(`\n financialSchedule`, financialSchedule);
    // if (summaryAllRowsMonths) console.log(`\n summaryAllRowsMonths`, summaryAllRowsMonths);
    // if (summaryAllRowsPeriods) console.log(`\n summaryAllRowsPeriods`, summaryAllRowsPeriods);


    return (
        <UserSection>
            <h1>Контроль финансов</h1>
            {answers && answers}
            {summaryAllRowsMonths && <DiagramFinancialSchedule data={summaryAllRowsMonths}/>}
            <div className={`${tables.container} grow ${styles.container}`}>
                <table className={tables.table}>
                    <thead>
                    <tr>
                        {tableNames.map((n, index) => (
                            <th
                                key={index}
                                rowSpan={n.type?.span}
                                colSpan={!n.type?.span && Object.keys(n.type).length}
                                className={`
                                    ${index === 0 ? "sticky left-0 z-30 bg-amber-400" : ""}
                                    ${index === 1 ? "sticky left-[67px] z-30 bg-amber-400" : ""}
                               `}
                            >
                                {n.name}
                            </th>
                        ))}
                    </tr>
                    <tr>
                        {tableNames
                            .filter(n => !n.type?.span)
                            .flatMap((n, index) =>
                                Object.values(n.type).map((name, id) => (
                                    <th key={`${id}-${index}`}>
                                        {name}
                                    </th>
                                ))
                            )
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {financialSchedule?.map((f, index) => {

                        const summary_periods = f.summary_periods;
                        const countDots = (str) => (str.split('.').length - 1);
                        const types = [
                            'group',
                            'sub-group',
                            'sub-sub-group',
                        ];

                        return (
                            <tr key={index} className={f.type === "group" ? tables[types[countDots(f.ssr)]] : ""}>
                                {tableNames.map((n, id) => {

                                    const printDate = n.date && f.periods[n.date];

                                    return (
                                        (n.key === "summary_periods") ?
                                            summary_periods ?
                                                <React.Fragment key={id}>
                                                    <td className={`text-center whitespace-nowrap`}>{summary_periods.plan > 0 && Price(summary_periods.plan)}</td>
                                                    <td className={`text-center whitespace-nowrap`}>{summary_periods.fact > 0 && Price(summary_periods.fact)}</td>
                                                </React.Fragment> :
                                                <React.Fragment key={id}>
                                                    <td/>
                                                    <td/>
                                                </React.Fragment> :
                                            n.date ?
                                                <React.Fragment key={id}>
                                                    <td className={`text-center whitespace-nowrap`}>
                                                        {printDate?.plan &&
                                                            Price(printDate?.plan)
                                                        }
                                                    </td>
                                                    <td className={`text-center whitespace-nowrap`}>
                                                        {printDate?.fact &&
                                                            Price(printDate?.fact)
                                                        }
                                                    </td>
                                                </React.Fragment> :
                                                <td key={id}
                                                    className={`
                                                    ${n.key !== "name" ? "text-center" : "min-w-[300px]"}
                                                    ${id === 0 ? "sticky left-0 top-[80px] z-50 bg-[#C47A45]" : ""}
                                                    ${id === 1 ? "sticky left-[67px] top-[80px] z-50 bg-[#C47A45]" : ""}
                                                    `}>
                                                    {f[n.key]}
                                                </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    {summaryAllRowsMonths && <React.Fragment>
                        <tr key={`all`}>
                            <td colSpan={2}>Итого за месяц</td>
                            <td className={`text-center whitespace-nowrap`} rowSpan={2}>
                                {Object.values(summaryAllRowsMonths.plan).reduce((acc, w) => (acc + w), 0) > 0 &&
                                    Price(Object.values(summaryAllRowsMonths.plan).reduce((acc, w) => (acc + w), 0))
                                }
                            </td>
                            <td className={`text-center whitespace-nowrap`} rowSpan={2}>
                                {Object.values(summaryAllRowsMonths.fact).reduce((acc, w) => (acc + w), 0) > 0 &&
                                    Price(Object.values(summaryAllRowsMonths.fact).reduce((acc, w) => (acc + w), 0))
                                }
                            </td>
                            {tableNames
                                .filter(n => n.date)
                                .map((n, id) => {

                                        return (
                                            <React.Fragment key={id}>
                                                <td className={`text-center whitespace-nowrap`}>{summaryAllRowsMonths?.plan[n.date] > 0 &&
                                                    Price(summaryAllRowsMonths?.plan[n.date])
                                                }</td>
                                                <td className={`text-center whitespace-nowrap`}>{summaryAllRowsMonths?.fact[n.date] > 0 &&
                                                    Price(summaryAllRowsMonths?.fact[n.date])
                                                }</td>
                                            </React.Fragment>
                                        )
                                    }
                                )
                            }
                        </tr>
                        <tr key={`all_summary`}>
                            <td colSpan={2}>Итого за период</td>
                            {tableNames
                                .filter(n => n.date)
                                .map((n, id) => {

                                        return (
                                            <React.Fragment key={id}>
                                                <td className={`text-center whitespace-nowrap`}>{summaryAllRowsPeriods?.plan[n.date] > 0 &&
                                                    Price(summaryAllRowsPeriods?.plan[n.date])
                                                }</td>
                                                <td className={`text-center whitespace-nowrap`}>{summaryAllRowsPeriods?.fact[n.date] > 0 &&
                                                    Price(summaryAllRowsPeriods?.fact[n.date])
                                                }</td>
                                            </React.Fragment>
                                        )
                                    }
                                )
                            }
                        </tr>
                    </React.Fragment>}
                    </tbody>
                </table>
            </div>
        </UserSection>
    );
}