import Section from "../../UI/Sections/Section";
import PageSection from "../../UI/Sections/PageSection";
import Alink from "../../UI/Buttons/Alink";

const PaySuccess = (props) => {

    return (
        <PageSection>
            <Section>
                <p>Оплата прошла успешно</p>
                <br />
                <Alink href='/'>Перейти на главную</Alink>
                <p className="text-gray-500 text-sm italic"><a href="/create-password?new-user">переход по ссылке из письма</a></p>
            </Section>
        </PageSection>
    );
}

export default PaySuccess;