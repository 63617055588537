import { useEffect, useState } from 'react';

const useMixedPersons = (id, representative_id, list) => {
    const [responsiblePerson, setResponsiblePerson] = useState(null);
    const [representativePerson, setRepresentativePerson] = useState(null);

    useEffect(() => {
        if (!list) return;
        const responsePerson = list
            .filter(person => String(person.id) === id)[0];
        setResponsiblePerson(responsePerson);

        if (!representative_id || !responsePerson) return;

        const representPerson = responsePerson.representatives
            .filter(representative => String(representative.id) === representative_id)[0];
        setRepresentativePerson(representPerson);
    }, [id, representative_id, list]);

    return { responsiblePerson, representativePerson };
};

export default useMixedPersons;