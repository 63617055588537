import PageSection from "../../UI/Sections/PageSection";
import Section from "../../UI/Sections/Section";
import Alink from "../../UI/Buttons/Alink";

const WaitBillPay = (props) => {

    return (
        <PageSection>
            <Section>
                <p>Ожидается оплата по счету {document.location.search.replace('?','')}</p>
                <br/>
                <div className="w-max mx-auto"><Alink href="/">Перейти на главную</Alink></div>
                <p className="text-gray-500 text-sm italic"><a href="/create-password?new-user">переход по ссылке из письма</a></p>
            </Section>
        </PageSection>
    );
}

export default WaitBillPay;