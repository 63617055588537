import React, {useState} from "react";
import Button from "../../UI/Buttons/Button";
import {FormProvider, useForm} from "react-hook-form";
import RegistrationInput from "../../UI/Inputs/RegistrationInput";
import Btn from "../../UI/Buttons/Btn";

export default function TaskEngineer(
    {
        createOrder,
        sendGipOrderHandler,
        order,
        editGipOrderHandler,
        moveGipOrderHandler,
        handleClosePopup
    }
) {

    const methods = useForm();
    const role = +window.localStorage.getItem('role');

    const [state, setState] = useState(null);

    // console.log(`\n createOrder`, createOrder);
    // if (order) console.log(`\n order`, order);

    const sendGipOrder = (data) => {
        data.coordinate_x = createOrder[0];
        data.coordinate_y = createOrder[1];
        
        // console.log(`\n data`, data);
        sendGipOrderHandler(data);
    }
    const editGipOrder = (data) => {

        data.coordinate_x = order.coordinate_x;
        data.coordinate_y = order.coordinate_y;
        data.floor = order.floor;
        data.id = order.id;

        // console.log(`\n data`, data);
        editGipOrderHandler(data);
    }
    const moveGipOrder = () => {
        console.log(order)
        moveGipOrderHandler(order.id, "gip");
        handleClosePopup();
    }

    if (!order) return (<FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(sendGipOrder)}>
                <RegistrationInput
                    name={`comment`}
                    type={`text`}
                    label={'Комментарий к задаче'}
                />
                {/*<RegistrationInput*/}
                {/*    name={`document`}*/}
                {/*    type={`file`}*/}
                {/*    label={'Загрузить документ'}*/}
                {/*/>*/}
                <div className={`mx-auto w-max`}>
                    <Button type="submit">Записать предписание</Button>
                </div>
            </form>
        </FormProvider>);
    else return (!state ?
            <React.Fragment>
                <div>Комментарий к предписанию: {order.comment}</div>
                <div className={`flex gap-2 items-center`}>
                    <Btn center method={'edit'} color={`button`} onClick={() => setState('edit')}>Редактировать</Btn>
                    {role === 4 && <Btn center icon={<svg fill="currentColor" viewBox="0 0 16 16" height="1em" width="1em"><path fillRule="evenodd" d="M7.646.146a.5.5 0 01.708 0l2 2a.5.5 0 01-.708.708L8.5 1.707V5.5a.5.5 0 01-1 0V1.707L6.354 2.854a.5.5 0 11-.708-.708l2-2zM8 10a.5.5 0 01.5.5v3.793l1.146-1.147a.5.5 0 01.708.708l-2 2a.5.5 0 01-.708 0l-2-2a.5.5 0 01.708-.708L7.5 14.293V10.5A.5.5 0 018 10zM.146 8.354a.5.5 0 010-.708l2-2a.5.5 0 11.708.708L1.707 7.5H5.5a.5.5 0 010 1H1.707l1.147 1.146a.5.5 0 01-.708.708l-2-2zM10 8a.5.5 0 01.5-.5h3.793l-1.147-1.146a.5.5 0 01.708-.708l2 2a.5.5 0 010 .708l-2 2a.5.5 0 01-.708-.708L14.293 8.5H10.5A.5.5 0 0110 8z"/></svg>} color={`button`} onClick={moveGipOrder}>Передвинуть</Btn>}
                </div>
            </React.Fragment>
            :
            state === 'edit' && <React.Fragment>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(editGipOrder)}>
                        <RegistrationInput
                            name={`comment`}
                            type={`text`}
                            label={'Комментарий к задаче'}
                            defaultValue={order.comment}
                        />
                        <div className={`mx-auto w-max`}>
                            <Button type="submit">Записать предписание</Button>
                        </div>
                    </form>
                </FormProvider>
                <Btn method={'back'} color={`button`} center onClick={() => setState(null)}>Назад</Btn>
            </React.Fragment>
    );
}