import React, {useContext, useEffect, useState} from 'react';
import {Alert, Box, Button, ButtonGroup, MenuItem, Snackbar, Tooltip} from "@mui/material";
import RegistrationInput from "../../UI/Inputs/RegistrationInput";
import {FormProvider, useForm} from "react-hook-form";
import axios from "axios";
import UserContext from "../../../store/user/user-context";
import InputFile from "../../UI/Inputs/InputFile";
import ReactHookFormMUISelect from "../common/components/ReactHookFormMUISelect";

const AddSignatoryModal =
    ({
         contract,
         signatories,
         setSignatories,
         setPopupShow,
         setAddedPerson,
         setAddedSubcompanySign,
         isMain = false
     }) => {
        const userSettings = useContext(UserContext);

        const [clicked, setClicked] = useState(false);
        const [signatory_id, setSignatory_id] = useState(null);

        const methods = useForm();

        const {
            control,
            handleSubmit,
            unregister,
            setValue
        } = methods;

        const getFields = (fields) => {
            return fields.map(field =>
                <RegistrationInput
                    key={field.name}
                    type={field.type}
                    name={field.name}
                    label={field.label}
                    notRequired={field.notRequired}
                    placeholder={field.placeholder}
                    options={field.options}
                    defaultValue={field.defaultValue}
                    min={field.min}
                    max={field.max}
                    step={field.step}
                />
            )
        }

        const fieldsSignatory = [
            {"name": "position", "type": "text", "label": "Должность"},
            {"name": "last_name", "type": "text", "label": "Фамилия"},
            {"name": "first_name", "type": "text", "label": "Имя"},
            {"name": "second_name", "type": "text", "label": "Отчество"}
        ];

        const addSignatory = async (data) => {
            const body = {...data, company: isMain ? contract.main : contract.subcompany};
            console.log("body", body);

            const response = await axios.post(`${userSettings.api}/signatories/`, body, userSettings.headers.post);
            console.log("added person");
            setAddedPerson(true);
            console.log("made is true");
            setSignatories(prev => ([...prev, response.data]));

            fieldsSignatory.forEach(field => unregister(field.name));

            // setClicked(true);
            setPopupShow(false);
        }

        const addDocumentToSignatory = async (data) => {
            console.log(data);
            const formData = new FormData();
            formData.append('signatory', signatory_id);
            formData.append('based', data.based);
            formData.append('based_document', data.based_document[0]);

            try {
                const addDoc = await axios.post(`${userSettings.api}/subcompanies/${contract.subcompany}/companies/${contract.main}/projects/${contract.project_id}/tenders/${contract.tender}/signatories/${isMain ? "company" : "subcompany"}_signatory/`, formData, {headers: userSettings.headers.postForm});
                console.log(addDoc);
                setAddedSubcompanySign(true);
                setPopupShow(false);
            } catch (e) {
                console.log("error: ", e)
            }
        }

        const fieldsFile = [{"name": "based", "type": "text", "label": "На основании"}];

        const handleSelectChange = (event) => {
            const selectedId = event.target.value;
            setSignatory_id(selectedId);
            setValue('signatories', selectedId);
            console.log("Selected Signatory ID:", selectedId);
        };

        console.log("Current signatory_id state:", signatory_id);

        const default_s = signatories?.filter(s => s.is_default)[0] || {};
        const defaultValue = default_s.id || "";

        useEffect(() => {
            if (!defaultValue) return;
            setSignatory_id(defaultValue);
        }, []);

        return (
            <FormProvider {...methods}>
                <form>
                    <Box>
                        {!clicked &&
                            <Box>
                                {getFields(fieldsSignatory)}
                                <Box className='flex justify-center'>
                                    <ButtonGroup>
                                        <Button onClick={handleSubmit(addSignatory)}
                                                variant='outlined'>Добавить</Button>
                                        {isMain && contract.company_signatory ||
                                        isMain && !contract.subcompany_signatory ||
                                        !isMain && contract.subcompany_signatory
                                            ? <Tooltip placement={"top"}
                                                       title={isMain && !contract.subcompany_signatory
                                                           ? "Сначала должен быть добавлен подписант со стороны подрядчика"
                                                           : `Уже подписано со стороны ${isMain ? "компании" : "подрядчика"}`
                                                       }
                                            >
                                                <Box>
                                                    <Button
                                                        disabled
                                                        variant='contained'
                                                    >
                                                        Выбрать текущего
                                                    </Button>
                                                </Box>
                                            </Tooltip>
                                            : <Button
                                                onClick={() => setClicked(true)}
                                                variant='contained'
                                            >
                                                Выбрать текущего
                                            </Button>
                                        }
                                    </ButtonGroup>
                                </Box>
                            </Box>
                        }

                        {clicked &&
                            <Box>
                                {signatories &&
                                    <ReactHookFormMUISelect
                                        id="signatories"
                                        name="signatories"
                                        label="Подписанты"
                                        onChange={handleSelectChange}
                                        control={control}
                                        defaultValue={defaultValue}
                                        variant="outlined"
                                        required={true}
                                    >
                                        {signatories.map(sign =>
                                            <MenuItem key={sign.id} value={sign.id}>{sign.full_name}</MenuItem>
                                        )}
                                    </ReactHookFormMUISelect>
                                }

                                {getFields(fieldsFile)}
                                <InputFile required={true} name="based_document" label='На основании'/>

                                <Box className='flex justify-center'>
                                    <Button
                                        onClick={handleSubmit(addDocumentToSignatory)}
                                        variant='contained'
                                    >
                                        Загрузить документ
                                    </Button>
                                </Box>
                            </Box>
                        }
                    </Box>
                </form>
            </FormProvider>
        );
    };

export default AddSignatoryModal;