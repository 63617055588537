import React, {useContext, useEffect, useState} from 'react';
import UserSection from "../../UI/Sections/UserSection";
import {Link, useNavigate} from "react-router-dom";
import UserContext from "../../../store/user/user-context";
import axios from "axios";
import styles from "../dash.module.css";
import Btn from "../../UI/Buttons/Btn";
import {getSubcompanies} from "../../UI/GetInfo/getSubcompanies";
import Warnings from "../../UI/Sections/Warnings";

export default function AcceptanceTransferSite () {

    const navigate = useNavigate();
    if (window.localStorage.getItem('role') !== '4') navigate('/work/');

    const userSettings = useContext(UserContext);
    const company_id = window.localStorage.getItem('company');
    const project_id = window.localStorage.getItem('project');

    const [subcompanies, setSubcompanies] = useState(null);

    useEffect(() => {

        const getInfo = async () => {
            try {
                const getSubcompaniesData = await getSubcompanies(userSettings, company_id, project_id);

                const registerActsPromises = getSubcompaniesData.map(company =>
                    axios.get(
                        `${userSettings.api}/subcompanies/${company.subcompany}/companies/${company_id}/projects/${project_id}/tenders/${company.tender.id}/register-acts/`,
                        userSettings.headers.get
                    ).then(response => ({
                        acts: response.data?.length > 0 ? response.data : null,
                        subcompany_id: company.subcompany,
                        tender_id: company.tender.id,
                    }))
                );

                const actsSubcompanies = await Promise.all(registerActsPromises);

                const subcompanies = getSubcompaniesData.map(subcompany => ({
                    ...subcompany,
                    acts: actsSubcompanies.find(a => a.tender_id === subcompany.tender.id)?.acts || []
                }));

                setSubcompanies(subcompanies);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        }

        getInfo();
    }, [userSettings, company_id, project_id]);

    if (subcompanies) console.log('\n subcompanies', subcompanies);

    return (
        <UserSection>
            <h1>Приём-передача участка</h1>
            {subcompanies?.length > 0 &&
                <div className={`flex flex-wrap gap-2`}>
                    {subcompanies.map(subcompany => {

                        return (
                            <div key={subcompany.id} className={styles.card}>
                                <div className={styles.label}>{subcompany.tender.name}</div>
                                <div className={styles.area}>
                                    {subcompany.acts?.length > 0 ?
                                        subcompany.acts.map(act => {

                                            return (
                                                <Btn method={`view`} color={`button`} center key={`${subcompany.tender.id}_${act.id}`}>
                                                    <Link to={`${userSettings.fileServer}${act.file}`} target={"_blank"}>{act.name}</Link>
                                                </Btn>
                                            );
                                        }) :
                                        <Warnings>Отсутствуют акты, свяжитесь с Координатором</Warnings>
                                    }
                                </div>
                            </div>
                        );
                    })}
                </div>
            }
        </UserSection>
    );
}