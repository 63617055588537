export const getLotsForRequest = (request) => {
    const replaceObj = {
        investor: "Инвестор",
        administrator: "Администратор",
        coordinator: "Координатор",
        build_control: "Стройконтроль",
        manager: "Начальник участка",
        gip: "ГИП",
        contract: "Контракт",
    }
    const result = []
    for (const [key, value] of Object.entries(request)) {
        if (key.startsWith("add_") && value > 0) result.push({key: key.slice(4), value});
    }

    return result.map(role => ({...role, name: replaceObj[role.key]}));
}