import axios from 'axios';

export async function sendSequentialRequests(requests) {
    const results = [];

    for (const request of requests) {
        try {
            const response = await axios({
                method: request.method,
                url: request.url,
                data: request.data,
                headers: request.headers
            });

            results.push({
                response: response.data,
                url: request.url,
                error: false
            });
        } catch (error) {
            let errorMessage = null;
            if (error.response && error.response.data && error.response.data.errors) {
                errorMessage = error.response.data.errors;
            } else if (error.response && error.response.status) {
                errorMessage = error.response.status;
            } else {
                errorMessage = 'Unknown error';
            }

            results.push({
                error: true,
                errorMessage,
                url: request.url
            });
        }
    }

    console.log(
        `\n requests`, requests,
        `\n results`, results,
    );

    return results;
}
