import React, {useContext, useEffect, useState} from "react";
import UserSection from "../../UI/Sections/UserSection";
import {useLocation, useNavigate} from "react-router-dom";
import UserContext from "../../../store/user/user-context";
import axios from "axios";
import Warnings from "../../UI/Sections/Warnings";
import {ErrorOccurred} from "../../UI/Sections/ErrorOccurred";
import Btn from "../../UI/Buttons/Btn";
import tables from "../../UI/Tables/table.module.css";
import TheadSort from "../../UI/Tables/TheadSort";
import {sendSequentialRequests} from "../../SendRequests/sendSequentialRequests";

export default function DefineSSR () {

    const navigate = useNavigate();
    if (window.localStorage.getItem('role') !== '4') navigate('/work/');

    const userSettings = useContext(UserContext);
    const company_id = +window.localStorage.getItem('company');
    const project_id = +window.localStorage.getItem('project');
    const contractData = new URLSearchParams(useLocation().search);
    const subcompany_id = +contractData.get('subcompany');
    const tender_id = +contractData.get('tender');

    const [answers, setAnswers] = useState(null);
    const [subcompany, setSubcompany] = useState(null);
    const [estimate, setEstimate] = useState(null);
    const [financialSchedule, setFinancialSchedule] = useState(null);
    const [selectedFinancialScheduleSsr, setSelectedFinancialScheduleSsr] = useState(null);
    // const completedEstimate = estimate && estimate.filter(e => e.ssr).length === estimate.length;

    const updateSsrValuesHandler = (id, ssr) => {

        // console.log(`\n updateSsrValuesHandler`, id, ssr);

        if (ssr) {
            const updateEstimate = estimate.map(e => {

                if (e.id === id) e.ssr = ssr;

                return e;
            });

            // console.log(`\n updateEstimate`, updateEstimate);
            setEstimate(updateEstimate);
        }
    };
    const saveEstimateHandler = async () => {

        try {

            const estimateWithSsr = estimate.filter(e => e.ssr);

            // console.log(`\n estimateWithSsr`, estimateWithSsr);

            if (estimateWithSsr.length > 0) {
                const requests = estimateWithSsr.map((e) => {

                    const element_id = e.id;

                    const formData = new FormData();
                    formData.append("ssr", e.ssr);

                    return ({
                        method: 'PUT',
                        url: `${userSettings.api}/work-journal/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/estimate-elements/${element_id}/modify-ssr/`,
                        data: formData,
                        headers: userSettings.headers.postForm
                    });
                });

                const results = await sendSequentialRequests(requests);
                // console.log(`\n results`, results);
                const errorsResult = results.filter(r => r.error).map(r => r.errorMessage).flat();
                // console.log(`\n errorsResult`, errorsResult);
                if (errorsResult.length > 0) {
                    const responseError = ErrorOccurred(
                        null,
                        "Ошибка определения ССР",
                        "saveEstimateHandler",
                        errorsResult
                    );
                    setAnswers(responseError.jsx);
                }

                const correctResult = results.filter(r => !r.error);
                if (correctResult.length > 0) {

                    window.location.reload();

                    // console.log(`\n correctResult`, correctResult);
                    // setAnswers(<Warnings correct>Данные успешно обновлены</Warnings>);
                }
            }
        }
        catch (error) {

            const responseError = ErrorOccurred(
                error.response,
                "Ошибка определения ССР",
                "saveEstimateHandler"
            );
            setAnswers(responseError.jsx);
        }
    }

    useEffect(() => {

        const getInfo = async () => {
            try {

                const updateFinancialSchedule = await axios.get(
                    `${userSettings.api}/finplan/companies/${company_id}/projects/${project_id}/`,
                    userSettings.headers.get
                );
                // console.log(`\n updateFinancialSchedule`, updateFinancialSchedule.data['financial_plan'].json);
                setFinancialSchedule(Object.values(updateFinancialSchedule.data['financial_plan'].json));
            }
            catch (error) {

                setFinancialSchedule(null);
            }
        }

        getInfo();
    },[company_id, project_id, userSettings]);
    useEffect(() => {

        const getInfo = async () => {
            try {

                const subcompanyInfo = await axios.get(
                    `${userSettings.api}/company/${subcompany_id}/`,
                    userSettings.headers.get
                );
                // console.log(`\n response`, response.data);
                setSubcompany(subcompanyInfo.data);
                const updateEstimate = await axios.get(
                    `${userSettings.api}/work-journal/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/estimate-elements/`,
                    userSettings.headers.get
                );
                // console.log(`\n updateEstimate`, updateEstimate.data.estimate);
                setEstimate(updateEstimate.data.estimate);
                setAnswers(null);
            }
            catch (error) {

                const responseError = ErrorOccurred(
                    error.response,
                    "Ошибка получения информации",
                    "getInfo"
                );
                setAnswers(responseError.jsx);
            }
        }

        if (financialSchedule) getInfo();
    },[financialSchedule, company_id, project_id, userSettings, tender_id, subcompany_id]);

    const sortNames = {
        estimate: [
            {
                tag: "number",
                name: "№",
            },
            {
                tag: "ssr",
                name: "ССР",
            },
            {
                tag: "name_works",
                name: "Наименование работ",
            },
            {
                tag: "units",
                name: "Ед. изм.",
            }
        ],
        financialSchedule: [
            {
                tag: "ssr",
                name: "ССР",
            },
            {
                tag:  "name",
                name: "Статья расходов",
            }
        ]
    }

    // if (subcompany) console.log(`\n subcompany`, subcompany);
    // if (estimate) console.log(`\n estimate`, estimate);
    // if (financialSchedule) console.log(`\n financialSchedule`, financialSchedule);
    // if (completedEstimate) console.log(`\n completedEstimate`, completedEstimate);

    return (
        <UserSection>
            <h1>Определить (ССР) {subcompany && `для компании ${subcompany.name}`}</h1>
            {answers&&answers}
            {financialSchedule ?
                <React.Fragment>
                    <div className={`md:flex`}>
                        <Btn color={`button`} method={`save`} onClick={saveEstimateHandler}>Сохранить</Btn>
                        {selectedFinancialScheduleSsr && <React.Fragment>
                            <Btn color={`button`} method={`cancel`} className={`md:mx-2`} onClick={() => setSelectedFinancialScheduleSsr(null)}>Отменить</Btn>
                            <div className={`my-auto`}>Выбранный ССР Финансового плана: {selectedFinancialScheduleSsr}</div>
                        </React.Fragment>}
                    </div>
                    <div className={`flex flex-row`}>
                        {estimate && <form className={`${tables.container} h-[85vh]`}>
                            <table className={tables.table}>
                                <TheadSort
                                    array={estimate}
                                    names={sortNames.estimate}
                                    setArray={setEstimate}
                                />
                                <tbody>
                                {estimate.length > 0 && estimate.map(e => <tr key={e.id} className={`text-center h-full`}>
                                    <td>{e.number}</td>
                                    <td className={`h-full`}>
                                        {selectedFinancialScheduleSsr ?
                                            <div
                                                className={`border border-pult/50 rounded-md cursor-pointer h-6 w-full`}
                                                onClick={() => updateSsrValuesHandler(e.id, selectedFinancialScheduleSsr)}
                                            >
                                                {e?.ssr}
                                            </div> :
                                            e?.ssr
                                        }
                                    </td>
                                    <td className={`text-left`}>{e.name_works}</td>
                                    <td>{e.units}</td>
                                </tr>)}
                                </tbody>
                            </table>
                        </form>}
                        {financialSchedule && <div className={`${tables.container} ml-2 h-[85vh]`}>
                            <table className={tables.table}>
                                <TheadSort
                                    array={financialSchedule}
                                    names={sortNames.financialSchedule}
                                    setArray={setFinancialSchedule}
                                />
                                <tbody>
                                {financialSchedule && Object.values(financialSchedule).filter(row => row.type === 'row').map(row => <tr key={row.ssr}>
                                    <td><Btn
                                        color={`${selectedFinancialScheduleSsr ?
                                            selectedFinancialScheduleSsr !== row.ssr ?
                                                `button` : `green`
                                            :
                                            'button'
                                        }`}
                                        className={`text-center`}
                                        full={true}
                                        method={selectedFinancialScheduleSsr ? selectedFinancialScheduleSsr === row.ssr ? `clone` : `view` : `clone`}
                                        onClick={() => setSelectedFinancialScheduleSsr(row.ssr)}
                                    ><span>{row.ssr}</span></Btn></td>
                                    <td>{row.name}</td>
                                </tr>)}
                                </tbody>
                            </table>
                        </div>}
                    </div>
                </React.Fragment> :
                <Warnings>Координатор не загрузил Финансовый план</Warnings>
            }
        </UserSection>
    );
}