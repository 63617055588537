import PageSection from "../../UI/Sections/PageSection";
import Section from "../../UI/Sections/Section";
import React, {useState} from "react";
import {useForm, FormProvider} from "react-hook-form";
import RegistrationInput from "../../UI/Inputs/RegistrationInput";
import axios from "axios";
import Warnings from "../../UI/Sections/Warnings";
import Btn from "../../UI/Buttons/Btn";
import {Link} from "react-router-dom";
import {Button} from "@mui/material";

const ForgotPassword = (props) => {

    const methods = useForm();
    const [isRested, setIsRested] = useState(false);
    const [isSend, setIsSend] = useState(null);
    const [answers, setAnswers] = useState(null);

    const resetPasswordHandler = async (data) => {

        const codes = {
            invalid: [
                {
                    name: "email",
                    message: "Указанный адрес электронной почты отсутствует в системе"
                }
            ],
        };

        setIsSend(true);

        try {

            const passwordReset = await axios.post(
                `${props.erpApiUrl}/password_reset/`,
                {"email": data.login},
                {
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            );
            // console.log(`\n passwordReset`, passwordReset);
            if (passwordReset.data.status === 'OK') {
                setIsRested(true);
                setAnswers(null);
            }

        } catch (error) {
            setIsSend(false);
            console.log(`\n error`, error.response);

            const errorResponse = error.response?.data?.errors;
            if (errorResponse && errorResponse.length > 0) {

                // console.log(`\n errorResponse`, errorResponse);

                const messages = errorResponse.map((e, id) => {

                    return (
                        <li key={id}>
                            {codes[e.code].find(code => code.name === e.attr).message}
                        </li>
                    )
                });
                // console.log(`\n messages`, messages);
                setAnswers(<Warnings>
                    <ul>{messages}</ul>
                </Warnings>)
            }
        }
    }

    return (
        <PageSection>
            <Button
                className="w-fit"
                variant="contained"
                href="/"
            >
                Вернуться на главную
            </Button>
            <Section>
                <h1>Восстановление пароля</h1>
                {answers && answers}
                {!isRested ?
                    <FormProvider {...methods} >
                        <form onSubmit={methods.handleSubmit(resetPasswordHandler)}>
                            <RegistrationInput
                                name="login"
                                label="Укажите адрес эл. почты"
                                type="email"
                            />
                            <div className={`flex items-center w-full`}>
                                {!isSend && <div className={`mx-auto w-max`}>
                                    <Button type="submit">
                                        Сбросить пароль
                                    </Button>
                                </div>}
                                <Btn color="button" center>
                                    <Link to={`/`}>На главную</Link>
                                </Btn>
                            </div>
                        </form>
                    </FormProvider>
                    :
                    <Warnings correct>На Ваш адрес электронной почты отправлено письмо с инструкцией</Warnings>
                }
            </Section>
        </PageSection>
    );
}

export default ForgotPassword;