import styles from "./buttons.module.css";

const Button = (props) => {

    return (
        <button
            type={props.type || "button"}
            className={`
                ${props.btn ? styles.btn : styles.button} 
                ${props.className ? props.className : ''}
                ${props.center ? styles.center : ''}
            `}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {props.children}
        </button>
    );
};

export default Button;
