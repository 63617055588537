import React from 'react';
import {Alert, Box, Typography} from "@mui/material";

const ReportKS6A = () => {
    return (
        <Box>
            <Typography variant="h1">Отчёт КС-6а</Typography>
            <Alert className='w-fit' severity="error">Страница находится в процессе разработке</Alert>
        </Box>
    );
};

export default ReportKS6A;