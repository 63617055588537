import axios from "axios";
import {Alert} from "@mui/material";

export const getFinancialPlan = async (userSettings, company_id, project_id) => {

    try {

        // console.log('\n getFinancialPlan', userSettings, company_id, project_id);

        const response = await axios.get(
            `${userSettings.api}/finplan/companies/${company_id}/projects/${project_id}/`,
            userSettings.headers.get
        );

        // console.log('\n response', response.data.financial_plan.json);
        return (response.data.financial_plan.json);
    }
    catch (error) {

        if (error.response.data) {
            // console.log('\n ', error.response.data.errors[0].code);
            return (error.response.data.errors[0].code);
        } else {
            console.log('\n getFinancialPlan error', error.response);
        }
    }
}

export const saveFinancialPlan = async (userSettings, company_id, project_id, data) => {

    try {

        // console.log('\n saveFinancialPlan', userSettings, company_id, project_id, data);

        const response = await axios.post(
            `${userSettings.api}/finplan/companies/${company_id}/projects/${project_id}/`,
            {json: data},
            userSettings.headers.post
        );

        // console.log('\n response', response);
        // console.log('\n response', response.data);

        return ({
            success: response.status === 200,
            fp: response.data.financial_plan.json
        })
    }
    catch (error) {
        console.error('saveFinancialPlan', error);

        if (error.response.data) {

            console.log('\n ', error?.response?.data.errors);

            return ({
                success: false,
                message: error?.response?.data.errors.map((e, i) => <Alert severity="error" key={i}>attr: {e.attr} detail: {e.detail} code: {e.code} </Alert>)
            })
        }
    }
}

export const putFinancialPlan = async (userSettings, company_id, project_id, data) => {

    try {

        // console.log('\n saveFinancialPlan', userSettings, company_id, project_id, data);

        const response = await axios.put(
            `${userSettings.api}/finplan/companies/${company_id}/projects/${project_id}/update-by-project/`,
            {json: data},
            userSettings.headers.post
        );

        return ({
            success: response.status === 200,
            fp: response.data.financial_plan.json
        })
        // console.log('\n response', response.data);
    }
    catch (error) {
        console.error('saveFinancialPlan', error);

        if (error.response.data) {

            console.log('\n ', error?.response?.data.errors);

            return ({
                success: false,
                message: error?.response?.data.errors.map((e, i) => <Alert severity="error" key={i}>attr: {e.attr} detail: {e.detail} code: {e.code} </Alert>)
            })
        }
    }
}