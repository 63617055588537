import {getMinMaxDates} from "./getMinMaxDates";

export const validateWorkDatesAndNames = (qualityDocs, estimateElements, justNumeric = false) => {
    const rootDates = []
    qualityDocs.forEach(doc => {
        doc.guid_dates.forEach(date => rootDates.push(date));
    });

    const workDatesSet = new Set();
    const works = [];
    const workNamesSet = new Set();
    estimateElements.estimate.forEach(est => {
        est.work_log.forEach(log => {
            if (rootDates.includes(log.id)) {
                workDatesSet.add(log.date);
                workNamesSet.add(est.name_works);
                works.push(est);
            }
        })
    })

    const workDatesArray = Array.from(workDatesSet);
    const workNamesArray = Array.from(workNamesSet);
    const updatedWorks = works.slice(0, 4)

    return {
        works: updatedWorks,
        workNames: workNamesArray,
        workDates: getMinMaxDates(workDatesArray, justNumeric)
    }
}