import React, {useContext, useEffect, useState} from "react";
import UserSection from "../../UI/Sections/UserSection";
import UserContext from "../../../store/user/user-context";
import {useNavigate} from "react-router-dom";
import EvidenceBase from "./EvidenceBase";
import {getOrders} from "../../UI/GetInfo/getOrders";
import Warnings from "../../UI/Sections/Warnings";

export default function QualityControlWorks() {

    const navigate = useNavigate();
    if (window.localStorage.getItem('role') !== '0') navigate('/work/');

    const userSettings = useContext(UserContext);
    const company_id = +window.localStorage.getItem('company');
    const project_id = +window.localStorage.getItem('project');

    const [answers, setAnswers] = useState(null); // setAnswers
    const [error, setError] = useState(null);
    const [orders, setOrders] = useState(null);

    useEffect(() => {
        if (!userSettings || !company_id || !project_id) return;

        const getInfo = async () => {
            const gotOrders = await getOrders(userSettings, company_id, project_id);
            if (gotOrders.answer) setAnswers(<Warnings>{gotOrders.answer}</Warnings>);
            const orders = gotOrders?.filter(order => order.history.length > 1)
            console.log(orders)

            return orders;
        }

        getInfo()
            .then(setOrders)
            .catch(setError)
    }, [userSettings, company_id, project_id]);
    // if (orders) console.log('\n QualityControlWorks orders', orders);

    return (
        <UserSection>
            <h1>Контроль качества работ</h1>
            <div className='w-fit'>
                {answers && answers}
            </div>
            <EvidenceBase
                orders={orders}
                userSettings={userSettings}
            />
        </UserSection>
    );
}