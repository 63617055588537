import {getDocumentType} from "./getDocumentType";

export const getDocumentNameNumberDate = (docs, type, withName = true) => {
    const document = getDocumentType(docs, type);

    if (!document) {
        return "документ отсутствует";
    }

    const name = document.organization_issued_document;
    const number = document.number;
    const date = document.date;

    if (withName) return name + ", " + number + ", " + date;

    return number + ", " + date;
}