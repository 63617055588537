import axios from "axios";

export const getTariffsRequests = async (userSettings) => {
    try {
        const tariffsRequests = await axios.get(
            `${userSettings.api}/tariffs/requests/`,
            userSettings.headers.get
        );
        // console.log(tariffsRequests.data)

        return tariffsRequests.data
    } catch (error) {
        console.error(`Ошибка /api/v1/tariffs/requests/: `, error);
    }
}

export const getTariffsRequestsById = async (userSettings, request_id) => {
    try {
        const tariffsRequestsById = await axios.get(
            `${userSettings.api}/tariffs/requests/${request_id}`,
            userSettings.headers.get
        );
        console.log(tariffsRequestsById.data)

        return tariffsRequestsById.data
    } catch (error) {
        console.error(`Ошибка /api/v1/tariffs/requests/request_id: `, error);
    }
}

export const getTariffsRequestsByIdAccept = async (userSettings, request_id) => {
    const tariffsRequestsByIdAccept = await axios.get(
        `${userSettings.api}/tariffs/requests/${request_id}/accept`,
        userSettings.headers.get
    );
    console.log(tariffsRequestsByIdAccept.data)

    return tariffsRequestsByIdAccept.data
}

export const getTariffsRequestsByIdDecline = async (userSettings, request_id) => {
    const tariffsRequestsByIdDecline = await axios.get(
        `${userSettings.api}/tariffs/requests/${request_id}/decline`,
        userSettings.headers.get
    );
    console.log(tariffsRequestsByIdDecline.data)

    return tariffsRequestsByIdDecline.data
}