import React, {useEffect, useState} from "react";
import tables from "../../UI/Tables/table.module.css";

function TheadSort (props) {

    const [selectedTag, setSelectedTag] = useState({});

    const selectTagHandler = (tag,direction) => {

        setSelectedTag({
            tag: tag,
            direction: direction,
        });
    };
    const sortByParameters = (array, tag, direction) => {
        array.sort((a, b) => {
            if (typeof a[tag] === 'string' && typeof b[tag] === 'string') {
                return direction ? a[tag].localeCompare(b[tag]) : b[tag].localeCompare(a[tag]);
            } else {
                return direction ? a[tag] - b[tag] : b[tag] - a[tag];
            }
        });

        return array;
    };
    const arraysAreEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) {
            return false;
        }

        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }

        return true;
    };

    // console.log(`\n `, props.array);

    useEffect(() => {
        if (Object.keys(selectedTag).length > 0) {
            const updateArray = sortByParameters([...props.array], selectedTag.tag, selectedTag.direction);

            // Проверка, чтобы избежать бесконечного цикла
            if (!arraysAreEqual(props.array, updateArray)) {
                props.setArray([...updateArray]);
            }
        }
    }, [selectedTag, props]);

    // console.log(`\n props.names`, props.names);

    return (
        <thead className={tables.thead}>
        <tr>
            {props.names.map((n, index) => {

                return (<th
                    key={n.tag ? n.tag : index}
                    onClick={() => selectTagHandler(n.tag, !selectedTag.direction)}
                    className={`cursor-pointer`}
                >
                    {n.name}
                    {(selectedTag.tag && selectedTag.tag === n.tag) && <span className={`text-xs my-auto ml-1`}>{
                        selectedTag.direction ? '▲' : '▼'
                    }</span>}
                </th>);
            })}
        </tr>
        </thead>
    );
}

export default TheadSort;