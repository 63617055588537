import React, {useContext, useEffect, useState} from "react";
import UserSection from "../../UI/Sections/UserSection";
import UserContext from "../../../store/user/user-context";
import {ErrorOccurred} from "../../UI/Sections/ErrorOccurred";
import axios from "axios";
import {Price} from "../../UI/Convertors/Price";
import Warnings from "../../UI/Sections/Warnings";
import inputStyles from "../../UI/Inputs/inputs.module.css";
import Btn from "../../UI/Buttons/Btn";
import tables from "../../UI/Tables/table.module.css";
import TheadSort from "../../UI/Tables/TheadSort";
import {Link} from "react-router-dom";
import styles from "../dash.module.css";
import Popup from "../../UI/Popup/Popup";
import {Alert, Box, Button, ButtonGroup} from "@mui/material";

export default function SubmitWorkPayment() {


    const userSettings = useContext(UserContext);
    const subcompany_id = +window.localStorage.getItem('company');
    const tender_id = +window.localStorage.getItem('tender');

    const [answers, setAnswers] = useState(null);
    const [popupShow, setPopupShow] = useState(false);
    const [tender, setTender] = useState(null);
    const [unapprovedWorkLogData, setUnapprovedWorkLogData] = useState(null);
    const [uploadedDocument, setUploadedDocument] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [qualityDocuments, setQualityDocuments] = useState(null);

    const company_id = tender?.tender.project.company.id;
    const project_id = tender?.tender.project.id;
    const headerNames = [
        {
            "name": "№",
            "tag": "number",
        },
        {
            "name": "Наименование работ",
            "tag": "name_works",
        },
        {
            "name": "Объем выполненных работ",
            "tag": "valuesWorks",
        },
        {
            "name": "Ед. изм.",
            "tag": "units",
        },
        {
            "name": "Стоимость выполненных работ",
            "tag": "costWorks",
        },
        {
            "name": "Загруженные документы",
            "actions": "agreement",
        }
    ];
    const unapprovedWorkLogPeriod = unapprovedWorkLogData && unapprovedWorkLogData.flatMap(w => w.work_log);
    const periods = unapprovedWorkLogPeriod && [...new Set(unapprovedWorkLogPeriod.map(w => new Date(w.date).toLocaleDateString()))].sort();
    const costWorks = unapprovedWorkLogData && Math.round(unapprovedWorkLogData.map(w => w.costWorks).reduce((acc, curr) => acc + curr, 0) * 100) / 100;

    const getUserDocumentHandler = (e) => {
        console.log(`\n uploadDocumentHandler step 1`, e.target.files[0]);
        setUploadedDocument(e.target.files[0]);
    }
    const addRow = (id) => {

        const updateSelectedRows = [...selectedRows, id];
        // console.log(`\n updateSelectedRows`, updateSelectedRows);
        setSelectedRows(updateSelectedRows);
    }

    const removeRow = (id) => {

        const updateSelectedRows = [...selectedRows].filter(row => row !== id);
        // console.log(`\n updateSelectedRows`, updateSelectedRows);
        setSelectedRows(updateSelectedRows);
    }

    const uploadDocumentsHandler = async () => {
        try {
            const formData = new FormData();
            const guid_dates = selectedRows.map((element_id) => unapprovedWorkLogData.find(w => w.id === element_id)?.not_approved_work_log.map(l => l.id)).flat();

            formData.append('document', uploadedDocument);
            for (let i = 0; i < guid_dates.length; i++) {
                formData.append('guid_dates', (guid_dates[i]));
            }
            console.log(formData)

            const response = await axios.post(
                `${userSettings.api}/work_payment/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/upload-docs`,
                formData,
                userSettings.headers.postForm
            );

            console.log(response);

            window.location.reload();
        } catch (error) {

            const responseError = ErrorOccurred(
                error.response,
                "Ошибка загрузки документов о качестве",
                "uploadDocsHandler"
            );
            setAnswers(responseError.jsx);
        }
    }

    const deleteCancelledDocument = (workLog) => {
        const anyGUIDWorkLogFromDoc = workLog?.not_approved_work_log[0]?.id;
        console.log(anyGUIDWorkLogFromDoc)
        for (let i = 0; i < qualityDocuments.length; i++) {
            if (qualityDocuments[i].guid_dates.includes(anyGUIDWorkLogFromDoc)) {
                const quality_document_delete_id = qualityDocuments[i].id;
                console.log(quality_document_delete_id);

                axios.delete(
                    `${userSettings.api}/work_payment/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/quality_documents/${quality_document_delete_id}/delete-doc`,
                    userSettings.headers.get
                ).then(res => window.location.reload());

                // const updatedWorkLogData = unapprovedWorkLogData.filter(w => !qualityDocuments[i].guid_dates.includes(w.not_approved_work_log[0].id));
                // setUnapprovedWorkLogData(updatedWorkLogData);

            }
        }
    }

    const updateDocsBySubcontractorHandler = (workLog) => {

        const updateDocsBySubcontractor = async (data) => {
            try {
                setPopupShow(null);

                console.log(workLog)
                const doc = getDocumentForGuidArray([workLog.work_log[0].id])
                console.log(doc)
                const comment_id = doc.manager_comments?.find(com => workLog.id === com.id).id
                console.log(comment_id)
                const quality_document_id = doc.id;

                const body = {}

                body.adjustment_work_log = doc.adjustment_work_log ? [...doc.adjustment_work_log, {
                    id: comment_id,
                    value: data.value
                }] : [{id: comment_id, value: data.value}]
                console.log(body)

                const changeVolume = await axios.patch(
                    `${userSettings.api}/work_payment/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/quality_documents/${quality_document_id}/update-docs-by-subcontractor`,
                    JSON.stringify(body),
                    {headers: userSettings.headers.post}
                )
                console.log(changeVolume)

                window.location.reload()
            } catch
                (error) {

                const responseError = ErrorOccurred(
                    error.response,
                    "Ошибка изменения документа о качестве",
                    "updateDocsBySubcontractor"
                );
                setAnswers(responseError.jsx);
            }
        }

        setPopupShow(<Popup
            title={`Изменить объем`}
            form={{
                fields: [{
                    type: "number",
                    name: "value",
                    label: "Укажите объем",
                    step: 0.01,
                }],
                submit: "Записать изменения"
            }}
            onSubmit={updateDocsBySubcontractor}
            onCloseModal={() => setPopupShow(false)}
        />);
    }

    useEffect(() => {

        const getTender = async () => {

            try {
                const wonTenderLots = await axios.get(
                    `${userSettings.api}/subcompanies/${subcompany_id}/won-tender-lots`,
                    userSettings.headers.get
                );
                // console.log(`\n wonTenderLots`, wonTenderLots.data);

                const updateCurrentTender = wonTenderLots.data.find(contract => contract.tender.id === tender_id);
                // console.log(`\n updateCurrentTender`, updateCurrentTender,tender_id);
                setTender(updateCurrentTender);
            } catch (error) {

                const responseError = ErrorOccurred(
                    error.response,
                    "Ошибка получения выигранных тендеров",
                    "getTender"
                );
                setAnswers(responseError.jsx);
            }
        }

        if (subcompany_id) getTender();
    }, [userSettings, subcompany_id, tender_id]);
    useEffect(() => {

        const getUnapprovedWorkLog = async () => {

            try {

                const getUpdateUnapprovedWorkLog = await axios.get(
                    `${userSettings.api}/work_payment/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/unapproved-work-log`,
                    userSettings.headers.get
                );

                const getQualityDocs = await axios.get(
                    `${userSettings.api}/work_payment/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/quality-docs`,
                    userSettings.headers.get
                );
                console.log(`\n getQualityDocs`, getQualityDocs.data);
                setQualityDocuments(getQualityDocs.data);
                // setQualityDocs(getQualityDocs.data);
                // console.log(`\n getUpdateUnapprovedWorkLog`, getUpdateUnapprovedWorkLog.data);
                const updateUnapprovedWorkLog = getUpdateUnapprovedWorkLog.data.map(w => {

                    const cost = w.unit_cost_materials + w.unit_cost_smr;
                    const valuesWorks = w.not_approved_work_log.map(l => +l.value).reduce((acc, curr) => acc + curr, 0);
                    const costWorks = Math.round(cost * valuesWorks * 100) / 100;
                    const documents = getQualityDocs.data.filter(d => d.work_journal_element_id === w.id);

                    // console.log(
                    //     `\n w`, w,
                    //     `\n valuesWorks`, valuesWorks,
                    //     `\n cost`, cost,
                    //     `\n costWorks`, costWorks,
                    //     `\n documents`, documents,
                    // );

                    return ({
                        ...w,
                        valuesWorks,
                        costWorks,
                        documents: documents.length > 0 ? documents : null,
                    });
                });
                // console.log(`\n updateUnapprovedWorkLog`, updateUnapprovedWorkLog);
                setUnapprovedWorkLogData(updateUnapprovedWorkLog.filter(object => object.not_approved_work_log.length > 0));
            } catch (error) {

                const responseError = ErrorOccurred(
                    error.response,
                    "Ошибка получения не согласованных работ",
                    "getUnapprovedWorkLog"
                );
                setAnswers(responseError.jsx);
            }
        }
        if (company_id && project_id && subcompany_id && tender_id) getUnapprovedWorkLog()
    }, [company_id, project_id, subcompany_id, tender_id, userSettings]);

// if (unapprovedWorkLogData) console.log(`\n unapprovedWorkLogData`, unapprovedWorkLogData);
// if (uploadedDocument) console.log(`\n uploadedDocument`, uploadedDocument);
// if (selectedRows) console.log(`\n selectedRows`, selectedRows);
// if (qualityDocs) console.log(`\n qualityDocs`, qualityDocs);
    const validatePrintValue = (workLogsObject, tag) => {
        const totalVolume = workLogsObject.not_approved_work_log.reduce((acc, cur) => acc + (+cur.value), 0);
        if (tag === "valuesWorks") {
            return totalVolume;
        } else if (tag === 'costWorks') {
            return Price(totalVolume * (workLogsObject.unit_cost_materials + workLogsObject.unit_cost_smr));
        } else return workLogsObject[tag];
    }

// console.log("qualityDocuments: ", qualityDocuments)

    const getDocumentForGuidArray = (guidArr) => {
        for (let i = 0; i < qualityDocuments.length; i++) {
            // console.log(qualityDocuments[i])
            const isInQualityDocument = guidArr.every(guid => qualityDocuments[i].guid_dates.includes(guid));
            if (isInQualityDocument) return qualityDocuments[i]
        }
    }

    const collectAllUnapprovedGuidArray = (workLogsObject) => {
        return workLogsObject.not_approved_work_log.map(log => log.id);
    }
    console.log(unapprovedWorkLogData)
    return (
        <UserSection>
            {popupShow && popupShow}
            <h1>Подать работы на оплату</h1>
            {answers && answers}
            {periods && <div>Период выполненных работ: с {periods[0]} по {periods[(periods.length - 1)]}</div>}
            {costWorks && <div>На сумму {Price(costWorks)} р.</div>}
            {!uploadedDocument ?
                <React.Fragment>
                    <h2 className={`my-2`}>Загрузить документы о качестве выполненных работ</h2>
                    <Warnings info>При загрузке zip принимаются только файлы, подписанные ЭЦП с вложенным в архив файл
                        *.sig!</Warnings>
                    <input
                        type={`file`}
                        name={`document`}
                        className={`${inputStyles.file} w-max`}
                        onChange={getUserDocumentHandler}
                    />
                </React.Fragment> :
                <React.Fragment>
                    <div>Документ: {uploadedDocument.name}</div>
                    <div className={`flex flex-wrap gap-2`}>
                        <Btn method={`back`} color={`button`} onClick={() => {
                            setUploadedDocument(null)
                        }}>Назад</Btn>
                        {selectedRows.length > 0 ?
                            <Btn method={`save`} color={`button`} onClick={uploadDocumentsHandler}>Записать</Btn> :
                            <Warnings info max>Выберите работы</Warnings>
                        }
                    </div>
                </React.Fragment>
            }
            {unapprovedWorkLogData && <div className={`${tables.container} grow mt-2`}>
                <table className={tables.table}>
                    <TheadSort
                        names={headerNames}
                        array={unapprovedWorkLogData}
                        setArray={setUnapprovedWorkLogData}
                    />
                    <tbody>
                    {unapprovedWorkLogData.map(workLogsObject => {
                        // console.log(workLogsObject);

                        const currentDocument = getDocumentForGuidArray(collectAllUnapprovedGuidArray(workLogsObject));
                        // console.log(currentDocument);
                        const isDocumentApproved = currentDocument?.is_build_control_approved && currentDocument.is_manager_approved;
                        // console.log(collectAllUnapprovedGuidArray(workLogsObject))
                        return (
                            <tr key={workLogsObject.id}>
                                {headerNames.map((n, index) => {
                                    const editedValue = workLogsObject?.work_log?.find(log => log.is_adjustment)?.value
                                    const printValue = validatePrintValue(workLogsObject, n.tag);
                                    const commentSectionManager = [];

                                    if (n.actions === 'agreement') {

                                        workLogsObject.work_log.forEach(l => {
                                            if (l.is_checked_by_section_manager) {
                                                commentSectionManager.push(l.section_manager_comment);
                                            }
                                        });

                                        // console.log(`\n commentSectionManager`, commentSectionManager);

                                        // console.log(`\n `, workLogsObject.documents);
                                        // console.log(`\n editedValue`, editedValue);
                                    }
                                    // console.log("currentDocument: ", currentDocument);
                                    // console.log(currentDocument.manager_comments, workLogsObject.id)
                                    const currentComm = currentDocument?.manager_comments?.find(com => com.id === workLogsObject.id)
                                    // console.log(currentComm)
                                    return (
                                        n.actions === 'agreement' ?
                                            <td key={index}>
                                                {currentDocument ?
                                                    <Box>
                                                        {currentDocument.manager_comments?.length > 0 &&
                                                            currentComm && !currentDocument.is_manager_approved &&
                                                            <div>
                                                                <div className={styles.rowName}>
                                                                    Причина отклонения начальником участка:
                                                                </div>
                                                                <Alert
                                                                    severity="info">{[...new Set(currentComm.comment)]}</Alert>

                                                                <div>Объем изменен на {editedValue}</div>
                                                                <Btn
                                                                    method={`edit`}
                                                                    color={`button`}
                                                                    center
                                                                    onClick={() => updateDocsBySubcontractorHandler(workLogsObject)}
                                                                >Редактировать объем</Btn>
                                                            </div>}

                                                        <Alert
                                                            severity={isDocumentApproved ? "success" : "error"}
                                                        >
                                                            {isDocumentApproved ?
                                                                "Документ согласован" :
                                                                currentDocument.build_control_comments ?
                                                                    <React.Fragment>
                                                                        <div className={styles.rowName}>
                                                                            Причина отклонения стройконтролем:
                                                                        </div>
                                                                        <Alert severity="info">
                                                                            {currentDocument.build_control_comments}
                                                                        </Alert>
                                                                        <Button
                                                                            variant="contained"
                                                                            color="secondary"
                                                                            onClick={() => deleteCancelledDocument(workLogsObject)}
                                                                        >
                                                                            Удалить отклонённый документ
                                                                        </Button>
                                                                    </React.Fragment> :
                                                                    <div className={styles.rowName}>
                                                                        Документ на согласовании
                                                                    </div>
                                                            }
                                                            <ButtonGroup>
                                                                <Button
                                                                >
                                                                    <Link
                                                                        to={`${userSettings.fileServer}${currentDocument.document}`}
                                                                        target={`_blank`}>Документ {currentDocument.id}</Link>
                                                                </Button>

                                                                <Button
                                                                    onClick={() => deleteCancelledDocument(workLogsObject)}
                                                                >
                                                                    Удалить документ
                                                                </Button>
                                                            </ButtonGroup>
                                                        </Alert>

                                                    </Box> :
                                                    uploadedDocument ?
                                                        selectedRows.includes(workLogsObject.id) ?
                                                            <Btn method={`remove`} center
                                                                 onClick={() => removeRow(workLogsObject.id)}>Отменить</Btn> :
                                                            <Btn method={`add`} color={`button`} center
                                                                 onClick={() => addRow(workLogsObject.id)}>Выбрать</Btn> :
                                                        // {collectAllUnapprovedGuidArray(workLogsObject).join("  |||  ")}
                                                        <Alert severity="warning">Загрузить документ</Alert>
                                                }
                                            </td>
                                            :
                                            <td className={n.tag === 'name_works' ? '' : 'text-center'}
                                                key={index}>{printValue}</td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>}
        </UserSection>
    );
}