import {useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import UserSection from "../../UI/Sections/UserSection";
import UserContext from "../../../store/user/user-context";
import {getFinancialPlan, putFinancialPlan, saveFinancialPlan} from "../../SendRequests/FinancialPlanData";
import UploadFinancialPlan from "./UploadFinancialPlan";
import ViewFinancialPlan from "./ViewFinancialPlan";
import {Box, Button} from "@mui/material";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import RestorePageOutlinedIcon from '@mui/icons-material/RestorePageOutlined';
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

const FinancialSchedule = () => {

    const navigate = useNavigate();
    if (window.localStorage.getItem('role') !== '2') navigate('/work/');
    const userSettings = useContext(UserContext);
    const company_id = +window.localStorage.getItem('company');
    const project_id = +window.localStorage.getItem('project');
    const [answers, setAnswers] = useState(null);

    const [financialPlan, setFinancialPlan] = useState(null);
    const [uploadedFinancialPlan, setUploadedFinancialPlan] = useState(null);
    const [isUpdate, setIsUpdate] = useState(false); //setIsUpdate

    // console.log('\n financialPlan', financialPlan);
    // console.log('\n uploadedFinancialPlan', uploadedFinancialPlan);

    useEffect(() => {

        const getData = async () => {

            try {

                const updateFinancialPlan = await getFinancialPlan(userSettings, company_id, project_id);

                // console.log('\n updateFinancialPlan', updateFinancialPlan);

                setFinancialPlan(updateFinancialPlan);
            }
            catch (error) {
                console.error('getData', error);
            }
        }

        if (userSettings && company_id && project_id) getData();
    }, [userSettings, company_id, project_id]);

    const uploadFinancialPlanHandler = (financialPlan) => {

        // console.log('\n uploadFinancialPlan', financialPlan);
        setUploadedFinancialPlan(financialPlan);
    }
    const saveFinancialPlanHandler = async () => {

        const savePlan = await saveFinancialPlan(userSettings, company_id, project_id, uploadedFinancialPlan);
        // console.log('\n savePlan', savePlan);

        if (!savePlan.success) setAnswers(savePlan.message);
        else window.location.reload();
    }
    const setUpdateFinancialPlanHandler = () => {

        setIsUpdate(financialPlan);
        setFinancialPlan("not_found");
    }
    const backUpdateFinancialPlanHandler = () => {

        setIsUpdate(false);
        setUploadedFinancialPlan(false);
        setFinancialPlan(isUpdate);
    }
    const updateFinancialPlanHandler = async () => {

        const savePlan = await putFinancialPlan(userSettings, company_id, project_id, uploadedFinancialPlan);
        // console.log('\n savePlan', savePlan);

        if (!savePlan.success) setAnswers(savePlan.message);
        else window.location.reload();
    }

    // if (userSettings) console.log('\n userSettings', userSettings);
    // if (company_id) console.log('\n company_id', company_id);
    // if (project_id) console.log('\n project_id', project_id);
    // if (financialPlan && userSettings && company_id && project_id) console.log('\n financialPlan', financialPlan);
    // console.log('\n uploadedFinancialPlan', uploadedFinancialPlan);

    const viewData = (financialPlan && financialPlan !== "not_found") ?
        Object.values(financialPlan) :
        uploadedFinancialPlan && Object.values(uploadedFinancialPlan)
    ;
    // if (viewData?.length > 0) console.log('\n viewData', viewData);

    return ( 
        <UserSection>
            <h1>Финансовый план</h1>
            {answers}
            {(financialPlan === "not_found" && uploadedFinancialPlan) ?
                <Box className={`flex flex-wrap gap-2`}>
                    <Button
                        className={`w-max`}
                        startIcon={<SaveOutlinedIcon />}
                        variant={`contained`}
                        onClick={isUpdate ? updateFinancialPlanHandler : saveFinancialPlanHandler}
                    >
                        Записать финансовый план
                    </Button>
                    {isUpdate && <Button
                        variant="contained"
                        startIcon={<ArrowBackRoundedIcon />}
                        onClick={backUpdateFinancialPlanHandler}
                    >Назад</Button>}
                </Box> :
                financialPlan !== "not_found" && <Button
                    className={`w-max`}
                    startIcon={<RestorePageOutlinedIcon />}
                    variant={`contained`}
                    onClick={setUpdateFinancialPlanHandler}
                >
                    Обновить финансовый план
                </Button>
            }
            {(financialPlan === "not_found" && !uploadedFinancialPlan) &&
                <UploadFinancialPlan
                    uploadFinancialPlanHandler={uploadFinancialPlanHandler}
                    isUpdate={isUpdate}
                    backUpdateFinancialPlanHandler={backUpdateFinancialPlanHandler}
                />
            }
            {viewData?.length > 0 &&
                <ViewFinancialPlan
                   data={viewData}
                />
            }
        </UserSection>
    );
}

export default FinancialSchedule;