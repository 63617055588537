import * as React from 'react';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function CustomizedTables({lots, previousAdded}) {
    return (
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 300}} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Дополнительные роли</StyledTableCell>
                        <StyledTableCell align="center">Количество до</StyledTableCell>
                        <StyledTableCell align="center">Количество после</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {lots.map((lot) => (
                        <StyledTableRow key={lot.key}>
                            <StyledTableCell component="th" scope="row">
                                {lot.name}
                            </StyledTableCell>
                            <StyledTableCell align="center">{previousAdded[lot.key].add}</StyledTableCell>
                            <StyledTableCell align="center">{lot.value}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}