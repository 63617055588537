import React, {useContext, useEffect, useState} from 'react';
import styles from "../dash.module.css";
import UserSection from "../../UI/Sections/UserSection";
import {Link, useNavigate} from "react-router-dom";
import UserContext from "../../../store/user/user-context";
import axios from "axios";
import Warnings from "../../UI/Sections/Warnings";
import Btn from "../../UI/Buttons/Btn";
import tables from "../../UI/Tables/table.module.css";
import TheadSort from "../../UI/Tables/TheadSort";
import inputs from "../../UI/Inputs/inputs.module.css";
import {Price} from "../../UI/Convertors/Price";
import {getTariffsCompanies} from "../../SendRequests/TariffsData";
import {postCompaniesTariffByIdCreateRequest} from "../../UI/GetInfo/getTariffsChanges";
import {Alert, Box, Button, ButtonGroup} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

const Tenders = () => {

    const navigate = useNavigate();
    if (window.localStorage.getItem('role') !== '2') navigate('/work/');

    const userSettings = useContext(UserContext);
    const company_id = +window.localStorage.getItem('company');
    const project_id = +window.localStorage.getItem('project');
    const company = userSettings.companies.filter(c => c.id === company_id)[0];

    const [answers, setAnswers] = useState(null);
    const [tenders, setTenders] = useState(null);
    const [criteria, setCriteria] = useState(null);
    const [criteriaIsShow, setCriteriaIsShow] = useState(null);
    const [criteriaIsEdit, setCriteriaIsEdit] = useState(null);

    const [isNotEnoughLots, setIsNotEnoughLots] = useState(false);
    console.log(criteria)

    const criteriaAllWeight = criteria && criteria?.map(c => c.weight).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const headerNames = [
        {
            name: "Критерий выбора",
            tag: "name",
        },
        {
            name: `Вес критерия ${criteriaAllWeight && criteriaAllWeight}`,
            tag: "weight",
        },
        {
            name: "Значение критерия",
            tag: "value",
        },
        {
            name: "Редактировать",
            tag: "edit",
        },
    ]

    const postCriteriaHandler = async (listСriteria) => {

        try {
            if (listСriteria.length > 0) {

                const updatedCriteria = await Promise.all(listСriteria.map(async (c) => {

                    const answer = await axios.post(
                        `${userSettings.api}/tenders/companies/${company_id}/projects/${project_id}/tender-criteria/`,
                        {
                            "name": c.name,
                            "weight": c.weight,
                            "value": c.value
                        },
                        {headers: userSettings.headers.post}
                    );
                    return answer.data;
                }));
                console.log(updatedCriteria)
                setCriteria([...criteria, ...updatedCriteria]);
            }
        } catch (error) {
            setAnswers(<Warnings>
                Ошибка создания критериев - {error.response.data.errors ?
                error.response.data.errors.map((e, id) => <p key={id}>{e.detail}</p>)
                :
                error.response.status
            }
            </Warnings>)
            console.log(`\n postCriteriaHandler error`, error.response);
        }
    }
    const putCriteriaHandler = async (listСriteria) => {

        try {
            if (listСriteria.length > 0) {
                const updatedCriteria = await Promise.all(listСriteria.map(async (c) => {
                    const criteria_id = c.id;
                    const answer = await axios.put(
                        `${userSettings.api}/tenders/companies/${company_id}/projects/${project_id}/tender-criteria/${criteria_id}/`,
                        {
                            "name": c.name,
                            "weight": c.weight,
                            "value": c.value
                        },
                        {headers: userSettings.headers.post}
                    );
                    return answer.data;
                }));

                const updatedCriteriaIds = updatedCriteria.map((c) => c.id);
                const updatedState = criteria.map((c) => {
                    if (updatedCriteriaIds.includes(c.id)) {
                        const updatedCriterion = updatedCriteria.find((uc) => uc.id === c.id);
                        return updatedCriterion;
                    } else {
                        return c;
                    }
                });

                // console.log(`\n updatedState`, updatedState);
                setCriteria(updatedState);
                setCriteriaIsEdit(null);

                setAnswers(<Warnings correct>Критерии успешно обновлены</Warnings>)
            }
        } catch (error) {
            setAnswers(<Warnings>
                Ошибка создания критериев - {error.response.data.errors ?
                error.response.data.errors.map((e, id) => <p key={id}>{e.detail}</p>)
                :
                error.response.status
            }
            </Warnings>)
            console.log(`\n postCriteriaHandler error`, error.response);
        }
    }
    const templateCriteriaHandler = () => {

        // console.log(`\n templateCriteriaHandler`, criteria);

        // const criteriaTemplate = [
        //     {
        //         "name": "Общая стоимость работ",
        //         "weight": 60,
        //         "value": 1,
        //     },
        //     {
        //         "name": "Срок выполнения работ в месяцах",
        //         "weight": 19,
        //         "value": 1,
        //     },
        //     {
        //         "name": "Аванс, %",
        //         "weight": 5,
        //         "value": 1,
        //     },
        //     {
        //         "name": "Техническое оснащение, ед. техники на балансе",
        //         "weight": 3,
        //         "value": 2,
        //     },
        //     {
        //         "name": "Штат, кол чел",
        //         "weight": 5,
        //         "value": 2,
        //     },
        //     {
        //         "name": "Опыт, лет",
        //         "weight": 5,
        //         "value": 2,
        //     },
        //     {
        //         "name": "Мобилизация, кол дн",
        //         "weight": 3,
        //         "value": 1,
        //     }
        // ];

        const addCriteria = [];
        const putCriteria = [];

        // criteriaTemplate.forEach(t => {
        //
        //     const toPutCriteria = criteria.find(c => c.name === t.name);
        //
        //     if (toPutCriteria) {
        //         toPutCriteria.weight = t.weight;
        //         toPutCriteria.value = t.value;
        //         putCriteria.push(toPutCriteria);
        //     } else {
        //         addCriteria.push(t)
        //     }
        // })

        // console.log(`\n addCriteria`, addCriteria);
        if (addCriteria.length > 0) postCriteriaHandler(addCriteria);
        // console.log(`\n putCriteria`, putCriteria);
        if (putCriteria.length > 0) putCriteriaHandler(putCriteria);
    }
    const changeCriteriaHandler = (id, prop, value) => {

        // console.log(`\n changeCriteriaHandler`, id,prop,value);

        const updateCriteria = [...criteria].map(c => {

            if (c.id === id) c[prop] = value;

            return (c);
        });
        // console.log(`\n updateCriteria`, updateCriteria);
        setCriteria(updateCriteria);

        setCriteriaIsEdit(true);
    }
    const cloneCriteriaHandler = (id) => {

        const currentCriteria = criteria.find(c => c.id === id);
        // currentCriteria.weight = 0;
        console.log(`\n cloneCriteriaHandler`, id, currentCriteria);
        const criteriaToCopy = {...currentCriteria, weight: 0}
        console.log(criteriaToCopy)

        postCriteriaHandler([criteriaToCopy]);
    }
    const deleteCriteriaHandler = async (criteria_id) => {

        try {
            const response = await axios.delete(
                `${userSettings.api}/tenders/companies/${company_id}/projects/${project_id}/tender-criteria/${criteria_id}/`,
                {headers: userSettings.headers.postX}
            );
            console.log(`\n `, response);

            setCriteria(prev => prev.filter(c => c.id !== criteria_id))
        } catch (error) {
            setAnswers(<Warnings>
                Ошибка удаления критерия - {error.response.data.errors ?
                error.response.data.errors.map((e, id) => <p key={id}>{e.detail}</p>)
                :
                error.response.status
            }
            </Warnings>)
            console.log(`\n deleteCriteriaHandler error`, error.response);
        }
    }

    const publicTenderHandler = async (tender_id, state) => {
        // console.log(`\n `, tender_id, state);

        try {
            const formData = new FormData();
            formData.append('is_public', state);

            const response = await axios.post(
                `${userSettings.api}/tenders/companies/${company_id}/project/${project_id}/tenders/${tender_id}/change-publishing`,
                formData,
                {headers: {...userSettings.headers.post, 'Content-Type': 'multipart/form-data'}}
            );

            const updateTenders = [...tenders].map(tender => {

                if (tender.id === response.data.id) tender.is_public = response.data.is_public;

                return tender;
            });

            setTenders(updateTenders);

        } catch (error) {
            console.error('Error', error.response.data);
            // Обработка ошибки
        }
    }

    const handleNotEnoughTenders = async () => {
        // try {
        //     const getTenderLimit = await axios.get(
        //         `${userSettings.api}/tenders/companies/${company_id}/project/${project_id}/check-tender-limits`,
        //         userSettings.headers.get
        //     );
        //     const canAdd = getTenderLimit.data.is_can;
        //     canAdd ? setIsNotEnoughLots(false) : setIsNotEnoughLots(true);
        // } catch (error) {
        //     console.log(`\n check-tender-limits error`, error.response);
        // }

        navigate("/work/coordinator/tender-add")
    };

    const handleAddLotToTariff = async () => {
        try {
            const tariff = await getTariffsCompanies([company_id], userSettings);
            const totalAdded = tariff[0].tariff;
            console.log("текущее кол-во контрактов: ", totalAdded.contract.add);

            const body = {
                "add_investor": totalAdded.investor.add,
                "add_administrator": totalAdded.administrator.add,
                "add_coordinator": totalAdded.coordinator.add,
                "add_build_control": totalAdded.build_control.add,
                "add_manager": totalAdded.manager.add,
                "add_gip": totalAdded.gip.add,
                "add_contract": totalAdded.contract.add + 1
            }

            const postResponse = await postCompaniesTariffByIdCreateRequest(userSettings, company_id, body);
            console.log(postResponse);
        } catch (error) {
            console.log(error?.response?.status);
        }
    };


    // получить тендеры
    useEffect(() => {

        const getData = async () => {

            try {
                const getTenders = await axios.get(
                    `${userSettings.api}/tenders/companies/${company_id}/`,
                    userSettings.headers.get
                );
                const currentProjectTenders = getTenders.data.sort((a, b) => a.id - b.id).filter(t => t.project.id === project_id);
                // console.log(`\n currentProjectTenders`, currentProjectTenders);
                if (currentProjectTenders.length > 0) setTenders(currentProjectTenders);

                const getCriteria = await axios.get(
                    `${userSettings.api}/tenders/companies/${company_id}/projects/${project_id}/tender-criteria/`,
                    userSettings.headers.get
                );
                // console.log(`\n getCriteria`, getCriteria.data);
                setCriteria(getCriteria.data.sort((a, b) => a.id - b.id));

                setAnswers(null);
            } catch (error) {
                setAnswers(<Warnings>
                    Ошибка получения информации - {error.response.data.errors ?
                    error.response.data.errors.map((e, id) => <p key={id}>{e.detail}</p>)
                    :
                    error.response.status
                }
                </Warnings>)
                console.log(`\n getData error`, error.response);
            }
        }

        getData();
    }, [company_id, project_id, userSettings]);

    // if (criteria) console.log(`\n criteria`, criteria);
    if (tenders) console.log(`\n tenders`, tenders);

    return (
        <UserSection>
            <h1>Тендеры</h1>
            {answers && answers}
            {company && <div className={`p-2`}>
                <div className={`text-xl`}>Информация о компании {company.name}</div>
                <div className={styles.list}>
                    {company.inn && <div className={styles.items}>ИНН {company.inn}</div>}
                    {company.kpp && <div className={styles.items}>КПП {company.kpp}</div>}
                </div>
            </div>}
            <Btn
                method={!criteriaIsShow ? 'view' : 'back'}
                color={`button`}
                onClick={() => setCriteriaIsShow(!criteriaIsShow)}
            >
                {!criteriaIsShow ? "Критерии выбора подрядчика" : "Назад"}
            </Btn>
            {criteriaIsShow && <React.Fragment>
                <div className={`my-2 flex`}>
                    {criteriaAllWeight === 100 ?
                        (criteriaIsEdit) &&
                        <Btn color={`button`} onClick={() => putCriteriaHandler(criteria)}>Сохранить</Btn>
                        :
                        <div className={`flex flex-col gap-1`}>
                            <Alert severity="warning">Сумма весов критериев должна быть 100</Alert>
                            {(criteria && criteria.length === 2) &&
                                <Btn color={`button`} onClick={templateCriteriaHandler}>Создать критерии по
                                    шаблону</Btn>}
                        </div>
                    }
                </div>
                {criteria &&
                    <Box className='px-4 h-fit'>
                        <div className={tables.container}>
                            <table className={tables.table}>
                                <TheadSort
                                    names={headerNames}
                                    array={criteria}
                                    setArray={setCriteria}
                                />
                                <tbody>
                                {criteria.map(c => {
                                    const exceptions = ["Общая стоимость работ", "Срок выполнения работ в месяцах"];

                                    return (<tr key={c.id}>
                                        {headerNames.map((name, index) => {

                                            const namePrint = !c.is_required ? <input
                                                type={`text`}
                                                defaultValue={c.name}
                                                className={`${inputs.input} w-full`}
                                                onChange={(e) => changeCriteriaHandler(c.id, 'name', e.target.value)}
                                            /> : c.name

                                            const weight = (
                                                <input
                                                    type={`number`}
                                                    className={`${criteriaAllWeight !== 100 ? `${inputs['invalid-input']} font-bold` : ''} ${inputs.input} w-20 text-center mx-auto block`}
                                                    defaultValue={c.weight}
                                                    onChange={(e) => changeCriteriaHandler(c.id, 'weight', +e.target.value)}
                                                />
                                            )
                                            const value = (<select
                                                className={`${inputs.input} w-20 text-center mx-auto block`}
                                                defaultValue={c.value === 1 ? '1' : '2'}
                                                onChange={(e) => changeCriteriaHandler(c.id, 'value', +e.target.value)}
                                            >
                                                <option value={'1'}>Мин</option>
                                                <option value={'2'}>Макс</option>
                                            </select>);
                                            const edit = !c.is_required && (
                                                <Box className='flex'>
                                                    <ButtonGroup>
                                                        <Button
                                                            onClick={() => cloneCriteriaHandler(c.id)}
                                                        >
                                                            Копировать
                                                        </Button>
                                                        <Button
                                                            color="error"
                                                            onClick={() => deleteCriteriaHandler(c.id)}
                                                        >
                                                            Удалить
                                                        </Button>
                                                    </ButtonGroup>
                                                </Box>
                                            );

                                            return (<td
                                                key={index}
                                                className={(name.tag !== "name") ? `text-center` : ""}
                                            >
                                                {(name.tag === "name") ?
                                                    namePrint :
                                                    (name.tag === "weight") ?
                                                        weight :
                                                        (name.tag === "value") ?
                                                            value :
                                                            (name.tag === "edit") ?
                                                                edit :
                                                                c[name.tag]}
                                            </td>);
                                        })}
                                    </tr>)
                                })}
                                </tbody>
                            </table>
                        </div>
                    </Box>
                }
            </React.Fragment>}

            <Btn onClick={handleNotEnoughTenders} method={`add`} color={`button`}>
                Добавить тендер
            </Btn>
            {isNotEnoughLots &&
                <Box className='mt-4'>
                    <Alert variant="outlined" severity="warning">Вы превысили допустимый лимит договоров с
                        подрядчиками. Пожалуйста, обратитесь к администратору для дальнейших действий..</Alert>
                    <Box className='mt-4'>
                        <Button
                            className={'mt-4'}
                            onClick={handleAddLotToTariff}
                            variant="contained"
                            endIcon={<SendIcon/>}
                        >
                            Создать запрос на получение дополнительного слота тендер
                        </Button>
                    </Box>
                </Box>
            }

            {tenders ?
                <div className={`flex flex-wrap gap-2`}>
                    {tenders.filter(tender => tender.project.id === project_id).map(tender => <div
                        className={`${styles.card} flex flex-col`} key={tender.id}>
                        <div className={styles.label}>{tender.name}</div>
                        <div className={`px-2 shadow shadow-black/40 bg-pult/10`}>
                            <div
                                className={`grow my-auto`}>Тендер {tender.is_won ? "выигран" : !tender.is_public ? "не опубликован" : "опубликован"}</div>
                            {!tender.is_won && <Btn color={`button`}
                                                    onClick={() => publicTenderHandler(tender.id, !tender.is_public)}>{!tender.is_public ? "Опубликовать" : "Снять с публикации"}</Btn>}
                        </div>
                        <div className={`px-2 flex-1 flex-col flex`}>
                            <div>Раздел: {userSettings.tender_directions[tender.category]}</div>
                            <div>НМЦ лота: {Price(tender.nmc)}</div>
                            <div>Адрес объекта: {tender.address}</div>
                            <div>Дата и время окончания подачи
                                заявок: {new Date(tender.end_date).toLocaleDateString()} г.
                            </div>
                        </div>
                        <Btn method={`view`} color={`button`} center>
                            <Link to={`/work/coordinator/tender-info/${tender.id}`}>Детали тендера</Link>
                        </Btn>
                    </div>)}
                </div> :
                <Warnings info>Нет созданных тендеров</Warnings>
            }
        </UserSection>
    );
}

export default Tenders;