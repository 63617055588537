import {useEffect, useMemo, useState} from "react";
import UserContext from './user-context';
import axios from "axios";
import {useNavigate} from "react-router-dom";

const templateRoles = {
    "investor": "Инвестор",
    "administrator": "Администратор",
    "coordinator": "Координатор",
    "manager": "Начальник участка",
    "buildcontrol": "Стройконтроль",
    "gip": "ГИП",
    "contract": "Подрядчик"
};

export const UserContextProvider = (props) => {
    const erpApiUrl = props.erpApiUrl;
    const fileServer = props.fileServer;
    const navigate = useNavigate();

    const headers = useMemo(() => {
        return {
            "get": {
                headers: {
                    'Authorization': props.token,
                    'accept': 'application/json',
                }
            },
            "post": {
                'accept': 'application/json',
                'Authorization': props.token,
                'Content-Type': 'application/json',
            },
            "postX": {
                'accept': '*/*',
                'Authorization': props.token,
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            "postForm": {
                'Authorization': props.token,
                'accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            },
        };
    }, [props.token]);

    const [userData, setUserData] = useState();
    const [roles, setRoles] = useState();
    const [userCompanyList, setUserCompanyList] = useState([]);
    const [subcompanyList, setSubcompanyList] = useState([]);

    useEffect(() => {
        if (props.acceptSubcompanyId) {
            setSubcompanyList(prevSubcompanyList => {
                return prevSubcompanyList.filter(s => s.id !== props.acceptSubcompanyId);
            });
        }
    }, [props.acceptSubcompanyId]);
    useEffect(() => {
        const options = headers.get;
        const requests = [
            axios.get(`${erpApiUrl}/user/`, options),
            axios.get(`${erpApiUrl}/roles/`, options),
        ];

        axios.all(requests).then(axios.spread((...responses) => {
            setUserData(responses[0].data);

            const getCompanies = [];
            responses[0].data.user_companies.forEach(company => {
                company['name'] = responses[0].data.companies.filter(comp => comp.id === company.company)[0].name;
                getCompanies.push(company);
            })
            setUserCompanyList(getCompanies)

            const getedRoles = {};
            responses[1].data.forEach(role => getedRoles[role.id] = templateRoles[role.name.toLowerCase()]);
            setRoles(getedRoles);
        })).catch(error => {
            console.log('\n error user, roles', error.response.data || error.response);
            navigate(`/authorization`);
        })
    }, [erpApiUrl, headers, navigate]);

    // console.log('\n ', props.token);
    // console.log('\n subcompanyList', subcompanyList);

    if (!userData) {
        console.log('\n error GET current user data');
        return null;
    }
    else {
        // console.log(`\n userData`, userData);

        return (
            <UserContext.Provider value={{
                companies: userData.companies,
                user_companies: userCompanyList,
                email: userData.email,
                api: erpApiUrl,
                token: props.token,
                template_roles: roles,
                fileServer: fileServer,
                tender_directions: props.tender_directions,
                headers: headers,
                subcompanyList: subcompanyList,
                company_tenders: props.companyTenderLots,
                user_info: userData,
            }}>
                {props.children}
            </UserContext.Provider>
        );
    }
}