import styles from "./inputs.module.css";
import {useFormContext} from "react-hook-form";
import HTMLReactParser from "html-react-parser";

const WorkInput = ({name, type, defaultValue, onChange, step, unit, className, notrequired, value}) => {
    const {register, formState: {errors}, clearErrors} = useFormContext();

    const handleOnChangeWithErrorCleaning = (event) => {
        clearErrors(name);
        if (onChange) onChange(event)
    }

    const inputProps = {
        className: `${styles.work} ${className && className}`,
        name,
        type,
        value,
        defaultValue: defaultValue && defaultValue,
        ...register(name, {
            validate: (value) => {
                if (notrequired) return true;
                if (!value) return "Поле обязательно для заполнения";
                return true;
            }
        }),
        onChange: handleOnChangeWithErrorCleaning,
    };

    if (type === "number") {
        inputProps.step = typeof step === "boolean" ? step.toString() : Number(step);
        inputProps.min = inputProps.min || 0; // Установим значение min только если оно не указано
        inputProps.max = inputProps.max || undefined; // Установим значение max только если оно не указано
    }

    const finalInputProps = {
        ...inputProps,
        title: "Обязательное поле",
    }

    console.log(errors[name])
    return (
        <div className={`${(errors[name]) ? styles.invalid : ""} flex gap-2`}>
            <input  {...finalInputProps} />
            <div>
                {unit && HTMLReactParser(unit)}
            </div>
        </div>
    )
};

export default WorkInput;
