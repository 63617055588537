import styles from './UserSelect.module.css';
import {ReactComponent as IconCompany} from "../../UI/Icons/ControlPanel/company.svg";
import {ReactComponent as IconProject} from "../../UI/Icons/ControlPanel/project.svg";
import {ReactComponent as IconEmployee} from "../../UI/Icons/ControlPanel/employee.svg";

const template = {
    company: {title: 'Выберите компанию', icon: <IconCompany />},
    tender: {title: 'Выберите тендер', icon: <IconCompany />},
    project: {title: 'Выберите проект', icon: <IconProject />},
    role: {title: 'Выберите сотрудника', icon: <IconEmployee />},
};

const UserSelect = (props) => {

    const changeUserStateHandler = (event) => props.onChageUserState(props.type, +event.target.value);
    const changeUserStateFromLiHandler = (event) => {

        props.onChageUserState(props.type, +event.target.dataset.id);

        props.showListHandler(false);
    }

    const setShowListId = () => {
        if (props.shownList === props.type) props.showListHandler(false);
        else props.showListHandler(props.type);
    }

    if (props.state) {
        return (
            <div className={styles.container}>
                <div title={template[props.type].title}>{template[props.type].icon}</div>
                <select defaultValue={props.defaultValue} name={props.type} onChange={changeUserStateHandler} >
                    {props.options?.map((item) => (<option value={props.type === 'company' ? item.company : item.id} key={item.id}>{item.name}</option>))}
                </select>
            </div>
        )
    }
    else {

        return (
            <div className={styles.collapsed}>
                <div
                    className={styles.icon}
                    title={template[props.type].title}
                    onClick={setShowListId}
                >
                    {template[props.type].icon}
                </div>
                <ul className={`${styles.dropdown} ${props.shownList === props.type ? styles['dropdown-show'] : ''}`} onMouseLeave={() => props.showListHandler(false)}>
                    {props.options.map((item) => (
                        <li
                            key={item.id}
                            className={`${item.id === props.defaultValue? styles.selected : ''}`}
                            data-id={props.type === 'company' ? item.company : item.id}
                            onClick={changeUserStateFromLiHandler}
                        >
                            {item.name}
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
}

export default UserSelect;