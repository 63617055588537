import React, {useContext, useEffect, useState} from "react";
import UserSection from "../../UI/Sections/UserSection";
import axios from "axios";
import {ErrorOccurred} from "../../UI/Sections/ErrorOccurred";
import UserContext from "../../../store/user/user-context";
import tables from "../../UI/Tables/table.module.css";
import TheadSort from "../../UI/Tables/TheadSort";
import Btn from "../../UI/Buttons/Btn";
import styles from "../dash.module.css";
import {Link} from "react-router-dom";
import {Alert} from "@mui/material";

export default function SubcompanyDocuments() {

    const userSettings = useContext(UserContext);
    const company_id = +window.localStorage.getItem('company');
    const project_id = +window.localStorage.getItem('project');
    const [documents, setDocuments] = useState(null);
    const [answers, setAnswers] = useState(null);
    const columns = [
        {
            tag: "tender_name",
            name: "Тендер",
        },
        {
            tag: "subcompany_name",
            name: "Подрядчик",
        },
        {
            tag: "register_documents_length",
            name: "Документы при регистрации",
        },
        {
            tag: "register_acts_length",
            name: "Акты передачи объекта",
        },
        {
            tag: "quality_documents_length",
            name: "Документы о качестве",
        },
        {
            tag: "work_payment_certificates_length",
            name: "Акты выполненных работ",
        },
    ];
    const [selectedDocuments,setSelectedDocuments] = useState(null);

    useEffect(() => {

        const getData = async () => {

            try {

                const getSubcompanies = await axios.get(
                    `${userSettings.api}/company/${company_id}/subcompanies`,
                    userSettings.headers.get
                );
                const subcompanies = getSubcompanies?.data?.data;
                // console.log('\n subcompanies', subcompanies);

                const getTenders = await axios.get(
                    `${userSettings.api}/tenders/companies/${company_id}/`,
                    userSettings.headers.get
                );
                const currentTenders = getTenders?.data?.filter(tender => (tender.project.id === project_id && tender.is_won));
                // console.log('\n currentTenders', currentTenders);

                if (currentTenders?.length > 0){

                    const getDocuments = currentTenders.map(async (tender) => {

                        const subcompany = subcompanies?.find(s => s.tender === tender.id);

                        const response = await axios.get(
                            `${userSettings.api}/subcompanies/${subcompany.subcompany}/companies/${company_id}/projects/${project_id}/tenders/${tender.id}/all-documents`,
                            userSettings.headers.get
                        );

                        // console.log('\n response', response.data);

                        return ({
                            ...response.data.data,
                            tender,
                            tender_id: tender.id,
                            tender_name: tender.name,
                            subcompany,
                        });
                    });
                    const getAllDocuments = await Promise.all(getDocuments);
                    // console.log('\n getAllDocuments', getAllDocuments);

                    if (getAllDocuments?.length > 0) {

                        const getDocumentsWithSubcompanyInfo = getAllDocuments.map(async (lot) => {

                            const response = await axios.get(
                                `${userSettings.api}/company/${lot.subcompany.subcompany}/`,
                                userSettings.headers.get
                            )

                            // console.log('\n response', response.data);

                            lot.subcompany.info = response.data;
                            lot.subcompany_name = response.data.name;
                            lot.register_documents_length = lot.register_documents.length;
                            lot.register_acts_length = lot.register_acts.length;
                            lot.quality_documents_length = lot.quality_documents.length;
                            lot.work_payment_certificates_length = lot.work_payment_certificates.length;

                            return (lot);
                        });
                        const getAllDocumentsWithSubcompanyInfo = await Promise.all(getDocumentsWithSubcompanyInfo);
                        // console.log('\n getAllDocumentsWithSubcompanyInfo', getAllDocumentsWithSubcompanyInfo);


                        setDocuments(getAllDocumentsWithSubcompanyInfo);
                    }
                }
            }
            catch (errors) {

                // const responseError = ErrorOccurred(
                //     errors.response,
                //     "Ошибка получения документов подрядчика",
                //     "SubcompanyDocuments"
                // );
                setAnswers(<Alert severity="error">Ошибка получения документов подрядчика
                    Данный метод может использовать только суперадминистратор</Alert>);

            }
        }

        getData();
    }, [company_id, project_id, userSettings]);

    const Row = ({document}) => {

        // console.log('\n document.tender_id', document.tender_id);

        return (
            <tr key={document.tender_id}>
                <td>{document.tender_name}</td>
                <td>{document.subcompany_name}</td>
                <td>{document.register_documents_length > 0 && <ViewDocuments array={document.register_documents} />}</td>
                <td>{document.register_acts_length > 0 && <ViewDocuments array={document.register_acts} />}</td>
                <td>{document.quality_documents_length > 0 && <ViewDocuments array={document.quality_documents} />}</td>
                <td>{document.work_payment_certificates_length > 0 && <ViewDocuments array={document.work_payment_certificates} />}</td>
            </tr>
        )
    }
    const ViewDocuments = ({array}) => {

        // console.log('\n ViewDocuments', array);

        return (
            <Btn
                color={`button`}
                method={`view`}
                center
                onClick={() => setSelectedDocuments(array)}
            >
                Перейти ({array.length})
            </Btn>
        )
    }
    const PrintDocument = ({document}) => {

        const fileLink = document.file || document.document;
        const name = document.name || document.work_journal?.estimate?.find(wj => wj.id === document.work_journal_element_id)?.name_works;

        return (
            <div className={styles.card}>
                <div className={styles.label}>{name}</div>
                <div className={`${styles.area} w-[300px]`}>
                    {document.number &&
                        <React.Fragment>
                            <div>Номер документ</div>
                            <div className={styles.items}>
                                {document.number}
                            </div>
                        </React.Fragment>
                    }
                    {document.organization &&
                        <React.Fragment>
                            <div>Дата документа</div>
                            <div className={styles.items}>
                                {new Date(document.date).toLocaleDateString()}
                            </div>
                        </React.Fragment>
                    }
                    {document.date &&
                        <React.Fragment>
                            <div>Наименование организаци</div>
                            <div className={styles.items}>
                                {document.organization}
                            </div>
                        </React.Fragment>
                    }
                    {document.is_declined &&
                        <div className={`bg-warning/30 p-2 rounded-md my-1`}>
                            <div>Документ отклонен координатором</div>
                            <div className={styles.items}>
                                {document.reason}
                            </div>
                        </div>
                    }
                    {document.is_approved &&
                        <div className={`bg-lime-500/30 p-2 rounded-md my-1`}>
                            <div>Документ согласован</div>
                            {document.reason &&
                                <React.Fragment>
                                    <div>Комментарий</div>
                                    <div className={styles.items}>
                                        {document.reason}
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                    }
                </div>
                <Btn
                    method={`download`}
                    color={`button`}
                    center
                >
                    <Link to={`${userSettings.fileServer}${fileLink}`} target="_blank">
                        Скачать
                    </Link>
                </Btn>
            </div>
        );
    }

    // if (documents) console.log('\n documents', documents);
    // if (selectedDocuments) console.log('\n selectedDocuments', selectedDocuments);

    return (
        <UserSection>
            <h1>Документы подрядчика</h1>
            {answers}
            {(!selectedDocuments && documents?.length > 0) &&
                <div className={tables.container}>
                    <table className={tables.table}>
                        <TheadSort
                            array={documents}
                            names={columns}
                            setArray={setDocuments}
                        />
                        <tbody>
                        {documents.map(doc => <Row key={doc.tender_id} document={doc} />)}
                        </tbody>
                    </table>
                </div>
            }
            {selectedDocuments?.length > 0 &&
                <React.Fragment>
                    <Btn method={`back`} color={`button`} onClick={() => setSelectedDocuments(null)}>Назад</Btn>
                    <div className={`flex flex-wrap gap-2`}>
                        {selectedDocuments.map(document => <PrintDocument
                            document={document}
                            key={document.id}
                        />)}
                    </div>
                </React.Fragment>
            }
        </UserSection>
    );
}