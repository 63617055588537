import axios from 'axios';

export const getTendersInfo = async (subcompany_id, userSettings, setTenderList) => {
    try {
        const responses = await axios.get(
            `${userSettings.api}/subcompanies/${subcompany_id}/tender-lots`,
            userSettings.headers.get
        );

        // console.log(`\n getTendersInfo`, responses.data);
        setTenderList(responses.data);

    } catch (error) {
        console.log('\nError in getTendersInfo:', error);
    }
};

export const getQualityDocs = async (userSettings, company_id, project_id, tender_id, subcompany_id) => {
    try {
        const responses = await axios.get(
            `${userSettings.api}/work_payment/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/quality-docs`,
            userSettings.headers.get
        );

        return responses.data;
    } catch (error) {
        console.log('\nError in getQualityDocs:', error);
    }
};