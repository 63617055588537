import styles from "./inputs.module.css";
import {useFormContext} from "react-hook-form";
import {useState} from "react";

const CheckBoxChoice = (props) => {

    const {register, setValue } = useFormContext();
    const registerName = props.id || props.name;
    const [isChecked, setIsChecked] = useState(false);
    const handleChange = () => {

        setIsChecked(!isChecked);

        setValue(String(registerName), !isChecked);
    }

    return (
        <div className={`${styles.checkbox} ${isChecked ? styles.checked : ''} ${props.max ? styles.max : ''}`} onClick={handleChange} >
            <input
                type={props.type}
                name={props.name}
                id={props.id}
                checked={isChecked}
                {...register(String(registerName))}
            />
            <span>{props.label}</span>
        </div>
    );
}

export default CheckBoxChoice;