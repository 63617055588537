import styles from "./header.module.css";
import Alink from "../UI/Buttons/Alink";
import React, {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import RegistrationInput from "../UI/Inputs/RegistrationInput";
import PasswordInputs from "../UI/Inputs/PasswordInputs";
import Grids from "../UI/Grids/Grids";
import Button from "../UI/Buttons/Button";
import {ReactComponent as Warning} from "../UI/Icons/ControlPanel/warning.svg";
import Btn from "../UI/Buttons/Btn";
import {ReactComponent as CloseModal} from "../UI/Icons/close.svg";
import popupStyles from "../UI/Popup/popup.module.css";

const Header = (props) => {

    const methods = useForm();
    const [modal, setModal] = useState(false);
    useEffect(() => {
        if (props.modal) setModal(true);
    },[props.modal]);
    useEffect(() => {
        if (props.isLogin) setModal(false);
    },[props.isLogin]);

    return (
        <header className={styles.header}>
            <div className={styles.logo}>
                <Alink href="/" title="На главную">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                    </svg>
                    <span>На главную</span>
                </Alink>
            </div>
            {props.tenders ?
                <React.Fragment>
                    {props.isLogin ?
                        <div className={styles.btn} onClick={() => props.logoutHandler()}>Выйти</div>
                        :
                        <div className={styles.btn} onClick={() => setModal(true)}>Авторизация</div>
                    }
                    {modal && <div className={`relative`}>
                        <FormProvider {...methods} >
                            <form onSubmit={methods.handleSubmit(props.onAuth)} className={`fixed bg-white/90 top-12 rounded-md right-1 shadow-lg shadow-black/20 overflow-auto`}>
                                <header className={popupStyles.header}>
                                    <h2>Введите эл. почты и пароль</h2>
                                    <button onClick={() => setModal(false)} type="button">
                                        <Btn color="white" icononly={true}><CloseModal /></Btn>
                                    </button>
                                </header>
                                <div className={`px-2`}>
                                    <div className={`relative`}>{props.authError &&
                                        <div className="bg-red-100 text-red-900/90 p-2 flex w-full">
                                            <Warning className="h-6 my-auto" />
                                            <span className="my-auto ml-2 whitespace-pre-wrap w-[250px]">{props.authError}</span>
                                        </div>
                                    }</div>
                                    <RegistrationInput
                                        name="login"
                                        type="email"
                                        label="Адрес эл. почты"
                                    />
                                    <PasswordInputs name="password" />
                                    <Grids cols={2}>
                                        <Button type="submit">Войти</Button>
                                        <Alink href="/forgot-password">Не помню пароль</Alink>
                                    </Grids>
                                </div>
                            </form>
                        </FormProvider>
                    </div>}
                </React.Fragment>
                :
                <Alink href="/authorization">Авторизация</Alink>
            }
            <Alink href="/registration-company">Регистрация</Alink>
            <Alink href="/tenders">Тендеры</Alink>
        </header>
    );
}

export default Header;