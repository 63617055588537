import React from "react";
import styles from "./warning.module.css";
import {ReactComponent as Warning} from "../Icons/ControlPanel/warning.svg";
import {ReactComponent as Correct} from "../Icons/ControlPanel/correct.svg";
import {ReactComponent as Reject} from "../Icons/Administrator/accessRejected.svg";
import {ReactComponent as Info} from "../Icons/info.svg";
import {ReactComponent as Comment} from "../Icons/comment.svg";

export default function Warnings(props) {

    return (
        <div className={`
            ${styles.body} 
            ${props.correct ?
                styles.correct
                :
                props.reject ?
                    styles.reject
                    :
                    (props.info || props.comment) ?
                        styles.info
                        :
                        styles.warning
            }
            ${props.max ? styles.max : ''}
        `}>
            <span className={styles.icon}>{props.correct ?
                <Correct />
                :
                props.reject ?
                    <Reject />
                    :
                    props.info ?
                        <Info />
                        :
                        props.comment ?
                            <Comment />
                            :
                            <Warning />
            }</span>
            <div>{props.children}</div>
        </div>
    );
}