import React, {useContext, useEffect, useState} from "react";
import UserSection from "../../UI/Sections/UserSection";
import {Link, useNavigate} from "react-router-dom";
import UserContext from "../../../store/user/user-context";
import axios from "axios";
import Warnings from "../../UI/Sections/Warnings";
import Btn from "../../UI/Buttons/Btn";
import {Price} from "../../UI/Convertors/Price";
import Popup from "../../UI/Popup/Popup";
import Grids from "../../UI/Grids/Grids";
import Button from "../../UI/Buttons/Button";
import {FormProvider, useForm} from "react-hook-form";
import RegistrationInput from "../../UI/Inputs/RegistrationInput";
import TenderResults from "./TenderResults";
import {nanoid} from "nanoid";

export default function TenderInfo() {

    const navigate = useNavigate();
    if (window.localStorage.getItem('role') !== '2') navigate('/work/');

    const userSettings = useContext(UserContext);
    const company_id = +window.localStorage.getItem('company');
    const project_id = +window.localStorage.getItem('project');
    const path = window.location.pathname.split('/');
    const tender_id = +path[path.length - 1];
    const methods = useForm();
    const {unregister} = methods;

    const [answers, setAnswers] = useState(null);
    const [popupShow, setPopupShow] = useState(false);
    const [tender, setTender] = useState(null);
    const [editDocs, setEditDocs] = useState(null);
    const [lots, setLots] = useState(null);

    console.log(editDocs)

    const editRepresentativeHandler = (representative, phone, email, tender_id) => {

        const editRepresentative = async (data) => {

            setPopupShow(null);

            try {

                const formData = new FormData();
                formData.append('representative', data.representative);
                formData.append('phone', data.phone.replace(/ /g, ''));
                formData.append('email', data.email);

                const response = await axios.put(
                    `${userSettings.api}/tenders/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/representative/`,
                    formData,
                    {headers: userSettings.headers.postForm}
                )

                console.log(`\n response`, response.data);
                setAnswers(<Warnings correct>Изменение представителя обновлены</Warnings>);

                setTender(response.data);
            } catch (error) {
                setAnswers(<Warnings>
                    Ошибка изменения представителя - {error.response.data.errors ?
                    error.response.data.errors.map((e, id) => <p key={id}>{e.detail}</p>)
                    :
                    error.response.status
                }
                </Warnings>)
                console.log(`\n editRepresentativeHandler error`, error.response);
            }
        }

        const fields = [
            {
                "name": "representative",
                "type": "text",
                "label": "Ф.И.О. представителя",
                "defaultValue": representative
            },
            {
                "name": "phone",
                "type": "tel",
                "label": "Телефон представителя",
                "defaultValue": phone
            },
            {
                "name": "email",
                "type": "email",
                "label": "E-mail представителя",
                "defaultValue": email
            }
        ];

        setPopupShow(<Popup
            title={`Изменить представителя тендера`}
            form={{
                fields: fields,
                submit: "Сохранить"
            }}
            onSubmit={editRepresentative}
            onCloseModal={() => setPopupShow(false)}
        />);
    }
    const putDocumentsHandler = async (data) => {
        try {

            console.log(`\n data`, data);
            const formData = new FormData();
            for (const key in data) {
                if (key.includes('files')) {
                    const index = key.split('-')[1];
                    const fileList = data[key];
                    const nameKey = `names-${index}`;
                    const fileName = data[nameKey];

                    Array.from(fileList).forEach(file => {
                        formData.append(`files[${index}]`, file);
                    });

                    formData.append(`names[${index}]`, fileName);
                }
            }
            console.log(`\n formData`, formData);
            const response = await axios.put(
                `${userSettings.api}/tenders/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/documents/`,
                formData,
                {headers: userSettings.headers.postForm}
            );
            console.log(`\n response`, response.data);
            if (response.status === 200) window.location.reload();
        } catch (error) {
            setAnswers(<Warnings>
                Ошибка изменения документов - {error.response.data.errors ?
                error.response.data.errors.map((e, id) => <p key={id}>{e.detail}</p>)
                :
                error.response.status
            }
            </Warnings>)
            console.log(`\n putDocumentsHandler error`, error.response);
        }
    }
    const editDocsHandler = () => {

        console.log(`\n editDocsHandler`,);

        if (tender && tender.attachments) setEditDocs(tender.attachments.map(d => d.name));
    }
    const addDoc = () => {
        const updatedDocs = [...editDocs];
        const newId = nanoid();
        updatedDocs.push({
            name: 'Новый документ',
            id: newId
        });
        setEditDocs(updatedDocs);
    }

    // console.log(tender)
    const publicTenderHandler = async () => {

        try {
            const formData = new FormData();
            formData.append('is_public', false);

            const response = await axios.post(
                `${userSettings.api}/tenders/companies/${company_id}/project/${project_id}/tenders/${tender_id}/change-publishing`,
                formData,
                {headers: {...userSettings.headers.post, 'Content-Type': 'multipart/form-data'}}
            );

            if (response.status === 200) window.location.reload();
        } catch (error) {
            console.error('Error', error.response.data);
            // Обработка ошибки
        }
    }
    const tenderConfirm = async (tender_lot_id, reason) => {

        console.log(`\n tenderConfirm`, tender_lot_id, reason);

        try {

            const formData = new FormData();
            formData.append("reason", reason)

            const response = await axios.post(
                `${userSettings.api}/tenders/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/tender-lots/${tender_lot_id}/confirm/`,
                formData,
                {headers: userSettings.headers.post}
            );
            console.log(`\n response`, response.data);
            publicTenderHandler(false);
        } catch (error) {
            setAnswers(<Warnings>
                Ошибка выигрыша тендера - {error.response.data.errors ?
                error.response.data.errors.map((e, id) => <p key={id}>{e.detail}</p>)
                :
                error.response.status
            }
            </Warnings>)
            console.log(`\n tenderConfirm error`, error.response);
        }
    }

    const removeDocHandler = (id, inputName, fileName) => {
        const updatedDocs = [...editDocs].filter(doc => doc.id !== id);
        console.log(updatedDocs)
        unregister(inputName);
        unregister(fileName);
        setEditDocs(updatedDocs);
    }

    useEffect(() => {
        window.localStorage.setItem("tender", +path[path.length - 1])

        const getData = async () => {

            try {
                const getTenders = await axios.get(
                    `${userSettings.api}/tenders/companies/${company_id}/`,
                    userSettings.headers.get
                );
                const currentTender = getTenders.data.find(t => t.id === tender_id);
                setTender(currentTender);
                setAnswers(null);

                const getLots = await axios.get(
                    `${userSettings.api}/tenders/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/tender-lots/`,
                    userSettings.headers.get
                );
                // console.log(`\n getLots`, getLots.data);
                if (getLots.data && getLots.data.length > 0) setLots(getLots.data.sort((a, b) => a.id - b.id));
            } catch (error) {
                setAnswers(<Warnings>
                    Ошибка получения информации - {error.response.data.errors ?
                    error.response.data.errors.map((e, id) => <p key={id}>{e.detail}</p>)
                    :
                    error.response.status
                }
                </Warnings>)
                console.log(`\n getData error`, error.response);
            }
        }

        getData();
    }, [company_id, project_id, userSettings, tender_id]);

    if (tender) console.log(`\n tender`, tender);
    if (editDocs) console.log(`\n editDocs`, editDocs);

    return (
        <UserSection>
            <h1>Информация по тендеру {tender && tender.name}</h1>
            {popupShow}
            {answers && answers}
            <Btn method={'back'} color={`button`}>
                <Link to={"/work/coordinator/tenders"}>Назад</Link>
            </Btn>
            {tender && <React.Fragment>
                <Grids cols={2}>
                    <div>
                        <div
                            className={`grow my-auto`}>Тендер {tender.is_won ? "выигран" : !tender.is_public ? "не опубликован" : "опубликован"}</div>
                        <div>Информация по тендеру</div>
                        <div>Раздел: {userSettings.tender_directions[tender.category]}</div>
                        <div>НМЦ лота: {Price(+tender.nmc)}</div>
                        <div>Адрес объекта: {tender.address}</div>
                        <div>Дата и время окончания подачи заявок: {new Date(tender.end_date).toLocaleDateString()} г.
                        </div>
                    </div>
                    <div>
                        <div>Представитель тендера</div>
                        <div className={`p-1 border border-pult/20 rounded-md bg-pult/5 my-1.5`}>
                            <div>Представитель: {tender.representative}</div>
                            <div>Тел.: {tender.phone}</div>
                            <div>E-mail: {tender.email}</div>
                        </div>
                        <Btn
                            method={`edit`}
                            color={`button`}
                            onClick={() => editRepresentativeHandler(tender.representative, tender.phone, tender.email, tender.id)}
                        >
                            Редактировать представителя
                        </Btn>
                    </div>
                </Grids>
                {!editDocs && <React.Fragment>
                    <div>Документы к тендеру</div>
                    <div className={`flex`}>
                        <div className={`flex flex-wrap flex-1 gap-2 items-center border border-pult/20 rounded-md`}>
                            {tender.attachments && tender.attachments.length > 0 && tender.attachments.map((doc, id) =>
                                <Btn key={id} link>
                                    <Link target={`_blank`}
                                          to={`${userSettings.fileServer}${doc.file}`}>{doc.name}</Link>
                                </Btn>
                            )}
                        </div>
                    </div>
                    <Btn method={`edit`} color={`button`} onClick={editDocsHandler}>Редактировать документы</Btn>
                </React.Fragment>}
            </React.Fragment>}
            {editDocs && <FormProvider {...methods} >
                <form onSubmit={methods.handleSubmit(putDocumentsHandler)}>
                    <div className={`flex flex-wrap gap-2`}>
                        {editDocs && editDocs.map((name, id) => {

                            return (<div key={id}>
                                <RegistrationInput
                                    name={`names-${id}`}
                                    label={`Наименование документа`}
                                    type={'text'}
                                    defaultValue={name}
                                />
                                <RegistrationInput
                                    name={`files-${id}`}
                                    label={`Загрузите документ`}
                                    type={`file`}
                                />
                                <Btn
                                    method={`remove`}
                                    center
                                    onClick={() => removeDocHandler(id, `names-${id}`, `files-${id}`)}
                                >
                                    Убрать документ
                                </Btn>
                            </div>);
                        })}
                    </div>
                    <Button onClick={() => setEditDocs(null)}>Назад</Button>
                    <Button onClick={addDoc}>Добавить документ</Button>
                    <Button type="submit">Изменить документы</Button>
                </form>
            </FormProvider>}
            {lots && <TenderResults
                lots={lots}
                tender={tender}
                userSettings={userSettings}
                tenderConfirm={tenderConfirm}
            />}
        </UserSection>
    );
}