import {pdf, PDFDownloadLink} from "@react-pdf/renderer";
import AktOsvidetelstvovaniya from "../../../UI/PdfDocuments/Akt-Osvidetelstvovaniya";
import Select from "../../../UI/Select/Select";
import useFetchedInfo from "./hooks/useFetchedInfo";
import useMixedSelect from "./hooks/useMixedSelect";
import {validateSelect} from "./helpers/validateSelect";
import useRepresentativeSelect from "./hooks/useRepresentativeSelect";
import {FormProvider, useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import Input from "../../../UI/Inputs/Input";
import UserSection from "../../../UI/Sections/UserSection";
import {Button, Typography} from "@mui/material";

const GenerateAktOsvidetelstvovaniyaPDF = ({setAktOsv}) => {
    const subcompany_id = +window.localStorage.getItem('company');
    const tender_id = +window.localStorage.getItem('tender');

    const {fetchedInfo, isLoading} = useFetchedInfo(subcompany_id, tender_id);

    // ------------------------------------------------------------------------------------------

    const {
        mixedState,
        isSelectedMixed,
        handleMixedOnChange,
    } = useMixedSelect(fetchedInfo?.personsList);

    const {
        representativesState,
        isSelectedRepresentatives,
        handleRepresentativesOnChange,
    } = useRepresentativeSelect(fetchedInfo?.personsList);

    // ------------------------------------------------------------------------------------------

    const methods = useForm({
        defaultValues: {
            first: "",
            second: "",
            fourth: "",
            fifth_a: "",
            fifth_b: "",
            sixth: "",
            eighth: "",
            ninth_a: "",
            ninth_a_load: "",
            ninth_a_conditions: "",
            ninth_b: "",
            additional_info: "",
            copies: "",
            attachments: "",
        }
    });

    const {register, handleSubmit, formState: {errors}} = methods;

    const [submitted, setSubmitted] = useState(false);

    const handleSubmitCallback = async (data, e) => {
        e.stopPropagation();
        e.preventDefault();
        setSubmitted(true);

        // Process form data here if needed

        // Example: send form data to server or perform actions
        // await sendFormDataToServer(formData);
    };

    const formData = methods.getValues();
    // ------------------------------------------------------------------------------------------

    const isPDFVisible =
        !isLoading &&
        isSelectedMixed &&
        isSelectedRepresentatives &&
        submitted;

    useEffect(() => {
        if (!isPDFVisible) return;

        const blob = pdf((<AktOsvidetelstvovaniya
            fetchedInfo={fetchedInfo}
            mixedState={mixedState}
            representativesState={representativesState}
            formData={formData}
        />)).toBlob().then(blob => setAktOsv(new File([blob], "Акт Освидетельствования.pdf")))

    }, [isPDFVisible]);

    return (
        <div>
            {!isLoading && fetchedInfo &&
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(handleSubmitCallback)}>
                        <Typography variant="h1">Акт освидетельствования ответственных конструкций:</Typography>
                        <div className='space-y-4 m-3 p-3 bg-[#e4e4e7] rounded-lg'>
                            {/*<Button onClick={sendAktOsvid}>send akt-osvid</Button>*/}
                            <p>Застройщик или технический заказчик:</p>
                            <Select
                                name='customer_id'
                                handleOnChange={handleMixedOnChange}
                                options={validateSelect(fetchedInfo.personsList)}
                            />

                            <p>Лицо, осуществляющее строительство:</p>
                            <Select
                                name='builder_id'
                                handleOnChange={handleMixedOnChange}
                                options={validateSelect(fetchedInfo.personsList)}
                            />

                            <p>Лицо, осуществляющее подготовку проектной документации:</p>
                            <Select
                                name='documentation_id'
                                handleOnChange={handleMixedOnChange}
                                options={validateSelect(fetchedInfo.personsList)}
                            />

                            <p>Лицо, выполнившее работы, подлежащие освидетельствованию:</p>
                            <Select
                                name='inspection_id'
                                handleOnChange={handleMixedOnChange}
                                options={validateSelect(fetchedInfo.personsList)}
                            />
                            
                            {/*------------------------------------------------------*/}

                            <p>Представитель лица, осуществляющего строительство, по вопросам строительного
                                контроля:</p>
                            <Select
                                name='builder_control_id'
                                handleOnChange={handleRepresentativesOnChange}
                                options={validateSelect(fetchedInfo.personsList)}
                            />

                            <p>а также иные представители лиц, участвующих в освидетельствовании:</p>
                            <Select
                                name='other_inspection_id'
                                handleOnChange={handleRepresentativesOnChange}
                                options={validateSelect(fetchedInfo.personsList)}
                            />

                            <p>Представители иных лиц:</p>
                            <Select
                                name='other_id'
                                handleOnChange={handleRepresentativesOnChange}
                                options={validateSelect(fetchedInfo.personsList)}
                            />

                            <div className='w-[400px]'>
                                <p>1. К освидетельствованию предъявлены следующие ответственные конструкции</p>
                                <Input
                                    error={errors?.first?.message} {...register("first", {required: "Заполните поле"})}/>
                                <p>2. Конструкции выполнены по проектной документации</p>
                                <Input
                                    error={errors?.second?.message} {...register("second", {required: "Заполните поле"})}/>
                                <p>4. Освидетельствованы скрытые работы, которые оказывают влияние на безопасность
                                    конструкций</p>
                                <Input
                                    error={errors?.fourth?.message} {...register("fourth", {required: "Заполните поле"})}/>
                                <p>5. Предъявлены документы, подтверждающие соответствие конструкций предъявляемым к
                                    ним
                                    требованиям, в том числе:</p>
                                <p>а) исполнительные геодезические схемы положения конструкций</p>
                                <Input
                                    error={errors?.fifth_a?.message} {...register("fifth_a", {required: "Заполните поле"})}/>
                                <p>б) результаты экспертиз, обследований, лабораторных и иных испытаний выполненных
                                    работ, проведенных в процессе строительного контроля</p>
                                <Input
                                    error={errors?.fifth_b?.message} {...register("fifth_b", {required: "Заполните поле"})}/>
                                <p>6. Проведены необходимые испытания и опробования</p>
                                <Input
                                    error={errors?.sixth?.message} {...register("sixth", {required: "Заполните поле"})}/>
                                <p>8. Предъявленные конструкции выполнены в соответствии с техническими
                                    регламентами,
                                    иными нормативными правовыми актами и проектной документацией</p>
                                <Input
                                    error={errors?.eighth?.message} {...register("eighth", {required: "Заполните поле"})}/>

                                <p>9. На основании изложенного:</p>
                                <p>а) разрешается использование конструкций по назначению</p>
                                <Input
                                    error={errors?.ninth_a?.message} {...register("ninth_a", {required: "Заполните поле"})}/>
                                <p>или разрешается использование конструкций по назначению с нагружением в n %
                                    проектной нагрузки</p>
                                <Input
                                    error={errors?.ninth_a_load?.message} {...register("ninth_a_load", {required: "Заполните поле"})}/>
                                <p>или разрешается полное нагружение при выполнении следующих условий:</p>
                                <Input
                                    error={errors?.ninth_a_conditions?.message} {...register("ninth_a_conditions", {required: "Заполните поле"})}/>
                                <p>б) разрешается производство последующих работ:</p>
                                <Input
                                    error={errors?.ninth_b?.message} {...register("ninth_b", {required: "Заполните поле"})}/>
                                <p>Дополнительные сведения</p>
                                <Input
                                    error={errors?.additional_info?.message} {...register("additional_info", {required: "Заполните поле"})}/>
                                <p>Акт составлен в n экземплярах</p>
                                <Input
                                    error={errors?.copies?.message} {...register("copies", {required: "Заполните поле"})}/>
                                <p>Приложения:</p>
                                <Input
                                    error={errors?.attachments?.message} {...register("attachments", {required: "Заполните поле"})}/>
                            </div>

                            <Button
                                type={'submit'}
                                className='w-full'
                                variant="contained"
                            >Сформировать документ</Button>
                        </div>
                    </form>
                </FormProvider>
            }
            {isPDFVisible &&
                <div>
                    <PDFDownloadLink
                        document={
                            <AktOsvidetelstvovaniya
                                fetchedInfo={fetchedInfo}
                                mixedState={mixedState}
                                representativesState={representativesState}
                                formData={formData}
                            />}
                        fileName={'Akt-osvidetelstvovaniya-otvetstvennyh-konstrukciy'}
                    >
                        <Button variant="contained">Готово к загрузке!</Button>
                    </PDFDownloadLink>

                    {/*<PDFViewer>*/}
                    {/*    <AktOsvidetelstvovaniya*/}
                    {/*        fetchedInfo={fetchedInfo}*/}
                    {/*        mixedState={mixedState}*/}
                    {/*        representativesState={representativesState}*/}
                    {/*        formData={formData}*/}
                    {/*    />*/}
                    {/*</PDFViewer>*/}
                </div>
            }
        </div>
    )
};

export default GenerateAktOsvidetelstvovaniyaPDF;
