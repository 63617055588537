import React from 'react';
import {Box, Typography} from "@mui/material";
import {getLotsForRequest} from "../helpers/getLotsForRequest";
import CustomizedTables from "../../Administrator/CustomizedTables";

const RequestItem = ({request, previousAdded}) => {
    return (
        <Box>
            <Typography>{request.company.name}</Typography>
            <Typography>Новый тариф выглядит следующим образом:</Typography>

            <CustomizedTables
                key={`${getLotsForRequest(request).key}_table`}
                lots={getLotsForRequest(request)}
                previousAdded={previousAdded}
            />

            <Box className='flex gap-4 mt-4'>
                <Typography>Цена тарифа до изменения - {request.old_tariff_month_price}</Typography>
                <Typography>Цена тарифа после изменения - {request.new_tariff_month_price}</Typography>
            </Box>
        </Box>
    );
};

export default RequestItem;