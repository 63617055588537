import styles from "./inputs.module.css";
import React, { forwardRef } from "react";

const Input = forwardRef((props, ref) => {
    return (
        <div className={`${styles.field} ${props.className} ${props.error ? styles.invalid : ''}`}>
            <label htmlFor={props.name}>
                {props.error ? props.error : props.label}
            </label>
            <input
                ref={ref}
                name={props.name}
                type={props.type}
                onChange={props.onChange}
            />
        </div>
    );
});

export default Input;