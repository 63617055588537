import axios from "axios";

export const patchCompaniesTariffById = async (userSettings, company_id, body) => {
    try {
        const tariff = await axios.patch(
            `${userSettings.api}/tariffs/companies/${company_id}/`,
            body,
            {headers: userSettings.headers.post}
        );
        console.log(tariff.data)

        return tariff.data
    } catch (error) {
        console.error(`Ошибка /api/v1/tariffs/companies/{company_id}: `, error);
    }
}

export const getCompaniesTariffAvailableRolesById = async (userSettings, company_id) => {
    try {
        const availableRoles = await axios.get(
            `${userSettings.api}/tariffs/companies/${company_id}/check_available_roles/`,
            userSettings.headers.get
        );

        return availableRoles.data;
    } catch (error) {
        console.error(`Ошибка /api/v1/tariffs/companies/{company_id}/check_available_roles: `, error);
    }
}

export const postCompaniesTariffByIdCreateRequest = async (userSettings, company_id, body) => {
    const tariff = await axios.post(
        `${userSettings.api}/tariffs/companies/${company_id}/create_tariff_request/`,
        body,
        {headers: userSettings.headers.post}
    );
    console.log(tariff.data);

    return tariff.data
}