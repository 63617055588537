import { useEffect, useState } from 'react';

const useRepresentativePersons = (representative_id, personList) => {
    const [representativePerson, setRepresentativePerson] = useState(null);

    useEffect(() => {
        if (!personList || !representative_id) return;
        personList.forEach(person => {
            person.representatives.forEach(representative => {
                if (String(representative.id) === representative_id) {
                    setRepresentativePerson(representative);
                }
            })
        })
    }, [representative_id, personList]);

    return representativePerson;
};

export default useRepresentativePersons;