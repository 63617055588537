import React, {useContext, useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import UserContext from "../../../store/user/user-context";
import {getCompany} from "../../UI/GetInfo/getCompany";
import axios from "axios";
import UserSection from "../../UI/Sections/UserSection";
import Warnings from "../../UI/Sections/Warnings";
import Btn from "../../UI/Buttons/Btn";
import inputsStyles from "../../UI/Inputs/inputs.module.css";
import {PrintPhone} from "../../UI/Convertors/PrintPhone";
import {sendSequentialRequests} from "../../SendRequests/sendSequentialRequests";
import {ErrorOccurred} from "../../UI/Sections/ErrorOccurred";
import {Alert, Box, Button, IconButton, Snackbar, Typography} from "@mui/material";
import AddSignatoryModal from "../Subcompany/AddSignatoryModal";
import ModalMuiWrapper from "../common/components/ModalMUIWrapper";
import DeleteIcon from "@mui/icons-material/Delete";
import InputFile from "../../UI/Inputs/InputFile";
import {FormProvider, useForm} from "react-hook-form";

export default function InfoAccept() {

    const navigate = useNavigate();
    if (window.localStorage.getItem('role') !== '2') navigate('/work/');

    const methods = useForm();
    const {handleSubmit} = methods;

    const userSettings = useContext(UserContext);
    const company_id = localStorage.getItem("company");
    const project_id = localStorage.getItem("project");
    const subcompanyData = new URLSearchParams(useLocation().search);
    const subcompany_id = +subcompanyData.get('subcompany');
    const tender_id = +subcompanyData.get('tender');

    const [answers, setAnswers] = useState(null);
    const [subcompanyInfo, setSubcompanyInfo] = useState(null);
    const [subCompanyState, setSubCompanyState] = useState(null);
    const [contractDocuments, setContractDocuments] = useState(null);
    const [contractActs, setContractActs] = useState(null);
    const [reasons, setReasons] = useState({});

    const [popupShow, setPopupShow] = useState(false);
    const [signatories, setSignatories] = useState(null);

    const [addedPerson, setAddedPerson] = useState(false);
    const [addedSubcompanySign, setAddedSubcompanySign] = useState(false);

    const infoKeys = [
        {
            name: "name",
            label: "Наименование компании",
            disabled: true,
        },
        {
            name: "legal_address",
            label: "Юридический адрес",
            disabled: subcompanyInfo?.type === 2,
        },
        {
            name: "postal_address",
            label: "Почтовый адрес",
        },
        {
            name: "kpp",
            label: "КПП",
            disabled: true,
        },
        {
            name: "inn",
            label: "ИНН",
            disabled: true,
        },
        {
            name: "ogrn",
            label: "ОГРН",
            disabled: true,
        },
        {
            name: "okpo",
            label: "ОКПО",
            disabled: true,
        },
        {
            name: "bank",
            label: "Наименование банка",
            disabled: true,
        },
        {
            name: "settlement_account",
            label: "Р/С",
        },
        {
            name: "correspondent_account",
            label: "К/С",
            disabled: true,
        },
        {
            name: "bik",
            label: "БИК",
            // disabled: true,
        },
        {
            name: "director",
            label: "Ген директор (Ф.И.О.)",
        },
        {
            name: "basis",
            label: "Основание полномочий",
        },
        {
            name: "email",
            label: "Адрес официальной эл. почты",
            disabled: true,
        },
        {
            name: "phone",
            label: "Контактный телефон",
        },
        {
            name: "type",
            label: "Право собственности",
            disabled: true,
        },
    ];
    const docsKeys = [
        {
            name: "number",
            label: "Номер документа",
        },
        {
            name: "date",
            label: "Дата документа",
        },
        {
            name: "organization",
            label: "Наименование организации",
        },
    ];
    const ownershipNames = {
        1: "Частное лицо",
        2: "Юридическое лицо",
        3: "Индивидуальный предприниматель",
    };

    let timeoutId;
    const reasonHandler = (name, comment, value) => {

        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            const currentReason = {...reasons};
            value = value.trim();

            if (value.length > 2) {
                if (!currentReason[comment]) currentReason[comment] = {};
                currentReason[comment][name] = value;
            } else {
                if (currentReason[comment]) delete currentReason[comment][name];
            }

            setReasons(currentReason);
        }, 500);

    }

    const acceptSubcompanyInfo = async () => {

        try {
            console.log('subcompany_info')

            const acceptInfo = await axios.post(
                `${userSettings.api}/subcompanies/${subcompany_id}/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/accept-info`,
                '',
                {headers: userSettings.headers.post}
            );
            console.log(`\n acceptInfo`, acceptInfo.data);
            setAnswers(<Warnings correct>{acceptInfo.data.detail}</Warnings>)
            window.location.reload();
        } catch (error) {

            const responseError = ErrorOccurred(
                error.response,
                "Ошибка согласования документов при регистрации",
                "acceptSubcompanyDocs"
            );
            setAnswers(responseError.jsx);
        }
    }
    const rejectSubcompanyInfo = async () => {
        try {
            const requests = Object.keys(reasons['info']).map((key) => {

                const formData = new FormData();
                formData.append("reason", reasons['info'][key]);
                formData.append("name", key);

                return ({
                    method: 'POST',
                    url: `${userSettings.api}/subcompanies/${subcompany_id}/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/company-verification/`,
                    data: formData,
                    headers: userSettings.headers.postForm
                });
            });

            const results = await sendSequentialRequests(requests);
            // console.log(`\n results`, results);

            if (results.length === Object.keys(reasons['info']).length) window.location.reload();
        } catch (error) {
            console.log(`\n error`, error);
        }
    }

    const acceptSubcompanyDocs = async () => {

        try {
            console.log('subcompany_docs')

            const signDocs = await axios.post(
                `${userSettings.api}/subcompanies/${subcompany_id}/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/sign-docs`,
                '',
                {headers: userSettings.headers.post}
            );
            console.log(`\n signDocs`, signDocs);
            // setAnswers(<Warnings correct>{acceptInfo.data.detail}</Warnings>)
            // window.location.reload();
        } catch (error) {

            const responseError = ErrorOccurred(
                error.response,
                "Ошибка согласования документов при регистрации",
                "acceptSubcompanyDocs"
            );
            setAnswers(responseError.jsx);
        }
    }
    const rejectSubcompanyDocs = async () => {

        try {

            const comments = reasons['docs'];

            if (Object.keys(comments).length > 0) {

                const requests = Object.keys(comments).map((document_id) => {

                    const formData = new FormData();
                    formData.append("reason", comments[document_id]);
                    formData.append("is_declined", true);

                    // console.log(
                    //     `\n uploadDocsHandler`,
                    //     `\n key`, document_id,
                    //     `\n comments`, comments[document_id],
                    //     `\n formData`, formData,
                    // );

                    return ({
                        method: 'POST',
                        url: `${userSettings.api}/subcompanies/${subcompany_id}/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/declined-documents/${document_id}`,
                        data: formData,
                        headers: userSettings.headers.postForm
                    });
                });

                const results = await sendSequentialRequests(requests);
                const errorsResult = results.filter(r => r.error).map(r => r.errorMessage).flat();
                const responseError = ErrorOccurred(
                    null,
                    "Ошибка загрузки документов",
                    "uploadDocsHandler",
                    errorsResult
                );
                const correctResult = results.filter(r => !r.error);

                console.log(
                    `\n rejectSubcompanyDocs`,
                    `\n comments`, comments,
                    `\n results`, results,
                    `\n correctResult`, correctResult,
                );

                setAnswers(responseError.jsx);

                if (correctResult.length > 0) window.location.reload();
            }
        } catch (error) {

            const responseError = ErrorOccurred(
                error.response,
                "Ошибка отклонения документов при регистрации",
                "rejectSubcompanyDocs"
            );
            setAnswers(responseError.jsx);
        }
    }
    const acceptSubcompanyActs = async () => {

        try {
            console.log('subcompany_acts')

            const signDocs = await axios.post(
                `${userSettings.api}/subcompanies/${subcompany_id}/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/sign-acts`,
                '',
                {headers: userSettings.headers.post}
            );
            console.log(`\n signDocs`, signDocs);
            // setAnswers(<Warnings correct>{acceptInfo.data.detail}</Warnings>)
            // window.location.reload();
        } catch (error) {

            const responseError = ErrorOccurred(
                error.response,
                "Ошибка согласования актов при регистрации",
                "acceptSubcompanyActs"
            );
            setAnswers(responseError.jsx);
        }
    }

    useEffect(() => {

        const getSubcompanyInfo = async () => {

            try {
                getCompany([subcompany_id], userSettings)
                    .then(results => {
                        // console.log("Результаты получены:", results);
                        setSubcompanyInfo(results[0]);
                    })
                    .catch(error => {
                        console.error("Произошла ошибка при получении компаний:", error)
                    });

                const subcompanyState = await axios(
                    `${userSettings.api}/company/${company_id}/subcompanies`,
                    userSettings.headers.get
                )
                const currentSubcompanyState = subcompanyState.data.data.find(s => s.tender === tender_id);
                // console.log(`\n currentSubcompanyState`, currentSubcompanyState, subcompanyState);
                setSubCompanyState(currentSubcompanyState);

                const registerDocs = await axios.get(
                    `${userSettings.api}/subcompanies/${subcompany_id}/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/register-docs/`,
                    userSettings.headers.get
                );
                // console.log(`\n registerDocs`, registerDocs.data);
                setContractDocuments((registerDocs.data.length > 0) && registerDocs.data);

                const registerActs = await axios.get(
                    `${userSettings.api}/subcompanies/${subcompany_id}/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/register-acts/`,
                    userSettings.headers.get
                );
                console.log(`\n registerActs`, registerActs.data);
                setContractActs((registerActs.data.length > 0) && registerActs.data);

                setAnswers(null);

            } catch (error) {
                setAnswers(<Warnings>
                    Ошибка получения данных - {error.response.data.errors ?
                    error.response.data.errors.map((e, id) => <p key={id}>{e.detail}</p>)
                    :
                    error.response.status
                }
                </Warnings>)
                console.log(`\n getSubcompanyInfo error`, error.response);
            }
        }

        getSubcompanyInfo();

    }, [subcompany_id, userSettings, company_id, project_id, tender_id]);

    const agreementTypes = [
        {
            id: 1,
            type: 'is_info_accepted',
            label: 'Принять информацию по подрядчику',
            complete_message: "Информация о компании подрядчика принята",
            keys: infoKeys,
            acceptHandler: acceptSubcompanyInfo,
            rejectHandler: rejectSubcompanyInfo,
            reason: null,
            comment: null,
            data: subcompanyInfo,
        },
        {
            id: 2,
            type: 'is_documents_accepted',
            label: 'Принять документы подрядчика',
            complete_message: "Документы подрядчика приняты",
            keys: docsKeys,
            acceptHandler: acceptSubcompanyDocs,
            rejectHandler: rejectSubcompanyDocs,
            reason: "Подрядчик не загрузил документы",
            comment: "docs",
            data: contractDocuments,
        },
        {
            id: 3,
            type: 'is_acts_accepted',
            label: 'Акты подрядчика при регистрации',
            complete_message: "Акты подрядчика приняты",
            keys: docsKeys,
            acceptHandler: acceptSubcompanyActs,
            rejectHandler: null,
            reason: "Подрядчик не загрузил акты",
            comment: null,
            data: contractActs,
        },
    ];
    const renderInfo = (property, data, ownershipNames, type) => {
        const companyProperty = (type.id === 1 && property.name === 'type') && ownershipNames[data[property.name]];
        const propertyResult = property.name === "phone" ? PrintPhone(data[property.name]) : (data[property.name]);

        // console.log(`\n propertyResult`, propertyResult);

        return (
            <div key={property.name} className={`border border-pult/20 rounded-md px-1 py-0.5`}>
                <div className={`text-center`}>{property.label}</div>
                {data[property.name] &&
                    <div className={`px-1 py-0.5 border border-pult/30 rounded-md bg-pult-hover/10 my-1`}>
                        {companyProperty ?
                            companyProperty :
                            propertyResult
                        }
                    </div>}
            </div>
        );
    };
    const renderDocs = (data, type) => {

        return (<div className={`px-1 py-0.5 border border-pult/20 rounded-md`} key={data.id}>
            <Btn method={`download`} color={`button`} center>
                <Link to={`${userSettings.fileServer}${data.file}`} target={`_blank`}>{data.name}</Link>
            </Btn>
            <div className={type.id !== 3 ? `my-3` : ''}>
                {docsKeys.map(property => {

                    const propertyResult = property.name === 'date' ? new Date(data[property.name]).toLocaleDateString() : data[property.name];
                    // console.log(`\n propertyResult`, propertyResult);

                    if (data[property.name]) return (
                        <div key={property.name}>
                            <div className={`text-mm text-center`}>{property.label}:</div>
                            <div
                                className={`px-1 py-0.5 border border-pult/30 rounded-md bg-pult-hover/10`}>{propertyResult}</div>
                        </div>
                    );
                    else return null;
                })}
            </div>
        </div>);
    };
    const renderFields = (name, render_elements, comment, label, is_declined, reason) => {

        // if (is_declined) console.log(`\n is_declined`, is_declined, reason);

        return (
            <div key={name}>
                {render_elements}
                {(comment) && <div>
                    {(is_declined) ?
                        <div className={`max-w-[270px] mt-2`}>
                            <Warnings info>
                                <div className={`text-sm`}>Комментарий к отклонению:</div>
                                {reason}
                            </Warnings>
                        </div> :
                        <textarea
                            name={name}
                            onChange={(e) => reasonHandler(name, comment, e.target.value)}
                            className={`${inputsStyles.input} mt-1`}
                            placeholder={`Укажите причину отклонения ${label}`}
                        />
                    }
                </div>}
            </div>
        );
    }

    // if (subcompanyInfo) console.log(`\n subcompanyInfo`, subcompanyInfo);
    // if (subCompanyState) console.log(`\n subCompanyState`, subCompanyState);
    // if (subCompanyState) console.log(`\n subCompanyState?.id`,tender_id,  subCompanyState,  subCompanyState?.id);
    // if (contractDocuments) console.log(`\n contractDocuments`, contractDocuments);
    // if (contractActs) console.log(`\n contractActs`, contractActs);
    // if (reasons) console.log(`\n reason`, reasons);

    useEffect(() => {
        const getSignatories = async () => {
            const all = await axios.get(
                `${userSettings.api}/signatories/`,
                userSettings.headers.get
            );

            const onlyCompany = all.data.filter(sign => sign.company.id === subCompanyState?.main);
            setSignatories(onlyCompany);
        }

        getSignatories()
    }, [subCompanyState]);

    const makeDefault = async (id) => {
        const set = await axios.post(
            `${userSettings.api}/signatories/${id}/set_default/`,
            null,
            {headers: userSettings.headers.post}
        )

        const updatedSignatories = signatories.map((sign) => {
            if (sign.is_default) {
                return {...sign, is_default: false};
            } else if (sign.id === id) {
                return {...sign, is_default: true};
            } else {
                return sign;
            }
        });

        setSignatories(updatedSignatories);
    }

    const deleteSignatory = async (id) => {
        const del = await axios.delete(
            `${userSettings.api}/signatories/${id}/`,
            userSettings.headers.get
        )

        const filteredSignatories = signatories.filter(sign => sign.id !== id);
        setSignatories(filteredSignatories);
    }

    const addAgreementDocument = async (data) => {
        console.log(data);
        const formData = new FormData();
        formData.append('agreement_document', data.agreement_document[0]);

        try {
            const addAgreementDoc = await axios.post(`${userSettings.api}/subcompanies/${subCompanyState.subcompany}/companies/${subCompanyState.main}/projects/${project_id}/tenders/${subCompanyState.tender}/sign-agreement/`, formData, {headers: userSettings.headers.postForm});
            console.log(addAgreementDoc);
        } catch (e) {
            console.log("error: ", e)
        }
    };
    console.log(subCompanyState)

    return (
        <UserSection>
            <FormProvider {...methods}>
                <form>
                    <ModalMuiWrapper
                        open={popupShow}
                        onClose={() => setPopupShow(false)}
                        ariaLabel={"add-signatories"}
                        ariaDescribe={"add-subcompany-signatory"}
                    >
                        <AddSignatoryModal
                            isMain={true}
                            contract={subCompanyState}
                            signatories={signatories}
                            setSignatories={setSignatories}
                            setPopupShow={setPopupShow}
                            setAddedPerson={setAddedPerson}
                            setAddedSubcompanySign={setAddedSubcompanySign}
                        />
                    </ModalMuiWrapper>

                    <Snackbar
                        anchorOrigin={{vertical: "top", horizontal: "right"}}
                        open={addedPerson}
                        autoHideDuration={2000}
                        onClose={() => setAddedPerson(false)}
                    >
                        <Alert severity="success">
                            Подписант со стороны компании был добавлен
                        </Alert>
                    </Snackbar>

                    <Snackbar
                        anchorOrigin={{vertical: "top", horizontal: "right"}}
                        open={addedSubcompanySign}
                        autoHideDuration={6000}
                        onClose={() => setAddedSubcompanySign(false)}
                    >
                        <Alert severity="success">
                            Подписант подписал тендер, необходимо загрузить документ согласования
                        </Alert>
                    </Snackbar>

                    <h1>Принять информацию по подрядчику <b className={`text-pult`}>{subcompanyInfo?.name}</b></h1>
                    <Box>
                        Договор № <span className={`underline`}>{subCompanyState?.id}</span>
                    </Box>
                    {answers && answers}
                    {agreementTypes.map(type => {

                        const checkComplete = subCompanyState && subCompanyState[type.type];
                        const updateType = type.id !== 1 && type.type.replace('accepted', 'uploaded');
                        const checkInput = (!checkComplete && subCompanyState) && subCompanyState[updateType];

                        return (
                            <Box key={type.id}>
                                <h2 className={`text-xl`}>{type.label}</h2>
                                {(checkComplete || (checkInput && !type.data)) ?
                                    <Warnings correct>{type.complete_message}</Warnings> :
                                    (type.id !== 1 && !checkInput) ?  // (!checkInput || !type.data)
                                        <Warnings>{type.reason}</Warnings> :
                                        <Box>
                                            {/* не изменяемые */}
                                            <Box className={`flex gap-3 flex-wrap`}>
                                                {(type.id === 1 && type.data) &&
                                                    type.keys.filter(property => property.disabled).map(propName => {

                                                        return (renderInfo(propName, type.data, ownershipNames, type));
                                                    })
                                                }
                                            </Box>
                                            {/* управление */}
                                            <Box className={`flex flex-wrap gap-3`}>
                                                {(reasons[type.comment] && reasons[type.acceptHandler] && Object.keys(reasons[type.comment]).length > 0)
                                                    ? <Btn method={`cancel`} color={`red`}
                                                         onClick={type.rejectHandler}>Отклонить</Btn>
                                                    : <Btn method={`apply`} onClick={type.acceptHandler}>Принять все</Btn>
                                                }
                                            </Box>
                                            {/* изменяемые */}
                                            <Box className={`flex gap-3 flex-wrap`}>
                                                {type.data &&
                                                (type.id === 1) ?
                                                    type.keys
                                                        .filter(key => !key.disabled)
                                                        .map(key =>
                                                            renderFields(
                                                                key.name,
                                                                renderInfo(key, type.data, ownershipNames, type),
                                                                type.comment,
                                                                key.label
                                                            )
                                                        ) :
                                                    (type.id === 2 || type.id === 3) &&
                                                    type.data.map(doc => {

                                                        return (
                                                            renderFields(
                                                                doc.id,
                                                                renderDocs(doc, type),
                                                                type.comment,
                                                                doc?.reason,
                                                                doc?.is_declined,
                                                                doc?.reason,
                                                            )
                                                        )
                                                    })
                                                }
                                            </Box>
                                        </Box>
                                }
                            </Box>
                        );
                    })}

                    {subCompanyState?.is_acts_concerted && subCompanyState?.is_documents_concerted &&
                        <Box className='mt-2'>
                            <Button onClick={() => setPopupShow(true)} variant="contained">
                                Добавить подписанта компании
                            </Button>
                            <Box className='flex flex-col gap-2 mt-2'>
                                {signatories?.length > 0 && signatories.map(s =>
                                    <Box key={s.id} className='p-2 bg-blue-200 rounded-md flex flex-col'>
                                        <Typography>{s.full_name}</Typography>
                                        <Box className='flex justify-between'>
                                            <Button
                                                variant='contained'
                                                onClick={() => makeDefault(s.id)}
                                                disabled={s.is_default}
                                            >
                                                Сделать ответственным
                                            </Button>
                                            <Box className='bg-white rounded-md'>
                                                <IconButton
                                                    onClick={() => deleteSignatory(s.id)}
                                                    aria-label="delete"
                                                    size="large"
                                                    color='error'
                                                >
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    }

                    {subCompanyState?.company_signatory && !subCompanyState?.subcompany_signatory &&
                        <Alert>Необходимо добавить подписанта со стороны подрядчика</Alert>
                    }

                    {subCompanyState?.company_signatory && subCompanyState?.subcompany_signatory &&
                        <Box>
                            <Typography>Документ согласования</Typography>
                            <InputFile required={true} name="agreement_document" label='Документ согласования'/>
                            <Button onClick={handleSubmit(addAgreementDocument)} variant={'contained'}>Загрузить</Button>
                        </Box>
                    }
                </form>
            </FormProvider>
        </UserSection>
    );
}