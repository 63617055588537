import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Grids from "../../UI/Grids/Grids";
import styles from "../dash.module.css";
import Btn from "../../UI/Buttons/Btn";
import Popup from "../../UI/Popup/Popup";
import {ReactComponent as BuildingIcon} from "../../UI/Icons/bulding.svg";
import UserContext from "../../../store/user/user-context";
import axios from "axios";
import UserSection from "../../UI/Sections/UserSection";
import {PrintPhone} from "../../UI/Convertors/PrintPhone";
import Warnings from "../../UI/Sections/Warnings";

const ObjectInformation = (props) => {

    const role = window.localStorage.getItem('role');

    const navigate = useNavigate();
    if (
        role !== '2' &&
        role !== '4'
    ) navigate('/work/');

    const userSettings = useContext(UserContext);
    const company_id = window.localStorage.getItem('company');
    const project_id = window.localStorage.getItem('project');

    const [popupShow, setPopupShow] = useState(false);

    const [examination, setExamination] = useState();
    const [buildingPermit, setBuildingPermit] = useState();
    const [objectName, setObjectName] = useState();
    const [tepBuildingList, setTepBuildingList] = useState();
    const [objectInformationDetails, setObjectInformationDetails] = useState();
    const [constructionSupervisionInfo, setConstructionSupervisionInfo] = useState(null);

    const [constructionSupervisionError, setConstructionSupervisionError] = useState(null)

    // console.log(`\n constructionSupervisionInfo`, constructionSupervisionInfo);

    const examinationNumberHandler = () => {

        const saveExaminationNumber = (data) => {

            axios.post(
                `${userSettings.api}/examination-number/companies/${company_id}/projects/${project_id}`,
                data,
                {headers: {Authorization: userSettings.token}}
            ).then(response => {
                if (response.data.data) {
                    setExamination(response.data.data);
                    setPopupShow(false);
                }
            }).catch(error => {
                console.error(error);
            });
        }
        const defaultDate = examination.examination_number_date;
        let today = new Date();
        today.setDate(today.getDate() - 1)
        const fields = [
            {
                type: "text",
                name: "examination_number",
                label: "Введите номер",
                defaultValue: examination.examination_number
            },
            {
                type: "date",
                name: "examination_number_date",
                max: today.toISOString().split('T')[0],
                label: "Укажите дату",
                defaultValue: defaultDate ? defaultDate.split('T')[0] : ''
            }
        ]
        if (examination.examination_number) fields.push({
            type: "text",
            name: "examination_number_edit_reason",
            label: "Укажите причину изменений",
        });

        setPopupShow(<Popup
            title={!examination.examination_number ? "Добавить номер экспертизы" : "Редактировать номер экспертизы"}
            form={{
                fields: fields,
                submit: "Отправить"
            }}
            onSubmit={saveExaminationNumber}
            onCloseModal={() => setPopupShow(false)}
        />);
    }
    const buildingPermitNumberHandler = () => {

        const saveBuildingPermitNumber = (data) => {

            axios.post(
                `${userSettings.api}/building-permit-number/companies/${company_id}/projects/${project_id}`,
                data,
                {headers: {Authorization: userSettings.token}}
            ).then(response => {
                if (response.data.data) {
                    setBuildingPermit(response.data.data);
                    setPopupShow(false);
                }
            }).catch(error => {
                console.error(error);
            });
        }

        const defaultDate = buildingPermit.building_permit_number_date;
        const fields = [
            {
                type: "text",
                name: "building_permit_number",
                label: "Введите номер",
                defaultValue: buildingPermit.building_permit_number
            },
            {
                type: "text",
                name: "building_permit_number_authority",
                label: "Наименование органа исполнительной власти",
                defaultValue: buildingPermit.building_permit_number_authority
            },
            {
                type: "date",
                name: "building_permit_number_date",
                max: new Date().toISOString().split('T')[0],
                label: "Укажите дату",
                defaultValue: defaultDate ? defaultDate.split('T')[0] : ''
            }
        ]
        if (buildingPermit.building_permit_number) fields.push({
            type: "text",
            name: "building_permit_number_edit_reason",
            label: "Укажите причину изменений",
        });

        setPopupShow(<Popup
            title={!buildingPermit.building_permit_number ? "Добавить номер разрешения на строительство" : "Редактировать номер разрешения на строительство"}
            form={{
                fields: fields,
                submit: "Отправить"
            }}
            onSubmit={saveBuildingPermitNumber}
            onCloseModal={() => setPopupShow(false)}
        />);
    }
    const ObjectNameHandler = () => {

        const saveObjectName = (data) => {

            axios.post(
                `${userSettings.api}/object-name/companies/${company_id}/projects/${project_id}`,
                data,
                {headers: {Authorization: userSettings.token}}
            ).then(response => {
                if (response.data.data) {

                    setObjectName(response.data.data);
                    setPopupShow(false);
                }
            }).catch(error => {
                console.error(error);
            });
        }

        const fields = [
            {
                type: "text",
                name: "object_name",
                label: "Наименование объекта",
                defaultValue: objectName.object_name
            }
        ]
        if (objectName.object_name) fields.push({
            type: "text",
            name: "object_name_edit_reason",
            label: "Укажите причину изменений",
        });

        setPopupShow(<Popup
            title={!buildingPermit.building_permit_number ? "Добавить наименование объекта" : "Редактировать наименование объекта"}
            form={{
                fields: fields,
                submit: "Отправить"
            }}
            onSubmit={saveObjectName}
            onCloseModal={() => setPopupShow(false)}
        />);
    }
    const ObjectInformationDetailsHandler = (building_id) => {

        axios.get(`${userSettings.api}/tep-building/companies/${company_id}/projects/${project_id}/buildings/${building_id}/`, userSettings.headers.get)
            .then(response => {
                // console.log('\n ', response.data.data);

                setObjectInformationDetails(response.data.data);
            })
            .catch(error => console.log(`\n error`, (error.code === 'ERR_BAD_REQUEST') ? 'ERR_BAD_REQUEST' : error.data));
    };
    const editConstructionSupervisionHandler = (type) => {

        const fields = [
            {
                "name": "name",
                "type": "text",
                "label": "Наименование",
                "defaultValue": constructionSupervisionInfo && constructionSupervisionInfo.name
            },
            {
                "name": "legal_address",
                "type": "text",
                "label": "Юридический адрес",
                "defaultValue": constructionSupervisionInfo && constructionSupervisionInfo.legal_address
            },
            {
                "name": "postal_address",
                "type": "text",
                "label": "Почтовый адрес",
                "defaultValue": constructionSupervisionInfo && constructionSupervisionInfo.postal_address
            },
            {
                "name": "phone",
                "type": "tel",
                "label": "Телефон",
                "defaultValue": constructionSupervisionInfo && constructionSupervisionInfo.phone
            },
            {
                "name": "document",
                "type": "file",
                "label": "Приказ",
                "notRequired": true
            }
        ];
        const updateData = (data) => {
            setConstructionSupervisionInfo(data);
            setPopupShow(false);
        }
        const editConstructionSupervision = (data) => {

            const options = {
                headers: userSettings.headers.postForm
            }

            data['phone'] = data.phone.replace(/ /g, '');

            const formData = new FormData();
            Object.keys(data).forEach(k => {
                if (k.includes('document') && data[k][0] ) formData.append(k, data[k][0]);
                else if (!k.includes('document')) formData.append(k, data[k]);
            });

            if (type === 'edit') {
                axios.put(
                    `${userSettings.api}/construction-supervision/companies/${company_id}/projects/${project_id}`,
                    formData,
                    options
                ).then(response => {
                    if (response.data.data) updateData(response.data.data);
                })
                    .catch(error => console.log(`\n error`, (error.code === 'ERR_BAD_REQUEST') ? 'ERR_BAD_REQUEST' : error.data));
            } else {
                axios.post(
                    `${userSettings.api}/construction-supervision/companies/${company_id}/projects/${project_id}`,
                    formData,
                    options
                ).then(response => {
                    if (response.data.data) updateData(response.data.data);
                })
                    .catch(error => console.log(`\n error`, (error.code === 'ERR_BAD_REQUEST') ? 'ERR_BAD_REQUEST' : error.data));
            }
        }

        setPopupShow(<Popup
            title={`${type === 'edit' ? "Редактировать" : "Добавить"} сведения о государственном строительном надзоре`}
            form={{
                fields: fields,
                submit: "Сохранить"
            }}
            onSubmit={editConstructionSupervision}
            onCloseModal={() => setPopupShow(false)}
        />);
    };
    const AddObjectHandler = () => {

        const AddObject = (data) => {

            axios.post(
                `${userSettings.api}/tep-building/companies/${company_id}/projects/${project_id}/buildings/`,
                data,
                {headers: {Authorization: userSettings.token}}
            ).then(response => {

                console.log(`\n `, response.data.data);

                const updatedList = [...tepBuildingList, response.data.data];
                setTepBuildingList(updatedList);
                setPopupShow(false)
            }).catch(error => {
                console.error(error);
            });
        }

        setPopupShow(<Popup
            title="Добавить корпус"
            addBuilding={true}
            onCloseModal={() => setPopupShow(false)}
            onSubmit={AddObject}
        />);
    }
    const sections = [
        {
            label: 'Номер экспертизы',
            data: examination?.examination_number,
            date: examination?.examination_number_date && new Date(examination.examination_number_date).toLocaleDateString(),
            editReason: examination?.examination_number_edit_reason,
            updated_at: examination?.updated_at,
            handler: examinationNumberHandler,
        },
        {
            label: 'Номер разрешения на строительство',
            data: buildingPermit?.building_permit_number,
            date: buildingPermit?.building_permit_number_date && new Date(buildingPermit.building_permit_number_date).toLocaleDateString(),
            authority: buildingPermit?.building_permit_number_authority,
            editReason: buildingPermit?.building_permit_number_edit_reason,
            updated_at: buildingPermit?.updated_at,
            handler: buildingPermitNumberHandler,
        },
        {
            label: 'Наименование объекта',
            data: objectName?.object_name,
            editReason: objectName?.object_name_edit_reason,
            updated_at: objectName?.updated_at,
            handler: ObjectNameHandler,
        },
        {
            label: 'Сведения о государственном строительном надзоре',
            name: constructionSupervisionInfo?.name,
            legal_address: constructionSupervisionInfo?.legal_address,
            postal_address: constructionSupervisionInfo?.postal_address,
            phone: constructionSupervisionInfo?.phone,
            document: constructionSupervisionInfo?.document,
            editReason: constructionSupervisionInfo?.object_name_edit_reason,
            updated_at: constructionSupervisionInfo?.updated_at,
            handler: () => editConstructionSupervisionHandler(constructionSupervisionInfo ? 'edit' : 'add'),
        },
    ];

    useEffect(() => {

        const options = {headers: {Authorization: userSettings.token}};

        axios.get(`${userSettings.api}/examination-number/companies/${company_id}/projects/${project_id}`, options)
            .then(response => setExamination(response.data.data))
            .catch(error => console.log('\n error', error));

        axios.get(`${userSettings.api}/building-permit-number/companies/${company_id}/projects/${project_id}`, options)
            .then(response => setBuildingPermit(response.data.data))
            .catch(error => console.log('\n error', error));

        axios.get(`${userSettings.api}/object-name/companies/${company_id}/projects/${project_id}`, options)
            .then(response => setObjectName(response.data.data))
            .catch(error => console.log('\n error', error));

        axios.get(`${userSettings.api}/tep-building/companies/${company_id}/projects/${project_id}/buildings/`, options)
            .then(response => setTepBuildingList(response.data.data))
            .catch(error => console.log('\n error', error.response.data));

        axios.get(`${userSettings.api}/construction-supervision/companies/${company_id}/projects/${project_id}`, userSettings.headers.get)
            .then(response => setConstructionSupervisionInfo(response.data.data))
            .catch(error => {
                if (!project_id) return;
                console.log(`\n error`, (error.code === 'ERR_BAD_REQUEST') ? 'ERR_BAD_REQUEST' : error.data)
                setConstructionSupervisionError(error?.response?.data?.errors
                    ? error.response.data.errors[0].detail
                    : ""
                );
            });
    }, [company_id, project_id, userSettings]);
    useEffect(() => {

        const editObjectDetails = (data) => {

            const filteredData = Object.entries(data)
                .filter(([key, value]) => value !== "")
                .reduce((obj, [key, value]) => {
                    obj[key] = value;
                    return obj;
                }, {});

            console.log(`\n filteredData`, filteredData);

            axios.put(
                `${userSettings.api}/tep-building/companies/${company_id}/projects/${project_id}/buildings/${objectInformationDetails.id}/`,
                filteredData,
                {headers: userSettings.headers.post}
            )
                .then(response => {
                    if (response.data.data) {

                        // console.log(`\n `, response.data.data);

                        setPopupShow(false);

                        setObjectInformationDetails(response.data.data);
                    }
                })
                .catch(error => console.log(`\n error`, (error.code === 'ERR_BAD_REQUEST') ? 'ERR_BAD_REQUEST' : error.data));
        };

        if (objectInformationDetails) setPopupShow(<Popup
            buildingInfo={objectInformationDetails}
            editable={role === '2'}
            onCloseModal={() => setPopupShow(false)}
            onSubmit={editObjectDetails}
        />);

    }, [objectInformationDetails, company_id, project_id, userSettings, role]);

    return (
        <UserSection>
            {popupShow}
            <h1>Информация по объекту</h1>
            <div>
                <Grids cols={4}>
                    {sections.map((section, index) => (<div className={styles.card} key={index}>
                        <div className={styles.label}>{section.label}</div>
                        {(section.data || section.name) ? <React.Fragment>
                                <div className={styles.area}>
                                    {section.data && (<React.Fragment>
                                        {index === 2 ? <div>Наименование :</div> : <div>Номер:</div>}
                                        <div className={styles.data}>{section.data}</div>
                                    </React.Fragment>)}
                                    {section.name && (<React.Fragment>
                                        <div>Наименование:</div>
                                        <div className={styles.data}>{section.name}</div>
                                    </React.Fragment>)}
                                    {section.date && (<React.Fragment>
                                        <div>Дата:</div>
                                        <div className={styles.data}>{section.date}</div>
                                    </React.Fragment>)}
                                    {section.authority && (<React.Fragment>
                                        <div>Наименование органа исполнительной власти:</div>
                                        <div className={styles.data}>{section.authority}</div>
                                    </React.Fragment>)}
                                    {section.legal_address && (<React.Fragment>
                                        <div>Юридический адрес:</div>
                                        <div className={styles.data}>{section.legal_address}</div>
                                    </React.Fragment>)}
                                    {section.postal_address && (<React.Fragment>
                                        <div>Почтовый адрес:</div>
                                        <div className={styles.data}>{section.postal_address}</div>
                                    </React.Fragment>)}
                                    {section.phone && (<React.Fragment>
                                        <div>Телефон:</div>
                                        <div className={styles.data}>{PrintPhone(section.phone)}</div>
                                    </React.Fragment>)}
                                    {section.document && <Btn method={"view"} color={`button`} center>
                                        <Link to={`${userSettings.fileServer}${section.document}`} target={"_blank"}>Скачать
                                            приказ</Link>
                                    </Btn>}
                                    {section.editReason && (<React.Fragment>
                                        <div>Причина изменения:</div>
                                        <div className={styles.data}>{section.editReason}</div>
                                    </React.Fragment>)}
                                    {section.updated_at && (<React.Fragment>
                                        <div>Дата обновления:</div>
                                        <div
                                            className={styles.data}>{new Date(section.updated_at).toLocaleDateString()}</div>
                                    </React.Fragment>)}
                                </div>
                                {role === '2' && (
                                    <Btn
                                        color="button"
                                        method="edit"
                                        className={`mx-auto`}
                                        onClick={() => section.handler(section)}>Редактировать</Btn>
                                )}
                            </React.Fragment>
                            :
                            (role === '2') && <React.Fragment>
                                <div className={styles.area}></div>
                                <Btn
                                    color="button"
                                    method="add"
                                    className={`mx-auto`}
                                    onClick={section.handler}>Добавить</Btn>
                            </React.Fragment>
                        }
                    </div>))}
                </Grids>

                {constructionSupervisionError &&
                    <div className='w-fit'>
                        <Warnings>{constructionSupervisionError}</Warnings>
                    </div>
                }

                <div>ТЭП объекта</div>
                {tepBuildingList ?
                    <React.Fragment>
                        <div>Список корпусов:</div>
                        <div className={`flex flex-wrap`}>
                            {tepBuildingList.map(building =>
                                <Btn
                                    key={building.id}
                                    className={`mx-1`}
                                    color="button"
                                    icon={<BuildingIcon/>}
                                    onClick={() => ObjectInformationDetailsHandler(building.id)}
                                >{building.name}</Btn>
                            )}
                        </div>
                    </React.Fragment>
                    :
                    <div className={`text-zinc-400`}>Нет добавленных корпусов</div>
                }
                {role === '2' && <Btn method="add" color="button" onClick={AddObjectHandler}>Добавить корпус</Btn>}
            </div>
        </UserSection>
    );
}

export default ObjectInformation;