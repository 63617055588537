import {Document, Page,Font} from "@react-pdf/renderer";
import Html from "react-pdf-html";
import {rubles} from "rubles";

Font.register({
    family: "Roboto",
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"
});

const Bill = (props) => {

    return (
        <Document>
        <Page>
            <Html>{`<html>
                            <body>
                                <style>
                                    body {
                                        font-family : "Roboto";
                                        font-size: 10pt;
                                    }
                                    .row {
                                        display: flex;
                                        flex-direction: row;
                                    }
                                    .row-title {
                                        font-size: 14pt;
                                        font-weight: bold;
                                        text-align: center;
                                    }
                                    .cell {
                                        padding: 2pt;
                                    }
                                    .cell-b {
                                        padding: 2pt;
                                        margin: 0;
                                        font-weight: bold;
                                    }
                                    .bordered {
                                        border-left: 1pt solid #000; 
                                        border-top: 1pt solid #000;
                                    }
                                    .bordered-end {
                                        border-left: 1pt solid #000; 
                                        border-right: 1pt solid #000; 
                                        border-top: 1pt solid #000;
                                    }
                                    .bordered-x {
                                        border-left: 1pt solid #000;
                                    }
                                    .bordered-xb {
                                        border-left: 1pt solid #000;
                                        border-bottom: 1pt solid #000;
                                    }
                                    .bordered-x-end {
                                        border-left: 1pt solid #000;
                                        border-right: 1pt solid #000;
                                    }
                                    .bordered-xb-end {
                                        border-left: 1pt solid #000;
                                        border-right: 1pt solid #000;
                                        border-bottom: 1pt solid #000;
                                    }
                                    .bordered-xy {
                                        border: 1pt solid #000;
                                    }
                                    .bordered-b {
                                        border-left: 1pt solid #000;
                                        border-top: 1pt solid #000;
                                        border-bottom: 1pt solid #000;
                                    }
                                    .col-10 {width: 10mm;}                            
                                    .col-15 {width: 15mm;}                            
                                    .col-25 {width: 25mm;}                            
                                    .col-30 {width: 30mm;}                            
                                    .col-40 {width: 40mm;}                            
                                    .col-60 {width: 60mm;}                            
                                    .col-170 {width: 170mm;}                            
                                    .col-grow {flex-grow: 1;}
                                    .text-r {text-align: right;}
                                    .summ-cuirsive {text-transform: uppercase;}
                                </style>
<div class="row">&nbsp;</div>
    <div class="row">
            <div class="cell bordered col-grow">` + props.bank_name + `</div>
            <div class="cell bordered col-25">БИК</div>
            <div class="cell bordered-end col-60">` + props.bik + `</div>
        </div>
        <div class="row">
            <div class="cell bordered col-grow">Банк получателя</div>
            <div class="cell bordered col-25">Кор.сч. №</div>
            <div class="cell bordered-end col-60">` + props.ks + `</div>
        </div>
        <div class="row">
            <div class="cell bordered col-15">ИНН</div>
            <div class="cell bordered col-grow">` + props.inn + `</div>
            <div class="cell bordered col-15">КПП</div>
            <div class="cell bordered col-grow">` + props.kpp + `</div>
            <div class="cell bordered col-25">Сч. №</div>
            <div class="cell bordered-end col-60">` + props.bank_account + `</div>
        </div>
        <div class="row">
            <div class="cell bordered col-grow">` + props.company_name + `</div>
            <div class="cell bordered-x col-25">&nbsp;</div>
            <div class="cell bordered-x-end col-60">&nbsp;</div>
        </div>
        <div class="row">
            <div class="cell bordered-b col-grow">Получатель</div>
            <div class="cell bordered-xb col-25">&nbsp;</div>
            <div class="cell bordered-xb-end col-60">&nbsp;</div>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row-title">Счет на оплату № ` + props.billNumber + ` от ` + props.billDate + `</div>
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="cell col-30">Поставщик:</div>
            <div class="cell col-170">` + props.company_name + ` ИНН: ` + props.inn + `</div>
        </div>
        <div class="row">
            <div class="cell col-30">Покупатель:</div>
            <div class="cell col-170">` + props.buyer + `</div>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="cell-b bordered col-10">№</div>
            <div class="cell-b bordered col-grow">Товары (работы, услуги)</div>
            <div class="cell-b bordered col-15">Кол-во</div>
            <div class="cell-b bordered col-15">Ед.</div>
            <div class="cell-b bordered col-40">Цена</div>
            <div class="cell-b bordered-end col-40">Сумма</div>
        </div>
        <div class="row">
            <div class="cell bordered-b col-10">1</div>
            <div class="cell bordered-b col-grow">Оплата тарифа компании ` + props.tariffName + `</div>
            <div class="cell bordered-b col-15 text-r">1</div>
            <div class="cell bordered-b col-15">шт.</div>
            <div class="cell bordered-b col-40 text-r">` + props.tariffPriceCost + `</div>
            <div class="cell bordered-xy col-40 text-r">` + props.tariffPriceCost + `</div>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="col-grow">&nbsp;</div>
            <div class="cell col-40 text-r">Итого:</div>
            <div class="cell col-40 text-r">` + props.tariffPriceCost + `</div>
        </div>
        <div class="row">
            <div class="col-grow">&nbsp;</div>
            <div class="cell col-40 text-r">Без налога (НДС):</div>
            <div class="cell col-40 text-r">&nbsp;</div>
        </div>
        <div class="row">
            <div class="col-grow">&nbsp;</div>
            <div class="cell col-40 text-r">Всего к оплате:</div>
            <div class="cell col-40 text-r">` + props.tariffPriceCost + `</div>
        </div>
        <div class="row">&nbsp;</div>
        <div class="row">
            <div class="cell">Всего наименований 1, на сумму ` + props.tariffPriceCost + ` RUB</div>
        </div>
        <div class="row">
            <div class="cell">`+rubles(props.tariffCost)+`</div>
        </div>
        </body>
        </html>`}</Html>
        </Page>
    </Document>
    )
}

export default Bill;