export const getMinMaxDates = (allDates, justNumeric) => {
    if (allDates.length === 0) {
        return {
            workStart: "нет информации",
            workEnd: "нет информации",
        };
    }

    // const testDates = ['2024-02-01', '2024-09-02', '2024-05-01', '2022-05-02']
    // const allTestDates = testDates.map(dateString => new Date(convertDateString(dateString)));
    // const minDateTest = new Date(Math.min(...allTestDates));
    // const maxDateTest = new Date(Math.max(...allTestDates));
    // console.log(minDateTest, maxDateTest)
    const dates = allDates.map(dateString => new Date(convertDateString(dateString)));

    const minDate = new Date(Math.min(...dates));
    const maxDate = new Date(Math.max(...dates));

    if (justNumeric) return {

        minDate: `${String(minDate.getDate()).padStart(2, '0')}.${String(minDate.getMonth() + 1).padStart(2, '0')}.${minDate.getFullYear()}`,
        maxDate: `${String(maxDate.getDate()).padStart(2, '0')}.${String(maxDate.getMonth() + 1).padStart(2, '0')}.${maxDate.getFullYear()}`,
    }

    return {
        minDate: formatDate(minDate),
        maxDate: formatDate(maxDate)
    };
}

function convertDateString(date) {
    const [year, day, month] = date.split('-');
    return `${day}-${month}-${year}`;
}

function formatDate(date) {
    const new_date = new Date(date);
    return new Intl.DateTimeFormat('ru-RU', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    }).format(new_date);
}