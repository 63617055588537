import styles from "./btn.module.css";
import { ReactComponent as Add } from "../Icons/addition.svg"
import { ReactComponent as Remove } from "../Icons/remove.svg"
import { ReactComponent as Cancel } from "../Icons/cancel.svg"
import { ReactComponent as Edit } from "../Icons/edit.svg"
import { ReactComponent as View } from "../Icons/view.svg"
import { ReactComponent as Back } from "../Icons/back.svg"
import { ReactComponent as Clone } from "../Icons/clone.svg"
import { ReactComponent as Save } from "../Icons/save.svg"
import { ReactComponent as Apply } from "../Icons/apply.svg"
import { ReactComponent as Info } from "../Icons/info.svg"
import { ReactComponent as Lock } from "../Icons/Administrator/accessRejected.svg"
import { ReactComponent as Download } from "../Icons/download.svg"
import {ReactComponent as XlsxIcon} from "../Icons/xlsx.svg";
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';

const Btn = (props) => {

    const titleAttribute = props.title ? { title: props.title } : {};

    return (
        <div
            className={`
                ${styles.btn}
                ${styles[props.color] ? styles[props.color] : ''}
                ${((props.method || props.icon) || !props.method === "file") ? styles.icon : ''}
                ${props.icononly ? styles['icon-only'] : ''}
                ${props.className ? props.className : ''}
                ${props.stroke ?  styles.stroke : ''}
                ${props.method === "apply" ? styles.apply : ''}
                ${props.method === "remove" ? styles.red : ''}
                ${props.method === "disable" ? styles.disable : ''}
                ${props.full ? styles.full : ''}
                ${props.center ? styles.center : ''}
                ${props.link ? styles.link : ''}
                ${props.transparent ? styles.transparent : ''}
            `}
            onClick={props.onClick}
            id={props.id}
            {...titleAttribute}
        >
            {props.method === "add" && <Add />}
            {props.method === "remove" && <Remove />}
            {props.method === "cancel" && <Cancel />}
            {props.method === "back" && <Back />}
            {props.method === "edit" && <Edit />}
            {props.method === "view" && <View />}
            {props.method === "clone" && <Clone />}
            {props.method === "save" && <Save />}
            {props.method === "apply" && <Apply />}
            {props.method === "lock" && <Lock />}
            {props.method === "info" && <Info />}
            {props.method === "download" && <Download />}
            {props.method === "xls" && <XlsxIcon />}
            {props.method === "tariff" && <LocalOfferOutlinedIcon sx={{scale: "0.8"}} />}
            {props.user &&
                <svg
                    viewBox="0 0 512 512"
                >
                    <path d="M399 384.2c-22.1-38.4-63.6-64.2-111-64.2h-64c-47.4 0-88.9 25.8-111 64.2 35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM512 256c0 141.4-114.6 256-256 256S0 397.4 0 256 114.6 0 256 0s256 114.6 256 256zm-256 16c39.8 0 72-32.2 72-72s-32.2-72-72-72-72 32.2-72 72 32.2 72 72 72z"/>
                </svg>
            }
            {props.file === "pdf" &&
                <svg
                    viewBox="0 0 384 512"
                    className={`fill-white p-0.5`}
                >
                    <path d="M64 0C28.7 0 0 28.7 0 64v384c0 35.3 28.7 64 64 64h256c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zm192 0v128h128L256 0zM64 224h24c30.9 0 56 25.1 56 56s-25.1 56-56 56h-8v32c0 8.8-7.2 16-16 16s-16-7.2-16-16V240c0-8.8 7.2-16 16-16zm24 80c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8v48h8zm72-64c0-8.8 7.2-16 16-16h24c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48h-24c-8.8 0-16-7.2-16-16V240zm32 112h8c8.8 0 16-7.2 16-16v-64c0-8.8-7.2-16-16-16h-8v96zm96-128h48c8.8 0 16 7.2 16 16s-7.2 16-16 16h-32v32h32c8.8 0 16 7.2 16 16s-7.2 16-16 16h-32v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V240c0-8.8 7.2-16 16-16z" />
                </svg>
            }
            {props.method === "move" &&
                <svg
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    className={`stroke-white p-0.5`}
                >
                    <path d="M5 9l-3 3 3 3M9 5l3-3 3 3M15 19l-3 3-3-3M19 9l3 3-3 3M2 12h20M12 2v20" />
                </svg>
            }
            {props.icon && props.icon}
            {props.children}
        </div>
    );
}

export default Btn;