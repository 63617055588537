import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import UserContext from "../../../store/user/user-context";
import UserSection from "../../UI/Sections/UserSection";
import styles from "../dash.module.css";
import Btn from "../../UI/Buttons/Btn";
import Grids from "../../UI/Grids/Grids";
import Alink from "../../UI/Buttons/Alink";
import Popup from "../../UI/Popup/Popup";

const ConstructionSupervision = (props) => { 
    
    const navigate = useNavigate();
    if (window.localStorage.getItem('role') !== '2') navigate('/work/');

    const userSettings = useContext(UserContext);
    const company_id = window.localStorage.getItem('company');
    const project_id = window.localStorage.getItem('project');

    const [constructionSupervisionInfo, setConstructionSupervisionInfo] = useState();
    useEffect(() => {
        
        axios.get(`${userSettings.api}/construction-supervision/companies/${company_id}/projects/${project_id}`, userSettings.headers.get)
            .then(response => setConstructionSupervisionInfo(response.data.data))
            .catch(error => console.log(`\n error`, (error.code === 'ERR_BAD_REQUEST') ? 'ERR_BAD_REQUEST' : error.data));
    }, [company_id, project_id, userSettings]);

    const [popupShow, setPopupShow] = useState(false);

    const editConstructionSupervisionHandler = (type) => {

        const fields = [
            {
                "name": "name",
                "type": "text",
                "label": "Наименование",
                "defaultValue": constructionSupervisionInfo && constructionSupervisionInfo.name
            },
            {
                "name": "legal_address",
                "type": "text",
                "label": "Юридический адрес",
                "defaultValue": constructionSupervisionInfo && constructionSupervisionInfo.legal_address
            },
            {
                "name": "postal_address",
                "type": "text",
                "label": "Почтовый адрес",
                "defaultValue": constructionSupervisionInfo && constructionSupervisionInfo.postal_address
            },
            {
                "name": "phone",
                "type": "tel",
                "label": "Телефон",
                "defaultValue": constructionSupervisionInfo && constructionSupervisionInfo.phone
            },
            {
                "name": "document",
                "type": "file",
                "label": "Приказ"
            }
        ];
        const updateData = (data) => {
            setConstructionSupervisionInfo(data);
            setPopupShow(false);
        }
        const editConstructionSupervision = (data) => {

            const options = {
                headers: userSettings.headers.postForm
            }

            data['phone'] = data.phone.replace(/ /g, '');

            const formData = new FormData();
            Object.keys(data).forEach(k => {
                if (k.includes('document')) formData.append(k, data[k][0]);
                else formData.append(k, data[k]);
            });

            if (type === 'edit') {
                axios.put(
                    `${userSettings.api}/construction-supervision/companies/${company_id}/projects/${project_id}`,
                    formData,
                    options
                ).then(response => {
                    if (response.data.data) updateData(response.data.data);
                })
                    .catch(error => console.log(`\n error`, (error.code === 'ERR_BAD_REQUEST') ? 'ERR_BAD_REQUEST' : error.data));
            } else {
                axios.post(
                    `${userSettings.api}/construction-supervision/companies/${company_id}/projects/${project_id}`,
                    formData,
                    options
                ).then(response => {
                    if (response.data.data) updateData(response.data.data);
                })
                    .catch(error => console.log(`\n error`, (error.code === 'ERR_BAD_REQUEST') ? 'ERR_BAD_REQUEST' : error.data));
            }
        }
        
        setPopupShow(<Popup
            title={`${type === 'edit' ? "Редактировать" : "Добавить"} сведения о государственном строительном надзоре`}
            form={{
                fields: fields,
                submit: "Сохранить"
            }}
            onSubmit={editConstructionSupervision}
            onCloseModal={() => setPopupShow(false)}
        />);
    };

    return (
        <UserSection>
            {popupShow}
            <h1>Сведения о государственном строительном надзоре</h1>
            <Grids cols={2}>
                {constructionSupervisionInfo ?
                    <div className={styles.card}>
                        <div className={styles.area}>
                            <div>
                                <div>Наименование:</div>
                                <div className={styles.data}>{constructionSupervisionInfo.name}</div>
                            </div>
                            <div>
                                <div>Юридический адрес:</div>
                                <div className={styles.data}>{constructionSupervisionInfo.legal_address}</div>
                            </div>
                            <div>
                                <div>Почтовый адрес:</div>
                                <div className={styles.data}>{constructionSupervisionInfo.postal_address}</div>
                            </div>
                            <div>
                                <div>Телефон:</div>
                                <div className={styles.data}>{constructionSupervisionInfo.phone}</div>
                            </div>
                            <div className={`flex flex-wrap items-center gap-2`}>
                                <div><Alink href={`https://erp-system.sixhands.co${constructionSupervisionInfo.document}`} target="_blank">Скачать приказ</Alink></div>
                                <Btn color="button" method="edit" onClick={() => editConstructionSupervisionHandler('edit')}>Редактировать</Btn>
                            </div>
                        </div>
                    </div>
                    :
                    <Btn color="button" method="add" className={`mx-auto`} onClick={() => editConstructionSupervisionHandler('add')}>Добавить</Btn>
                }
            </Grids>
        </UserSection>
    );
}

export default ConstructionSupervision;