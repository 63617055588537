import React, {useEffect} from 'react';
import KS2 from "../../../UI/PdfDocuments/KS-2";
import {pdf, PDFDownloadLink} from "@react-pdf/renderer";
import {useSelectPerson} from "./hooks/useSelectPerson";
import Select from "../../../UI/Select/Select";
import useFetchedInfo from "./hooks/useFetchedInfo";
import {Box, Button, Typography} from "@mui/material";

const GenerateKS2PDF = ({setKs2}) => {
    const subcompany_id = +window.localStorage.getItem('company');
    const tender_id = +window.localStorage.getItem('tender');

    const {fetchedInfo, isLoading} = useFetchedInfo(subcompany_id, tender_id);

    const {
        customerState,
        handleCustomerOnChange,
        isSelectedPersons
    } = useSelectPerson(fetchedInfo?.personsList);

    useEffect(() => {
        if (!isSelectedPersons) return;

        const blob = pdf((<KS2
            fetchedInfo={fetchedInfo}
            customerState={customerState}
        />)).toBlob().then(blob => setKs2(new File([blob], "KS-2.pdf")))
    }, [isSelectedPersons]);

    return (
        <Box>
            <Typography variant="h1">КС-2:</Typography>
            {!isLoading && fetchedInfo &&
                <Box className='space-y-2 m-3 p-3 bg-[#e4e4e7] rounded-lg'>
                    <p className='text-xl'>Застройщик (Генподрядчик):</p>
                    <Select
                        not_combined={true}
                        name='customer_id'
                        handleOnChange={handleCustomerOnChange}
                        options={fetchedInfo.personsList}
                    />
                    {isSelectedPersons &&
                        <Box>
                            <PDFDownloadLink
                                document={
                                    <KS2
                                        fetchedInfo={fetchedInfo}
                                        customerState={customerState}
                                    />}
                                fileName={'KS-2'}

                            >
                                <Button variant="contained">Готово к загрузке!</Button>
                            </PDFDownloadLink>

                            {/*<PDFViewer style={{width: '100%', height: '100%'}}>*/}
                            {/*    <KS2*/}
                            {/*        fetchedInfo={fetchedInfo}*/}
                            {/*        customerState={customerState}*/}
                            {/*    />*/}
                            {/*</PDFViewer>*/}
                        </Box>
                    }
                </Box>
            }
        </Box>
    )
};

export default GenerateKS2PDF;
