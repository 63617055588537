import axios from "axios";

export const getSubcompanies = async (userSettings, company_id, project_id) => {

    try {
        const allSubcompanies = await axios.get(
            `${userSettings.api}/company/${company_id}/subcompanies-to-accept`,
            userSettings.headers.get
        );
        // console.log('\n allSubcompanies', allSubcompanies.data);

        const getCompaniesInfo = allSubcompanies.data.map(async (company) => {

            const response = await axios.get(
                `${userSettings.api}/company/${company.subcompany}/`,
                userSettings.headers.get
            )

            return (response.data);
        });
        const allCompaniesInfo = await Promise.all(getCompaniesInfo);
        // console.log('\n allCompaniesInfo', allCompaniesInfo);

        const allTenders = await axios.get(
            `${userSettings.api}/tenders/companies/${company_id}/`,
            userSettings.headers.get
        );
        const tenders = allTenders.data.filter(t => t.project.id === +project_id);
        // console.log('\n tenders', tenders, allTenders.data, +project_id);

        const subcompanies = allSubcompanies.data
            .map(subcompany => {

                // console.log('\n info', allCompaniesInfo, subcompany.subcompany);

                const tender = tenders.find(tender => tender.id === subcompany.tender);
                const info = allCompaniesInfo.find(c => c.id === subcompany.subcompany);

                return (tender && {
                    ...subcompany,
                    tender,
                    info
                })
            })
            .filter(s => s)
        ;
        // console.log('\n subcompanies', subcompanies);

        if (tenders?.length > 0 && subcompanies?.length > 0) return (subcompanies);
    }
    catch (error) {
        console.log('\n error getSubcompanies', error.response);
    }
};

export const getTender = async (userSettings, subcompany_id) => {

    try {

        const allTenders = await axios.get(
            `${userSettings.api}/subcompanies/${subcompany_id}/won-tender-lots`,
            userSettings.headers.get
        );
        // console.log('\n getContract', allTenders.data);
        return (allTenders.data);
    }
    catch (error) {
        console.log('\n error getTender', error.response);
    }
}