import React, {useContext, useEffect, useState} from "react";
import Btn from "../../UI/Buttons/Btn";
import Warnings from "../../UI/Sections/Warnings";
import {Link} from "react-router-dom";
import {ReactComponent as Project} from "../../UI/Icons/project.svg"
import {Alert, Box, Button, IconButton, Snackbar} from "@mui/material";
import AddSignatoryModal from "./AddSignatoryModal";
import UserContext from "../../../store/user/user-context";
import axios from "axios";
import ModalMuiWrapper from "../common/components/ModalMUIWrapper";

export default function TendersDetail(
    {
        project,
        contractInfo,
        getAccessCheck,
        coordinatorAccepted,
        contractDocumentsUploaded,
        contractDocumentsAccepted,
        contractActsUploaded,
        contractActsAccepted,
        subcompanySignatory,
        companySignatory,
        subcompany_id,
        contract,
        tender,
    }
) {
    const userSettings = useContext(UserContext);
    const [isDetailed, setIsDetailed] = useState(false);

    const [popupShow, setPopupShow] = useState(false);
    const [signatories, setSignatories] = useState(null);

    const [addedPerson, setAddedPerson] = useState(false);
    const [addedSubcompanySign, setAddedSubcompanySign] = useState(false);
    // console.log(signatories)
    // console.log(`\n tender`,tender.tender.name,);

    const contractSelect = () => {

        window.localStorage.setItem('company', contract.subcompany);
        window.localStorage.setItem('tender', contract.tender);
        window.localStorage.setItem('role', 6);

        window.location.replace('/work/subcompany/welcome/')
    }

    useEffect(() => {
        const getSignatories = async () => {
            const all = await axios.get(
                `${userSettings.api}/signatories/`,
                userSettings.headers.get
            );

            const onlySubcompany = all.data.filter(sign => sign.company.id === contract.subcompany);
            setSignatories(onlySubcompany);
        }

        getSignatories()
    }, []);

    // const makeDefault = async (id) => {
    //     const set = await axios.post(
    //         `${userSettings.api}/signatories/${id}/set_default/`,
    //         null,
    //         {headers: userSettings.headers.post}
    //     )
    //
    //     const updatedSignatories = signatories.map((sign) => {
    //         if (sign.is_default) {
    //             return {...sign, is_default: false};
    //         } else if (sign.id === id) {
    //             return {...sign, is_default: true};
    //         } else {
    //             return sign;
    //         }
    //     });
    //
    //     setSignatories(updatedSignatories);
    // }
    //
    // const deleteSignatory = async (id) => {
    //     const del = await axios.delete(
    //         `${userSettings.api}/signatories/${id}/`,
    //         userSettings.headers.get
    //     )
    //
    //     const filteredSignatories = signatories.filter(sign => sign.id !== id);
    //     setSignatories(filteredSignatories);
    // }

    return (
        <React.Fragment>
            <ModalMuiWrapper
                open={popupShow}
                onClose={() => setPopupShow(false)}
                ariaLabel={"add-signatories"}
                ariaDescribe={"add-subcompany-signatory"}
            >
                <AddSignatoryModal
                    setAddedPerson={setAddedPerson}
                    setAddedSubcompanySign={setAddedSubcompanySign}
                    contract={contract}
                    signatories={signatories}
                    setSignatories={setSignatories}
                    setPopupShow={setPopupShow}
                />
            </ModalMuiWrapper>

            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "right"}}
                open={addedPerson}
                autoHideDuration={2000}
                onClose={() => setAddedPerson(false)}
            >
                <Alert severity="success">
                    Подписант со стороны подрядчика подрядчика был добавлен
                </Alert>
            </Snackbar>

            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "right"}}
                open={addedSubcompanySign}
                autoHideDuration={6000}
                onClose={() => setAddedSubcompanySign(false)}
            >
                <Alert severity="success">
                    Подписант подписал тендер, необходима проверка и подтверждение со стороны компании
                </Alert>
            </Snackbar>

            <h2 className={`text-center`}>{project.company.name}</h2>
            <Btn center method={isDetailed ? 'back' : `view`} color={`button`}
                 onClick={() => setIsDetailed(!isDetailed)}>
                {isDetailed ? 'Скрыть детали' : "Детали договора"}
            </Btn>
            {isDetailed &&
                <Box className="flex flex-col space-y-2">
                    <Alert severity="info">
                        {contractInfo.map((c, id) =>
                            <div key={id}>
                                {c.label && <div>{c.label}</div>}
                                <div className={c.class ? c.class : `text-mm mb-1`}>
                                    {c.value}
                                </div>
                            </div>
                        )}
                    </Alert>
                    {
                        <Box className="flex flex-col space-y-2">
                            {coordinatorAccepted
                                ? <Alert severity="success">Данные согласованы, договор по контракту прикреплён</Alert>
                                :
                                <Alert severity="warning">Координатор не загрузил договор по текущему контракту</Alert>
                            }
                            {contractDocumentsAccepted
                                ? <Alert severity="success">
                                    Документы согласованы
                                    <Button variant="contained" className={`w-full`}>
                                        <Link
                                            to={`subcompany/upload-registration-docs/?subcompany=${subcompany_id}&tender=${contract.tender}`}>
                                            Показать документы
                                        </Link>
                                    </Button>
                                </Alert>
                                : <Alert severity="warning">
                                    <Box>
                                        <div>Документы на согласовании</div>
                                        <Button variant="contained" className={`w-full`}>
                                            <Link
                                                to={`subcompany/upload-registration-docs/?subcompany=${subcompany_id}&tender=${contract.tender}`}>
                                                {!contractDocumentsUploaded ? 'Загрузить' : 'Показать'} документы
                                            </Link>
                                        </Button>
                                    </Box>
                                </Alert>
                            }
                            {contractActsAccepted
                                ? <Alert severity="success">
                                    Акты согласованы
                                    <Btn className={`w-full`} color={!contractActsUploaded ? 'warning' : 'button'}>
                                        <Link
                                            to={`subcompany/upload-registration-acts/?subcompany=${subcompany_id}&tender=${contract.tender}`}>
                                            Показать акты
                                        </Link>
                                    </Btn>
                            </Alert>
                                : <Alert severity="warning">
                                    <div>Акты на согласовании</div>
                                    <Btn className={`w-full`} color={!contractActsUploaded ? 'warning' : 'button'}>
                                        <Link
                                            to={`subcompany/upload-registration-acts/?subcompany=${subcompany_id}&tender=${contract.tender}`}>
                                            {!contractActsUploaded ? 'Загрузить' : 'Показать'} акты
                                        </Link>
                                    </Btn>
                                </Alert>
                            }
                            {subcompanySignatory
                                ? <Alert severity="success">Подписант подрядчика был добавлен</Alert>
                                : <Alert severity="warning">Необходимо добавить подписанта со стороны подрядчика</Alert>
                            }
                            {companySignatory
                                ? <Alert severity="success">Подписант компании был добавлен</Alert>
                                : <Alert severity="warning">Необходимо добавить подписанта со стороны компании</Alert>
                            }
                        </Box>
                    }
                    {contract.is_acts_uploaded && contract.is_documents_uploaded &&
                        <Box className='flex justify-center my-2'>
                            <Button
                                onClick={() => setPopupShow(true)}
                                variant="contained"
                            >
                                Добавить подписанта подрядчика
                            </Button>
                        </Box>
                    }
                </Box>}
            {getAccessCheck &&
                <Box className='flex justify-center my-2'>
                    <Button
                        onClick={contractSelect}
                        startIcon={<Project/>}
                        variant="contained" color="success"
                    >
                        Тендер {tender?.tender.name}
                    </Button>
                </Box>
            }
        </React.Fragment>
    );
}