import React from "react";
import Warnings from "./Warnings";

export const ErrorOccurred = (
    response,
    label,
    functionName,
    errorsArray,
    isCodeDisabled
) => {

    const errors = response?.data?.errors || errorsArray;

    // console.log(
    //     `\n response`, response,
    //     `\n label`, label,
    //     `\n functionName`, functionName,
    //     `\n errors`, errors,
    // );

    const returnJsx = (
        <Warnings>
            <h3>{label}</h3>
            {(errors && errors.length > 0) ?
                <ul>
                    {errors.map((e,id) => {

                        // console.log(`\n e`, e);

                        return (
                            <li key={id}>
                                {e.detail ? e.detail : `Статус: ${e}`}
                                {e.attr && ` - ${e.attr}`}
                                {e.code && !isCodeDisabled && ` - ${e.code}`}
                            </li>
                        );
                    })}
                </ul> :
                `Статус: ${response?.status}`
            }
        </Warnings>
    );

    console.log(`\n error ${functionName}`, errors || response);

    const returnErrors = {};
    errors?.forEach(e => {

        returnErrors[e.attr] = e.detail;
    });
    // console.log(`\n returnErrors`, returnErrors);

    return ({
        jsx: returnJsx,
        errors: Object.keys(returnErrors).length > 0 && returnErrors
    });
}