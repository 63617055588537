export function validateSelect(personsList) {
    if (!personsList) return;

    const compressedChoice = [];

    personsList.forEach(person => {
        person.representatives.forEach(
            representative => compressedChoice.push({...person, name: (person.name + ", " + representative.name + ", " + representative.position), id: (person.id + "_" + representative.id)})
        )
    })

    return compressedChoice;
}