import styles from "./work.module.css";

const WorkSection = (props) => {

    return (
        <section className={styles.layout}>
            <div className={styles.page}>
                {props.children}
            </div>
        </section>
    );
}

export default WorkSection;