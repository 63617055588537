import {useState, useEffect} from "react";
import styles from "./slide-bar.module.css";
import Burger from "./Burger";
import UserPanel from "./UserPanel";
import ProjectControl from "./ProjectControl";

const ControlPanel = (props) => {

    const [expanded, setExpanded] = useState(true);
    useEffect(() => {

        const userExpand = localStorage.getItem('expanded');

        if (userExpand === '1') setExpanded(false);
    }, []);
    const expandHandler = (state) => {

        setExpanded(state);

        state ? localStorage.setItem('expanded', '') : localStorage.setItem('expanded', '1');
    }

    const [shownList, setShownList] = useState(false);
    const showListHandler = (id) => setShownList(id);
    
    const role = window.localStorage.getItem('role');
    // console.log(`\n role`, role);
    // console.log(`\n contractorWithoutSsr`, props.contractorWithoutSsr);
    // console.log(`\n calledOrders`, props.calledOrders);

    return (
        <div className={`${styles.navbar} ${!expanded ? styles.collapsed : ''}`}>
            <div className={styles['nav-but-menu']}>
                <Burger
                    setExpanded={expandHandler}
                    state={expanded}
                />
            </div>
            <UserPanel
                state={expanded}
                shownList={shownList}
                showListHandler={showListHandler}
            />
            {role && <ul className={`${styles['controller-list']} ${expanded ? styles.project : ""}`}>
                <ProjectControl
                    state={expanded}
                    shownList={shownList}
                    showListHandler={showListHandler}
                />
            </ul>}
        </div>
    );
}

export default ControlPanel;