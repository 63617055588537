import styles from './Select.module.css';
import {getDocumentType} from "../../Work/Subcompany/Akt-osvidetelstvovaniya/helpers/getDocumentType";
import {
    getRepresentativeFromCombined
} from "../../Work/Subcompany/Akt-osvidetelstvovaniya/helpers/getRepresentativeFromCombined";

const Select = (props) => {
    const disabledForCombined = (item) => {
        if (props.not_combined) return;

        return !(getDocumentType(getRepresentativeFromCombined(item).docs, 2) && getDocumentType(getRepresentativeFromCombined(item).docs, 6))
    }

    return (
        <div className={styles.container}>
            <div className={styles.collapsed}>
                <select
                    name={props.name}
                    onChange={props.handleOnChange}
                    className={`${styles.dropdown}
                      ${props.shownList === props.type
                        ? styles['dropdown-show']
                        : ''}
                    `}
                >
                    <option style={{display: 'none'}} value={''}>Выберите ответственного</option>
                    {props.options.map((item, index) => (
                        <option
                            disabled={disabledForCombined(item)}
                            key={props.name + index}
                            data-id={item.id}
                            value={item.id}
                            // onClick={() => console.log(item.representatives[item.id.split("_")[0]].docs)}
                        >
                            {item.name}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
}

export default Select;