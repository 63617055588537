import {useEffect, useState} from "react";

const usePerson = (id, list) => {
    const [person, setPerson] = useState(null);

    useEffect(() => {
        if (!list) return;
        const responsePerson = list
            .filter(person => String(person.id) === id)[0];
        setPerson(responsePerson);
    }, [id, list]);

    return {person};
};

export default usePerson;