import React from "react";
import {FormProvider, useForm} from "react-hook-form";
import RegistrationInput from "../Inputs/RegistrationInput";
import Btn from "../Buttons/Btn";

export default function CreateOrder(
    {
        createOrder,
        subcompanies,
        createOrderHandler,
    }
) {
    // if (subcompaniesInfo) console.log(`\n subcompaniesInfo`, subcompaniesInfo.find(s => s.id === 2));
    // if (subcompanies) console.log(`\n subcompanies`, subcompanies);

    const fields = [
        {
            label: 'Выбрать договор',
            name: 'tender_id',
            type: 'select',
            options: subcompanies?.map(subcompany => {

                return (
                    {
                        name: subcompany.tender.name,
                        id: subcompany.tender.id
                    }
                );
            }),
            firstEmpty: true
        },
        {
            label: 'Категория',
            name: 'category',
            type: 'select',
            options: [
                {
                    name: 1,
                    id: 1
                },
                {
                    name: 2,
                    id: 2
                },
                {
                    name: 3,
                    id: 3
                },
            ],
            firstEmpty: true
        },
        {
            label: 'Дата устранения',
            name: 'date_completion',
            type: 'date',
            min: new Date().toISOString().split('T')[0]
        },
        {
            label: 'Описание',
            name: 'description',
            type: 'text'
        },
        {
            label: 'Норматив',
            name: 'standard',
            type: 'text'
        },
        {
            label: 'Мероприятия по устранению',
            name: 'measures',
            type: 'text'
        },
        {
            label: 'Добавить фото',
            name: 'photos_to_upload',
            type: 'file',
            multiple: true,
            accept: '.jpg,.jpeg,.png,.gif',
            allowedFileTypes: '.jpg, .jpeg, .png, .gif'
        }
    ];
    const methods = useForm();

    const sendOrder = (data) => {

        console.log('\n sendOrder', data);

        // const tender = tenders.find(t => t.contract.id === data.contract);
        data['coordinate_x'] = createOrder[0];
        data['coordinate_y'] = createOrder[1];
        // data['tender_id'] = 1;

        delete data['contract'];

        // console.log(`\n sendOrder`, data);
        createOrderHandler(data);
    }
    // console.log(`\n CreateOrder`, createOrder, subcompanies);
    // console.log(`\n fields`, fields);

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(sendOrder)}>
                {fields.map(field =>
                    <RegistrationInput
                        key={field.name}
                        type={field.type}
                        name={field.name}
                        label={field.label}
                        options={field.options}
                        defaultValue={field.defaultValue}
                        min={field.min}
                        max={field.max}
                        step={field.step}
                        multiple={field.multiple}
                        accept={field.accept}
                        allowedFileTypes={field.allowedFileTypes}
                        firstEmpty={field.firstEmpty}
                    />
                )}
                <Btn method={`save`} color={`button`} center>
                    <button type="submit">Записать предписание</button>
                </Btn>
            </form>
        </FormProvider>
    );
}