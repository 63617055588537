import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DiagramFinancialSchedule = (props) => {

    const template = {
        plan: {
            label: 'Финансовый план',
            color: '#555f78'
        }
    }

    const labels = Object.keys(props.data.plan).map(date => {

        const label = new Date(date * 1000).toLocaleDateString('ru-RU',{year: 'numeric', month: 'long'});

        return (label[0].toUpperCase() + label.slice(1))
    });

    const data = {
        labels,
        datasets: [
            {
                label: template[Object.keys(props.data)[0]].label,
                data: Object.values(props.data.plan).map(price => price),
                borderColor: template[Object.keys(props.data)[0]].color,
                backgroundColor: template[Object.keys(props.data)[0]].color,
                pointRadius: 3,
                pointHoverRadius: 7,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        scales: {
            x: {
                beginAtZero: true,
            },
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <div className={`h-[250px] mb-3`}>
            <Line options={options} data={data} height={1300} />
        </div>
    );
}

export default DiagramFinancialSchedule;