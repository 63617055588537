import {useEffect,useState} from "react";
// import axios from "axios";

import {pdf} from "@react-pdf/renderer";
import {saveAs} from "file-saver";
import {Price} from "../../UI/Convertors/Price";

import PageSection from "../../UI/Sections/PageSection";
import Section from "../../UI/Sections/Section";
import Button from "../../UI/Buttons/Button";
import Grids from "../../UI/Grids/Grids";
import Bill from "../../UI/Documents/Bill";
import Alink from "../../UI/Buttons/Alink";
import axios from "axios";

const Payment = (props) => {

    const [accessToken, setAccessToken] = useState('');
    const [user, setUser] = useState();
    const [company, setCompany] = useState();
    const [tariffs, setTariffs] = useState();
    const [systemOwner,setSystemOwner] = useState();
    const [currentTariff,setCurrentTariff] = useState();

    useEffect(() => {
        
        if (document.cookie.length === 0) window.location.replace('/registration-company');

        const token = document.cookie.split(';').filter(cookie => cookie.includes('user='))[0].replace('user=','');
        
        console.log('\n ', token);
        
        if (token.length > 0) setAccessToken(token);
        else window.location.replace('/registration-company');

        document.cookie.split(';').forEach(data => {
                if (data.includes(' company=')) {

                const company = data.replace(' company=','').trim();

                setCompany(JSON.parse(company));
            }
        });
    },[accessToken]);

    useEffect(() => {

        const options = {headers: {Authorization: `Bearer ${accessToken}`}};

        const requests = [
            axios.get(`${props.erpApiUrl}/user/`, options),
            axios.get(`${props.erpApiUrl}/tariffs/`),
            axios.get(`${props.serverUrl}?system-owner`),
        ];

        axios.all(requests)
            .then(axios.spread((...responses) => {
                
                console.log('\n ', responses);

                setUser(responses[0].data);
                setTariffs(responses[1].data.data);
                setSystemOwner(responses[2].data);
                
            }))
            .catch(error => {
                // Обработка ошибок
            })
        ;
    },[props.serverUrl,accessToken,props.erpApiUrl]);
    useEffect(() => {
        if (user && tariffs && user.tariff) tariffs.forEach(tariff => {
            if (tariff.id === +user.tariff) {
                setCurrentTariff(tariff);
            }
        })
    },[user, tariffs,accessToken]);

    const createBill = () => {
        
        // console.log('\n ', company);

        const billNumber = Math.round((Math.pow(36, 16 + 1) - Math.random() * Math.pow(36, 16))).toString(36).slice(1);
        const billDate = new Date().toLocaleDateString('ru-RU', {year: 'numeric',month: 'long',day: 'numeric',timezone: 'UTC'});
        const generateBillHandler = <Bill
            bank_name={systemOwner.bank_name}
            company_name={systemOwner.company_name}
            inn={systemOwner.inn}
            bank_account={systemOwner.bank_account}
            ks={systemOwner.ks}
            bik={systemOwner.bik}
            buyer={company.company_name + ' ИНН: ' + company.inn + ', ' + company.legal_address}
            tariffName={currentTariff.name}
            tariffPriceCost={Price(currentTariff.price)}
            tariffCost={currentTariff.price}
            billNumber={billNumber}
            billDate={billDate}
        />

        pdf(generateBillHandler)
            .toBlob()
            .then((blob) => {
                saveAs(blob, billNumber + ".pdf");
            });
    }

    // console.log('\n ', user);

    if (currentTariff && currentTariff.price) {

        const payCardHref = '/pay-cards?cost=' + currentTariff.price;

        return (
            <PageSection>
                <Section>
                    <h1>Выбирите способ оплаты</h1>
                    <div>{currentTariff.name + ' - ' + Price(currentTariff.price)}</div>
                    <Grids className="justify-items-center" cols={2}>
                        {company.bank ? <Button onClick={createBill}>Сформировать счет на оплату</Button> : ''}
                        <Alink href={payCardHref}>Перейти к оплате картой</Alink>
                    </Grids>
                </Section>
            </PageSection>
        );
    }
    else if (tariffs) {

        let tariffsElements = [];

        if (tariffs.length > 0) {

            tariffs.forEach(tariff => {
                if (+tariff.price > 0) {
                    tariffsElements.push(
                        <div key={tariff.id}>
                            <div>{tariff.name}</div>
                            <div>{Price(tariff.price)}</div>
                            <Button onClick={() => setCurrentTariff({
                                id: tariff.id,
                                name: tariff.name,
                                price: tariff.price
                            })}>Выбрать
                                тариф - {tariff.name}</Button>
                        </div>
                    );
                }
            });
        }

        return (
            <PageSection>
                <Section>
                    <Grids cols={3}>
                        {tariffsElements}
                    </Grids>
                </Section>
            </PageSection>
        );
    }
}

export default Payment;