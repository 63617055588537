import React from "react";
import {Controller, useFormContext} from "react-hook-form";
import {FormControl, InputLabel, Select, FormHelperText, Box} from "@mui/material";

const ReactHookFormMUISelect =
    ({
         name,
         label,
         control,
         onChange,
         defaultValue,
         children,
         helperText,
         required,
         ...props
     }) => {
        const methods = useFormContext();
        const {register} = methods;
        const labelId = `${name}-label`;

        return (
            <FormControl fullWidth {...props}>
                <InputLabel id={labelId}>{label}</InputLabel>
                <Controller
                    name={name}
                    control={control}
                    defaultValue={defaultValue}
                    render={({field, fieldState: {error}}) => (
                        <>
                            <Select
                                labelId={labelId}
                                label={label}
                                {...field}
                                {...register(name, {
                                    validate: (data) => {
                                        if (required) return data;
                                        return true;
                                    }
                                })}
                                onChange={onChange}
                                error={!!error}
                            >
                                {children}
                            </Select>
                            {helperText && <FormHelperText>{helperText}</FormHelperText>}
                            {error && <FormHelperText error>{error.message}</FormHelperText>}
                        </>
                    )}
                />
            </FormControl>
        );
    };

export default ReactHookFormMUISelect;