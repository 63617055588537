import React, {useState} from "react";
import Grids from "../../UI/Grids/Grids";
import {Link} from "react-router-dom";
import {ReactComponent as Arrow} from "../../UI/Icons/arrow.svg";
import styles from "./docs.module.css";
import Btn from "../../UI/Buttons/Btn";

const WatchDocs = (props) => {

    const [isShown, setIsShown] = useState(false);

    // console.log(`\n `, props.userSettings);

    return (
        <React.Fragment>
            <div className={`${styles.header} ${isShown ? styles.shown : ''} px-2`}
                 onClick={() => setIsShown(!isShown)}>Документы представителя<Arrow/></div>
            {isShown && <div className={styles.row}>
                {(props.docs && Array.isArray(props.docs)) && props.docs.map(doc =>
                    <React.Fragment key={doc.type}>
                        <div className={styles.name}>{props.names[doc.type]}</div>
                        <Grids cols={2}>
                            <div className={`text-sm`}>Номер документа</div>
                            <div>{doc.number}</div>
                            <div className={`text-sm`}>Дата документа</div>
                            <div>{new Date(doc.date).toLocaleDateString()}</div>
                            {doc.organization_issued_document &&
                                <>
                                    <div className={`text-sm`}>Наименование организации</div>
                                    <div>{doc.organization_issued_document}</div>
                                </>
                            }
                            <Link className={`${styles.link} my-auto`}
                                  to={`${props.userSettings.fileServer}${doc.file}`} target="_blank">Скачать</Link>
                            <Btn color="button" method="edit"
                                 onClick={() => props.editDocsHandler(props.id, doc)}>Редактировать</Btn>
                        </Grids>
                        <hr className={styles.end}/>
                    </React.Fragment>
                )}
                <Btn color="button" className={`mx-auto`} method="add" onClick={props.addDocsHandler}>Добавить
                    документ</Btn>
            </div>}
        </React.Fragment>
    );
}

export default WatchDocs;