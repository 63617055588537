import React from 'react';

const UserContext = React.createContext({
    companies: [],
    user_companies: [],
    short_name: '',
    email: '',
    api: '',
    token: '',
    template_roles: {},
    fileServer: '',
    tender_directions: {},
    headers: {},
    subcompanyList: [],
    company_tenders: [],
    user_info: {},
});

export default UserContext;