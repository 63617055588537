import React from "react";
import dashStyles from "../dash.module.css";
import Btn from "../../UI/Buttons/Btn";
import RegistrationInput from "../../UI/Inputs/RegistrationInput";
import {FormProvider, useForm} from "react-hook-form";

export default function UploadAct(
    {
        isSend,
        signActsHandler,
        name,
    }
) {

    const methods = useForm();

    return (
        <FormProvider {...methods} >
            <form onSubmit={methods.handleSubmit(signActsHandler)}>
                <div className={dashStyles.card}>
                    <h3 className={`${dashStyles.label} whitespace-pre-wrap`}>{name}</h3>
                    <Btn
                        method={`download`}
                        color={`button`}
                        onClick={(e) => console.log(`\n name`, name)}
                        center
                    >Скачать для подписи</Btn>
                    <div className={dashStyles.area}>
                        <RegistrationInput
                            type={'file'}
                            name={name}
                            label={`Загрузите подписанный документ формата .sig или .pdf`}
                            sig
                        />
                    </div>
                    {!isSend && <Btn method={`save`} color={`button`} center>
                        <button type={`submit`}>Подписать акт</button>
                    </Btn>}
                </div>
            </form>
        </FormProvider>
    );
}