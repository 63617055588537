import { useForm, FormProvider } from "react-hook-form";

import PageSection from "../../UI/Sections/PageSection";
import Section from "../../UI/Sections/Section";
import Button from "../../UI/Buttons/Button";
import PasswordInputs from "../../UI/Inputs/PasswordInputs";
import {Link, useLocation} from "react-router-dom";
import axios from "axios";
import Btn from "../../UI/Buttons/Btn";
import React from "react";

const CreatePassword = (props) => {

    const uuid = useLocation().pathname.replace('/create-password/', '');

    const methods = useForm();
    const createPasswordHandler = (data) => {

        axios.post(`${props.erpApiUrl}/create_password_confirm/${uuid}`,{
            "password": data.password
        }).then(response => {
            if (response.data.status) {
                console.log('\n ', response);
                window.location.replace('/authorization');
            }
        }).catch(error => {
            console.error(error);
        });
    };

    return (
        <PageSection>
                <Btn>
                    <Link to={`/`}>Вернуться на главную</Link>
                </Btn>
            <Section>
                <FormProvider {...methods} >
                    <form onSubmit={methods.handleSubmit(createPasswordHandler)}>
                        <PasswordInputs name="password" />
                        <PasswordInputs name="repeatPassword" check={methods.watch("password")} />
                        <Button type="submit">Создать пароль</Button>
                    </form>
                </FormProvider>
            </Section>
        </PageSection>
    );
}

export default CreatePassword;