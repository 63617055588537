export const getFirstDateWithCurrentYear = (allDates) => {
    if (allDates.length === 0) {
        return "-";
    }
    const currentYear = String(new Date().getFullYear());
    const currentYearDates = allDates.filter(date => date.startsWith(currentYear));

    return convertDateString(currentYearDates[0]);
}

export function convertDateString(date) {
    const [year, month, day] = date.split('-');
    return `${day}.${month}.${year}`;
}
