import React from 'react';
import {Backdrop, Box, Button, IconButton, Modal, Typography} from "@mui/material";
import styles from "./ModalMUIWrapper.module.css";
import CloseIcon from '@mui/icons-material/Close';

const ModalMuiWrapper = ({open, onClose, ariaLabel, ariaDescribe, children}) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby={ariaLabel}
            aria-describedby={ariaDescribe}
            style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        >
            <Box className={styles.modal}>
                <Box className={styles.content}>
                    <Box className={styles.header}>
                        <Typography variant='h6'>Добавить</Typography>
                        <IconButton onClick={onClose} color="primary" aria-label="close-modal">
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                    <Box className={styles.form}>
                        {children}
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default ModalMuiWrapper;