import React from "react";
import {Document, Font, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import {getCurrentDate} from "../../Work/Subcompany/Akt-osvidetelstvovaniya/helpers/getCurrentDate";
import {getSurnameAndInitials} from "../../Work/Subcompany/Akt-osvidetelstvovaniya/helpers/getSurnameAndInitials";
import {
    getDocumentNameNumberDate
} from "../../Work/Subcompany/Akt-osvidetelstvovaniya/helpers/getDocumentNameNumberDate";
import {validateWorkNamesList} from "../../Work/Subcompany/Akt-osvidetelstvovaniya/helpers/validateWorkNamesList";

Font.register({
    family: "Roboto", src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"
});

const styles = StyleSheet.create({
    body: {
        fontFamily: "Roboto",
        fontSize: 10,
        padding: 20,
    },
    page: {
        display: "flex",
        flexDirection: "column",
        padding: 10,
    },
    block: {
        margin: "10px 0",
        display: "flex",
        flexDirection: "column",
    },
    row: {
        display: "flex",
        flexDirection: "row",
    },
    spaceBetween: {
        justifyContent: "space-between"
    },
    end: {
        alignItems: "flex-end",
    },
    horizontalBreak: {
        width: "100%",
        borderBottom: "1px solid black"
    },
    underline: {
        textDecoration: "underline"
    },
    textCenter: {
        textAlign: "center",
        margin: "10px 0",
    }
});

const AktOsvidetelstvovaniya = (props) => {
    const {
        object_name,
        workDates,
        workNames,
    } = props.fetchedInfo;

    const {
        customer,
        customerRepresentative,
        builder,
        builderRepresentative,
        documentation,
        documentationRepresentative,
        inspection,
        inspectionRepresentative
    } = props.mixedState;

    const {
        builderControl,
        otherInspection,
        other,
    } = props.representativesState;

    const {
        first,
        second,
        fourth,
        fifth_a,
        fifth_b,
        sixth,
        eighth,
        ninth_a,
        ninth_a_load,
        ninth_a_conditions,
        ninth_b,
        additional_info,
        copies,
        attachments,
    } = props.formData;

    console.log(props.mixedState)

    const currentDate = getCurrentDate()?.split(" ");
    const workStart = workDates.minDate ? workDates.minDate.split(" ") : "---";
    const workEnd = workDates.maxDate ? workDates.maxDate.split(" ") : "---";

    return (
        <Document title='Akt-osvidetelstvovaniya-otvetstvennyh-konstrukciy'>
            <Page style={styles.body}>
                <View style={[styles.block, styles.end]}>
                    <Text>Приказ Минстроя №344/пр от 16.05.2023</Text>
                    <Text>Приложение №5</Text>
                </View>

                <View style={styles.block}>
                    <Text>Объект капитального строительства:</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {object_name.object_name}
                        </Text>
                        <Text>(наименование, почтовый или строительный адрес объекта капитального строительства)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>Застройщик или технический заказчик:</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {(customer.ogrn && customer.inn)
                                ? (customer.name + ", " + customer.ogrn + ", " + customer.inn + ", " + getDocumentNameNumberDate(customerRepresentative.docs, 6, false))
                                : " "
                            }
                        </Text>
                        <Text>(наименование, ОГРН, ИНН, номер и дата выдачи свидетельства о допуске к видам работ по
                            строительству, реконструкции, капитальному ремонту</Text>
                    </View>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {" "}
                        </Text>
                        <Text>объектов капитального строительства, которые оказывают влияние на безопасность объектов
                            капитального строительства, с указанием</Text>
                    </View>

                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {(customer.ogrn && customer.inn)
                                ? (customer.postal_address + ", ")
                                : " "
                            }
                        </Text>
                        <Text>саморегулируемой организации, его выдавшей, почтовые реквизиты, телефон/факс - для
                            юридических лиц и индивидуальных предпринимателей; фамилия, имя, отчество, паспортные
                            данные, место проживания, телефон/факс - для физических лиц)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>Лицо, осуществляющее строительство:</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {(builder.ogrn && builder.inn)
                                ? (builder.name + ", " + builder.ogrn + ", " + builder.inn + ", " + getDocumentNameNumberDate(builderRepresentative.docs, 6, false))
                                : " "
                            }
                        </Text>
                        <Text>(наименование, ОГРН, ИНН, номер и дата выдачи свидетельства о допуске к видам работ по
                            строительству, реконструкции, капитальному ремонту</Text>
                    </View>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {" "}
                        </Text>
                        <Text>объектов капитального строительства, которые оказывают влияние на безопасность объектов
                            капитального строительства, с указанием</Text>
                    </View>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {(builder.ogrn && builder.inn)
                                ? (builder.postal_address + ", ")
                                : " "
                            }
                        </Text>
                        <Text>саморегулируемой организации, его выдавшей, почтовые реквизиты, телефон/факс - для
                            юридических лиц и индивидуальных предпринимателей; фамилия, имя, отчество, паспортные
                            данные, место проживания, телефон/факс - для физических лиц)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>Лицо, осуществляющее подготовку проектной документации:</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {(documentation.ogrn && documentation.inn)
                                ? (documentation.name + ", " + documentation.ogrn + ", " + documentation.inn + ", " + getDocumentNameNumberDate(documentationRepresentative.docs, 6, false))
                                : " "
                            }
                        </Text>
                        <Text>(наименование, ОГРН, ИНН, номер и дата выдачи свидетельства о допуске к видам работ по
                            подготовке проектной документации, которые</Text>
                    </View>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {" "}
                        </Text>
                        <Text>оказывают влияние на безопасность объектов капитального строительства, с указанием
                            саморегулируемой организации, его выдавшей,</Text>
                    </View>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {(documentation.ogrn && documentation.inn)
                                ? (documentation.postal_address + ", ")
                                : " "
                            }
                        </Text>
                        <Text>почтовые реквизиты, телефон/факс - для юридических лиц и индивидуальных предпринимателей;
                            фамилия, имя, отчество, паспортные данные, место проживания, телефон/факс - для физических
                            лиц)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>Лицо, выполнившее работы, подлежащие освидетельствованию:</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {(inspection.ogrn && inspection.inn)
                                ? (inspection.name + ", " + inspection.ogrn + ", " + inspection.inn + ", " + getDocumentNameNumberDate(inspectionRepresentative.docs, 6, false))
                                : " "
                            }
                        </Text>
                        <Text>(наименование, ОГРН, ИНН, номер и дата выдачи свидетельства о допуске к видам работ по
                            строительству, реконструкции, капитальному ремонту</Text>
                    </View>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {" "}
                        </Text>
                        <Text>объектов капитального строительства, которые оказывают влияние на безопасность объектов
                            капитального строительства, с указанием</Text>
                    </View>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {(inspection.ogrn && inspection.inn)
                                ? (inspection.postal_address + ", ")
                                : " "
                            }
                        </Text>
                        <Text>саморегулируемой организации, его выдавшей, почтовые реквизиты, телефон/факс - для
                            юридических лиц и индивидуальных предпринимателей; фамилия, имя, отчество, паспортные
                            данные, место проживания, телефон/факс - для физических лиц)</Text>
                    </View>
                </View>

                <View style={[styles.block, styles.textCenter]}>
                    <Text>АКТ</Text>
                    <Text>освидетельствования ответственных конструкций</Text>
                </View>

                <View style={[styles.row, styles.spaceBetween]}>
                    <View style={styles.row}>
                        <Text>№</Text>
                        <Text style={[styles.underline, {marginLeft: 15}]}>{21838123213}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text>«</Text>
                        <Text style={[styles.underline, {marginHorizontal: 2}]}>{currentDate[0]}</Text>
                        <Text>»</Text>
                        <Text style={[styles.underline, {marginHorizontal: 3}]}>{`${currentDate[1]}`}</Text>
                        <Text>{`${currentDate[2] + " " + currentDate[3]}`}</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>Представитель застройщика или технического заказчика по вопросам строительного
                        контроля:</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {`${customerRepresentative.position + ", " + getSurnameAndInitials(customerRepresentative.name).surname + ", " + getSurnameAndInitials(customerRepresentative.name).initials + ", " + getDocumentNameNumberDate(customerRepresentative.docs, 2)}`}
                        </Text>
                        <Text>(должность, фамилия, инициалы, реквизиты документа о представительстве)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>Представитель лица, осуществляющего строительство:</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {`${builderRepresentative.position + ", " + getSurnameAndInitials(builderRepresentative.name).surname + ", " + getSurnameAndInitials(builderRepresentative.name).initials + ", " + getDocumentNameNumberDate(builderRepresentative.docs, 2)}`}
                        </Text>
                        <Text>(должность, фамилия, инициалы, реквизиты документа о представительстве)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>Представитель лица, осуществляющего строительство, по вопросам строительного контроля:</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {`${builderControl.position + ", " + getSurnameAndInitials(builderControl.name).surname + ", " + getSurnameAndInitials(builderControl.name).initials + ", " + getDocumentNameNumberDate(builderControl.docs, 2)}`}
                        </Text>
                        <Text>(должность, фамилия, инициалы, реквизиты документа о представительстве)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>Представитель лица, осуществляющего подготовку проектной документации</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {`${documentationRepresentative.position + ", " + getSurnameAndInitials(documentationRepresentative.name).surname + ", " + getSurnameAndInitials(documentationRepresentative.name).initials + ", " + getDocumentNameNumberDate(documentationRepresentative.docs, 2)}`}
                        </Text>
                        <Text>(должность, фамилия, инициалы, реквизиты документа о представительстве)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>Представитель лица, выполнившего конструкции, подлежащие освидетельствованию</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {`${inspectionRepresentative.position + ", " + getSurnameAndInitials(inspectionRepresentative.name).surname + ", " + getSurnameAndInitials(inspectionRepresentative.name).initials + ", " + getDocumentNameNumberDate(inspectionRepresentative.docs, 2)}`}
                        </Text>
                        <Text>(должность, фамилия, инициалы, реквизиты документа о представительстве)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>а также иные представители лиц, участвующих в освидетельствовании:</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {`${otherInspection.position + ", " + getSurnameAndInitials(otherInspection.name).surname + ", " + getSurnameAndInitials(otherInspection.name).initials + ", " + getDocumentNameNumberDate(otherInspection.docs, 2)}`}
                        </Text>
                        <Text>(должность, фамилия, инициалы, реквизиты документа о представительстве)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>произвели осмотр ответственных конструкций, выполненных</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {`${otherInspection.position + ", " + getSurnameAndInitials(otherInspection.name).surname + ", " + getSurnameAndInitials(otherInspection.name).initials + ", " + getDocumentNameNumberDate(otherInspection.docs, 2)}`}
                        </Text>
                        <Text>(наименование лица, осуществляющего строительство, фактически выполнившего
                            конструкции)</Text>
                    </View>
                </View>

                <Text>и составили настоящий акт о нижеследующем:</Text>
                <View style={styles.block}>
                    <Text>1. К освидетельствованию предъявлены следующие ответственные конструкции</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>{first}</Text>
                        <Text>(перечень и краткая характеристика конструкций)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>2. Конструкции выполнены по проектной документации</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>{second}</Text>
                        <Text>(номер, другие реквизиты чертежа, наименование проектной и/или рабочей документации,
                            сведения о лицах, осуществляющих подготовку раздела проектной и/или рабочей
                            документации)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>3. При выполнении конструкций применены</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>{validateWorkNamesList(workNames)}</Text>
                        <Text>(наименование материалов (изделий) со ссылкой на сертификаты или другие документы,
                            подтверждающие качество)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>4. Освидетельствованы скрытые работы, которые оказывают влияние на безопасность
                        конструкций</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>{fourth}</Text>
                        <Text>(указываются скрытые работы, даты и номера актов их освидетельствования)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>5. Предъявлены документы, подтверждающие соответствие конструкций предъявляемым к ним
                        требованиям, в том числе:</Text>
                    <Text>а) исполнительные геодезические схемы положения конструкций</Text>
                    <View style={styles.textCenter}>
                        <Text
                            style={styles.horizontalBreak}>
                            {fifth_a}
                        </Text>
                        <Text>(наименование документа, дата, номер, другие реквизиты)</Text>
                    </View>
                    <Text>б) результаты экспертиз, обследований, лабораторных и иных испытаний выполненных работ,
                        проведенных в процессе строительного контроля</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {fifth_b}
                        </Text>
                        <Text>(наименование документа, дата, номер, другие реквизиты)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>6. Проведены необходимые испытания и опробования</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>{sixth}</Text>
                        <Text>(наименование документа, дата, номер, другие реквизиты)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <View>
                        <View style={styles.row}>
                            <Text>7. Даты: </Text>
                            <Text>начала работ</Text>
                            <Text style={{marginLeft: 2}}>«</Text>
                            <Text style={[styles.underline, {marginHorizontal: 2}]}>{workStart[0]}</Text>
                            <Text>»</Text>
                            <Text style={[styles.underline, {marginHorizontal: 3}]}>{workStart[1]}</Text>
                            <Text>{`${workStart[2] + " " + workStart[3]}`}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{marginLeft: 39}}>окончания работ</Text>
                            <Text style={{marginLeft: 2}}>«</Text>
                            <Text style={[styles.underline, {marginHorizontal: 2}]}>{workEnd[0]}</Text>
                            <Text>»</Text>
                            <Text style={[styles.underline, {marginHorizontal: 3}]}>{workEnd[1]}</Text>
                            <Text>{`${workEnd[2] + " " + workEnd[3]}`}</Text>
                        </View>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>8. Предъявленные конструкции выполнены в соответствии с техническими регламентами, иными
                        нормативными правовыми актами и проектной документацией</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {eighth}
                        </Text>
                        <Text>(указываются наименование, статьи (пункты) технического регламента, иных нормативных
                            правовых актов, разделы проектной и/или рабочей документации)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>9. На основании изложенного:</Text>
                    <Text>а) разрешается использование конструкций по назначению</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {ninth_a}
                        </Text>
                    </View>
                    <Text>или разрешается использование конструкций по назначению с нагружением в
                        <Text style={[styles.underline, {marginLeft: 15, marginRight: 15, minWidth: 100}]}>
                            {`${ninth_a_load}`}
                        </Text>% проектной нагрузки;</Text>
                    <Text>или разрешается полное нагружение при выполнении следующих условий:</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {ninth_a_conditions}
                        </Text>
                    </View>

                    <Text>б) разрешается производство последующих работ:</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {ninth_b}
                        </Text>
                        <Text>(наименование работ и конструкций)</Text>
                    </View>
                </View>
                <View style={styles.block}>
                    <View style={styles.row}>
                        <Text>
                            Дополнительные сведения
                        </Text>
                        <Text style={[styles.underline, {marginHorizontal: 10}]}>
                            {additional_info}
                        </Text>
                    </View>
                    <View style={styles.row}>
                        <Text>Акт составлен в</Text>
                        <Text style={[styles.underline, {marginHorizontal: 10}]}>
                            {`${copies}`}
                        </Text>
                        <Text>экземплярах.</Text>
                    </View>
                    <Text>Приложения: {attachments}</Text>
                </View>

                <View style={styles.block}>
                    <Text>Представитель застройщика или технического заказчика по вопросам строительного контроля</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {`${customerRepresentative.position + ", " + getSurnameAndInitials(customerRepresentative.name).surname + ", " + getSurnameAndInitials(customerRepresentative.name).initials}`}
                        </Text>
                        <Text>(должность, фамилия, инициалы, подпись)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>Представитель лица, осуществляющего строительство</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {`${builderRepresentative.position + ", " + getSurnameAndInitials(builderRepresentative.name).surname + ", " + getSurnameAndInitials(builderRepresentative.name).initials}`}
                        </Text>
                        <Text>(должность, фамилия, инициалы, подпись)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>Представитель лица, осуществляющего строительство, по вопросам строительного контроля</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {`${builderControl.position + ", " + getSurnameAndInitials(builderControl.name).surname + ", " + getSurnameAndInitials(builderControl.name).initials}`}
                        </Text>
                        <Text>(должность, фамилия, инициалы, подпись)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>Представитель лица, осуществляющего подготовку проектной документации, в случаях, когда
                        авторский надзор осуществляется</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {`${documentationRepresentative.position + ", " + getSurnameAndInitials(documentationRepresentative.name).surname + ", " + getSurnameAndInitials(documentationRepresentative.name).initials}`}
                        </Text>
                        <Text>(должность, фамилия, инициалы, подпись)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>Представитель лица, выполнившего конструкции, подлежащие освидетельствованию</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {`${inspectionRepresentative.position + ", " + getSurnameAndInitials(inspectionRepresentative.name).surname + ", " + getSurnameAndInitials(inspectionRepresentative.name).initials}`}
                        </Text>
                        <Text>(должность, фамилия, инициалы, подпись)</Text>
                    </View>
                </View>

                <View style={styles.block}>
                    <Text>Представители иных лиц:</Text>
                    <View style={styles.textCenter}>
                        <Text style={styles.horizontalBreak}>
                            {`${other.position + ", " + getSurnameAndInitials(other.name).surname + ", " + getSurnameAndInitials(other.name).initials}`}
                        </Text>
                        <Text>(должность, фамилия, инициалы, подпись)</Text>
                    </View>
                </View>
            </Page>
        </Document>)
};

export default AktOsvidetelstvovaniya;