import { Box, Button, FormControl, Input, InputAdornment, InputLabel } from "@mui/material";
import { useLocation } from "react-router-dom";
import PaymentRoundedIcon from "@mui/icons-material/PaymentRounded";
import React from "react";

export default function CardPay() {
    const data = new URLSearchParams(useLocation().search);
    const company_id = +data.get('company');
    const amount = +data.get('amount');

    // console.log('\n ', company_id, amount);

    const paymentCardHandler = (event) => {
        event.preventDefault(); // Предотвращаем перезагрузку страницы

        const userAmount = +event.target.amount.value;
        // console.log('\n paymentCardHandler', userAmount);

        window.location.replace(`/work/payment-event/?company=${company_id}&amount=${userAmount}`);
    };

    return (
        <Box className="flex items-center h-full">
            <Box className="w-max mx-auto">
                <h1>Оплата картой</h1>
                <form onSubmit={paymentCardHandler} className={`flex flex-col gap-2`}>
                    <FormControl sx={{ m: 1 }} variant="standard">
                        <InputLabel htmlFor="amount">Укажите сумму оплаты</InputLabel>
                        <Input
                            id="amount"
                            name={"amount"}
                            startAdornment={<InputAdornment position="start"><PaymentRoundedIcon /></InputAdornment>}
                            defaultValue={amount}
                            endAdornment={<InputAdornment position="end">р.</InputAdornment>}
                        />
                    </FormControl>
                    <Button type="submit" variant="outlined">Оплатить</Button>
                </form>
            </Box>
        </Box>
    );
}
