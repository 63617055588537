import React, {useState} from 'react';
import {FormProvider, useForm} from "react-hook-form";
import RegistrationInput from "../../UI/Inputs/RegistrationInput";
import {Box, Button, Typography} from "@mui/material";

const CustomTariffProposeModal = (props) => {
    const methods = useForm();

    const [clicked, setClicked] = useState(false);

    const getFields = (fields) => {
        return fields.map(field =>
            <RegistrationInput
                key={field.name}
                type={field.type}
                name={field.name}
                label={field.label}
                notRequired={field.notRequired}
                placeholder={field.placeholder}
                options={field.options}
                defaultValue={field.defaultValue}
                min={field.min}
                max={field.max}
                step={field.step}
            />
        )
    }

    const fields = [
        {
            "name": "administrator",
            "type": "number",
            "label": "Администратор, кол-во",
            "placeholder": 0,
            "notRequired": true
        },
        {
            "name": "coordinator",
            "type": "number",
            "label": "Координатор, кол-во",
            "placeholder": 0,
            "notRequired": true
        },
        {
            "name": "build_control",
            "type": "number",
            "label": "Стройконтроль, кол-во",
            "placeholder": 0,
            "notRequired": true
        },
        {
            "name": "manager",
            "type": "number",
            "label": "Начальник участка, кол-во",
            "placeholder": 0,
            "notRequired": true
        },
        {
            "name": "gip",
            "type": "number",
            "label": "ГИП, кол-во",
            "placeholder": 0,
            "notRequired": true
        },
        {
            "name": "contract",
            "type": "number",
            "label": "Подрядчик, кол-во",
            "placeholder": 0,
            "notRequired": true
        }
    ];

    return (
        <FormProvider {...methods} >
            <form onSubmit={methods.handleSubmit(props.onSubmit)}>
                {!clicked &&
                    <Box>
                        <Typography>Добавить к текущим следующее кол-во мест</Typography>
                        {getFields(fields)}
                        <Box className='flex justify-center'>
                            <Button onClick={() => setClicked(true)} variant="outlined">Подтвердить</Button>
                        </Box>
                    </Box>
                }

                {clicked &&
                    <Box>
                        <Box>
                            <Typography>
                                Обращаем ваше внимание, что
                                изменение тарифа может привести к
                                увеличению ежемесячной оплаты в случае увеличения допустимого
                                по тарифу количества сотрудников. Пожалуйста, учитывайте это
                                при планировании изменений.
                            </Typography>
                        </Box>

                        <Box className='flex justify-center'>
                            <Button type='submit' variant="outlined">Создать запрос</Button>
                        </Box>
                    </Box>
                }
            </form>
        </FormProvider>
    );
};

export default CustomTariffProposeModal;