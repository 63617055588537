import React, {useState} from 'react';
import styles from './controlItems.module.css';
import {Link} from 'react-router-dom';
import {ReactComponent as Arrow} from '../../UI/Icons/arrow.svg';

const ControlItem = (props) => {

    const [expandedList, setExpandedList] = useState(false);

    const setShowListId = () => {
        if (props.shownList === props.id) props.showListHandler(false);
        else props.showListHandler(props.id);
    }

    // if (props) console.log(`\n props`, props);
    // console.log(`\n calledOrders`, props.calledOrders);
    return (
        <li className={`${props.state ? styles.item : styles['collapsed-item']} relative`}>
            {props.showOnlyTariffTab && <React.Fragment>
                <div
                    className={`${styles['item-list']} ${expandedList ? styles['list-show-items'] : ''}`}
                    onClick={() => {
                        setExpandedList(!expandedList)
                        setShowListId()
                    }}
                    title={"Согласовать информацию об изменении тарифа"}
                >
                    <span
                        className="grow">{`${props.state ? "Тарифы" : "Т"}`}</span>
                    {props.state && <Arrow/>}
                    {props?.tariffRequests &&
                        <span
                            className={`${props.state ? styles['list-amount'] : styles['collapsed-list-amount']}`}
                        >
                        {props.tariffRequests.length}
                    </span>}
                </div>
                {expandedList &&
                    <ul
                        className={`${props.state ? '' : styles.notification}`}
                        onMouseLeave={() => {
                            if (!props.state) setExpandedList(false)
                        }}
                    >
                        <li key='tariff-requests' className={`mx-3 my-2 border border-pult-hover rounded-md`}>
                            <div className={`${styles['item-list']} whitespace-nowrap`}>
                                {props.currentRole === 0 &&
                                    <Link to={`/work/depositor/tariff-requests`}>
                                        <div className={`px-2 whitespace-break-spaces`}>
                                            <div>Перейти на страницу с запросами на изменение тарифов</div>
                                            <div className={`text-mm`}>инвестор</div>
                                        </div>
                                    </Link>
                                }
                                {props.currentRole === 1 &&
                                    <Link to={`/work/administrator/tariff-requests`}>
                                        <div className={`px-2 whitespace-break-spaces`}>
                                            <div>Перейти на страницу с запросами на изменение тарифов</div>
                                            <div className={`text-mm`}>администратор</div>
                                        </div>
                                    </Link>
                                }
                            </div>
                        </li>
                    </ul>
                }
            </React.Fragment>}
            {props.contracts && <React.Fragment>
                <div
                    className={`${styles['item-list']} ${expandedList ? styles['list-show-items'] : ''}`}
                    onClick={() => {
                        setExpandedList(!expandedList)
                        setShowListId()
                    }}
                    title={"Согласовать информацию о подрядчике"}
                >
                    <span className="grow">{`${props.state ? "Согласовать информацию о подрядчике" : "C"}`}</span>
                    {props.state && <Arrow/>}
                    <span
                        className={`${props.state ? styles['list-amount'] : styles['collapsed-list-amount']}`}>{props.contracts.length}</span>
                </div>
                {expandedList &&
                    <ul
                        className={`${props.state ? '' : styles.notification}`}
                        onMouseLeave={() => {
                            if (!props.state) setExpandedList(false)
                        }}
                    >
                        {props.contracts.map(contract => <li key={contract.id}
                                                             className={`mx-3 my-2 border border-pult-hover rounded-md`}>
                            <div className={`${styles['item-list']} whitespace-nowrap`}>
                                <Link
                                    to={`/work/coordinator/contract/info-accept/?subcompany=${contract.subcompany}&tender=${contract.tender}`}>
                                    <div className={`px-2 whitespace-break-spaces`}>
                                        <div>{props.companiesInfo && props.companiesInfo.find(c => c.id === contract.subcompany).name}</div>
                                        <div className={`text-mm`}>{contract.id}</div>
                                    </div>
                                </Link>
                            </div>
                        </li>)}
                    </ul>
                }
            </React.Fragment>}
            {(props.subcontractorsWithoutSsr && props.subcontractorsWithoutSsr.length > 0) && <React.Fragment>
                <div
                    className={`${styles['item-list']} ${expandedList ? styles['list-show-items'] : ''}`}
                    onClick={() => {
                        setExpandedList(!expandedList)
                        setShowListId()
                    }}
                    title={"Назначить ССР"}
                >
                    <span className="grow">{`${props.state ? "Назначить ССР" : "Н"}`}</span>
                    {props.state && <Arrow/>}
                    <span
                        className={`${props.state ? styles['list-amount'] : styles['collapsed-list-amount']}`}>{props.subcontractorsWithoutSsr.length}</span>
                </div>
                {expandedList &&
                    <ul
                        className={`${props.state ? '' : styles.notification}`}
                        onMouseLeave={() => {
                            if (!props.state) setExpandedList(false)
                        }}
                    >
                        {props.subcontractorsWithoutSsr.map(contract => {

                            return (
                                <li key={contract.tender_id}
                                    className={`mx-3 my-2 border border-pult-hover rounded-md`}>
                                    <div className={`${styles['item-list']} whitespace-nowrap`}>
                                        <Link
                                            to={`/work/section-manager/define-ssr/?subcompany=${contract.subcompany.id}&tender=${contract.tender_id}`}>
                                            <div className={`px-2 whitespace-break-spaces`}>
                                                {contract.subcompany.name} - {contract.tender}
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                }
            </React.Fragment>}
            {(props.calledOrders?.length > 0) && <React.Fragment>
                <div
                    className={`${styles['item-list']} ${expandedList ? styles['list-show-items'] : ''}`}
                    onClick={() => {
                        setExpandedList(!expandedList)
                        setShowListId()
                    }}
                    title={"Вызов на предписание"}
                >
                    <span className="grow">{`${props.state ? "Вызов на предписание" : "В"}`}</span>
                    {props.state && <Arrow/>}
                    <span
                        className={`${props.state ? styles['list-amount'] : styles['collapsed-list-amount']}`}>{props.calledOrders?.length}</span>
                </div>
                {expandedList &&
                    <ul
                        className={`${props.state ? '' : styles.notification}`}
                        onMouseLeave={() => {
                            if (!props.state) setExpandedList(false)
                        }}
                    >
                        {props.calledOrders?.map(order => {

                            return (
                                <li key={order.id} className={`mx-3 my-2 border border-pult-hover rounded-md`}>
                                    <div className={`${styles['item-list']} whitespace-nowrap`}>
                                        <Link
                                            to={`/work/technical-control/quality-control/building/?building=${order.building_id}&section=${order.section}&floor=${order.floor}&order=${order.id}`}>
                                            <div className={`px-2 whitespace-break-spaces`}>
                                                {order.id} - {order.subcompany.tender.name}
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                }
            </React.Fragment>}

            {(props.qualityDocs?.length > 0) && <React.Fragment>
                <div
                    className={`${styles['item-list']} ${expandedList ? styles['list-show-items'] : ''}`}
                    onClick={() => {
                        setExpandedList(!expandedList)
                        setShowListId()
                    }}
                    title={"Согласовать работы"}
                >
                    <span className="grow">{`${props.state ? "Согласовать работы" : "С"}`}</span>
                    {props.state && <Arrow/>}
                    <span
                        className={`${props.state ? styles['list-amount'] : styles['collapsed-list-amount']}`}>{props.qualityDocs?.length}</span>
                </div>
                {expandedList &&
                    <ul
                        className={`${props.state ? '' : styles.notification}`}
                        onMouseLeave={() => {
                            if (!props.state) setExpandedList(false)
                        }}
                    >
                        {props.qualityDocs?.map((d, index) => {

                            // console.log('\n d', d);

                            return (
                                <li key={index} className={`mx-3 my-2 border border-pult-hover rounded-md`}>
                                    <div className={`${styles['item-list']} whitespace-nowrap`}>
                                        <Link
                                            to={`section-manager/agree-volume/?subcompany=${d.subcompany_id}&tender=${d.tender_id}`}>
                                            <div className={`px-2 whitespace-break-spaces`}>
                                                {d.company.name}
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                }
            </React.Fragment>}

            {props.hasApprovedDocs &&
                <Link
                    to={`/work/subcompany/acts/`}
                    title={`Формирование актов`}>
                    {props.state ? `Формирование актов` : `Ф`}
                </Link>
            }

            {props.notApprovedWorkLog &&
                <Link
                    to={`/work/subcompany/submit-work-payment/`}
                    title={`Подать работы на согласование`}>
                    {props.state ? `Подать работы на согласование` : `П`}
                </Link>
            }

            {(props.label && props.href) &&
                <Link to={props.href} title={props.label}>{props.state ? props.label : props.label[0]}</Link>}
        </li>
    )
}

export default ControlItem;