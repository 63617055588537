import styles from './buttons.module.css';
import {Link} from "react-router-dom";

const Alink = (props) => {

    return (
        <Link className={`${styles.link} ${props.className ? props.className : ''}`} to={props.href} target={props.target}>{props.children}</Link>
    );
}

export default Alink;