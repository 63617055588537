import React from 'react';
import Warnings from "../../UI/Sections/Warnings";

const Rates = () => {
    return (
        <div>
            <h1>
                Тарифы
            </h1>
            <div className='w-fit'>
                <Warnings reject>Страница находится в процессе разработки</Warnings>
            </div>
        </div>
    );
};

export default Rates;